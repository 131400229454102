import React from "react";
import ImpactBar from "components/impactBar";
import { ImpactCategoriesColors } from "types";
import "./styles.css";

type ChartVisualizationSettings = {
  showInfluencer?: boolean;
  showImplementer?: boolean;
  showIntroducer?: boolean;
  showInformation?: boolean;
};

type ImpactBarChartProps = {
  implementer: number;
  influencer: number;
  introducer: number;
  information: number;
  visualizationSettings?: ChartVisualizationSettings;
};

const ImpactBarChart = (props: ImpactBarChartProps) => {
  const {
    implementer,
    influencer,
    introducer,
    information,
    visualizationSettings = {},
  } = props;

  const {
    showInfluencer = true,
    showImplementer = true,
    showIntroducer = true,
    showInformation = true,
  } = visualizationSettings;

  return (
    <div className="bar-chart-wrapper">
      {showInfluencer && (
        <ImpactBar
          key="influencer" // TODO: key is not unique
          maxValue={5}
          value={influencer}
          color={ImpactCategoriesColors.INFLUENCER}
        />
      )}
      {showImplementer && (
        <ImpactBar
          key="implementer"
          maxValue={5}
          value={implementer}
          color={ImpactCategoriesColors.IMPLEMENTER}
        />
      )}
      {showIntroducer && (
        <ImpactBar
          key="introducer"
          maxValue={5}
          value={introducer}
          color={ImpactCategoriesColors.INTRODUCER}
        />
      )}
      {showInformation && (
        <ImpactBar
          key="information"
          maxValue={5}
          value={information}
          color={ImpactCategoriesColors.INFORMATION}
        />
      )}
    </div>
  );
};

export default ImpactBarChart;
