import React, { useMemo } from "react";
import {
  CheckboxOptionType,
  Drawer,
  DrawerProps,
  Form,
  Radio,
  Affix,
  Button,
  Space,
  Spin,
  Row,
  Typography,
} from "@pankod/refine-antd";
import useDeviceSize from "hooks/useDeviceSize";
import FileDraggerField from "./FileDraggerField";
import useImportContacts, { IImportContacts } from "hooks/useImportContacts";
import ImportType from "./ImportType";
import { ImportResult } from "./ImportResult";
import ExportInstructions from "./ExportInstructions";
import { getFormFiledErrors } from "utils";

type ImportContactsDrawerProps = DrawerProps & {
  visible: boolean;
  onClose(): void;
  onImportSuccess?(): void;
};

export const ImportContactsDrawer: React.FC<ImportContactsDrawerProps> = ({
  title,
  onImportSuccess,
  ...props
}) => {
  const { state: deviceSizeState } = useDeviceSize();

  const [form] = Form.useForm();
  const { state, operations } = useImportContacts({
    onSuccess: () => {
      form.resetFields();
      onImportSuccess?.();
    },
    onError: (errors) => {
      const fieldErrors = getFormFiledErrors(errors);
      form.setFields(fieldErrors);
    },
  });
  const { settings, result } = state;

  const fileErrors = form.getFieldError("file");

  const fileExtensions = useMemo(() => {
    return settings?.file_extensions.map((ext) => {
      return `.${ext}`;
    });
  }, [settings?.file_extensions]);

  const radioOptions: CheckboxOptionType[] = useMemo(() => {
    if (!settings?.import_from) return [];

    return settings.import_from.map((option) => {
      return {
        label: <ImportType type={option.label} />,
        value: option.value,
      };
    });
  }, [settings?.import_from]);

  return (
    <Drawer
      title={title || "Import contacts"}
      getContainer={false}
      width={deviceSizeState.isSmall ? "100%" : "500px"}
      {...props}
      onClose={state.isLoading ? () => {} : props.onClose}
    >
      <Spin spinning={state.isLoading}>
        <Row style={{ marginBottom: "24px" }}>
          <Typography.Text>
            Follow these steps to import a batch of your contacts:
          </Typography.Text>
        </Row>
        <ExportInstructions title="1. Export your contacts into an XLS or a CSV file:" />
        <Form<IImportContacts>
          layout="vertical"
          name="import-contacts"
          requiredMark={false}
          form={form}
          onFinish={(values) => {
            operations.importContacts(values);
          }}
          onChange={() => {
            operations.resetResult();
          }}
        >
          <Form.Item
            name="import_from"
            label="2. Tell us which platform your contacts are coming from:"
            rules={[
              {
                required: true,
                message: "Please select a platform",
              },
            ]}
          >
            <Radio.Group
              className="relationship-matrix-radio-buttons"
              optionType="button"
              options={radioOptions}
            />
          </Form.Item>
          <Form.Item
            name="file"
            rules={[
              {
                required: true,
                message: "File is required",
              },
            ]}
          >
            <FileDraggerField
              fileExtensions={fileExtensions || []}
              description="3. Drag & drop the exported file here. (Or tap to select it.)"
              error={fileErrors.length > 0}
            />
          </Form.Item>
        </Form>
        <Form.Item>
          <Affix offsetBottom={40}>
            <Space style={{ float: "right" }}>
              <Button type="primary" onClick={form.submit}>
                Import
              </Button>
            </Space>
          </Affix>
        </Form.Item>
        {result && <ImportResult result={result} />}
      </Spin>
    </Drawer>
  );
};
