import React from "react";
import moment, { Moment } from "moment";
import { Button, Form, Input, Space, Select } from "@pankod/refine-antd";
import { DatePicker } from "antd";
import { IAction } from "interfaces";
import { actionOptions } from "options";

type ActionFormProps = {
  action: IAction;
  onDelete?: () => void;
  onSave: (actionData: IAction) => void;
};

interface IActionFormData extends IAction {
  selectedActions: string[];
  dateRange?: [Moment, Moment];
}

const ActionsSeparator = ", ";

const ActionForm = (props: ActionFormProps) => {
  const { action } = props;
  const [form] = Form.useForm<IActionFormData>();

  const fromProps = {
    labelCol: { xs: 8 },
  };

  const getDefaultEndDate = () => {
    if (action?.end_date && moment(action?.end_date).isValid()) {
      return moment(action?.end_date);
    }
    return null;
  };

  return (
    <Form<IActionFormData>
      form={form}
      onFinish={(values) => {
        const action = { ...values };

        // date
        action.start_date = null;
        action.end_date =
          action?.end_date && moment(action.end_date).isValid()
            ? moment(action.end_date).format("YYYY-MM-DD")
            : null;

        // action name
        const actionsSelected: string[] = [...action.selectedActions];
        const name = actionsSelected.join(ActionsSeparator);

        const { selectedActions, ...restActionData } = action; // remove selectedAction

        props.onSave({
          ...props.action,
          ...restActionData,
          name,
        });
      }}
      initialValues={{
        ...props.action,
        selectedActions: !!props.action.name
          ? props.action.name.split(ActionsSeparator)
          : [],
        end_date: getDefaultEndDate(),
      }}
      name="add-action"
    >
      <Form.Item
        name="selectedActions"
        label="Action"
        rules={[
          {
            required: true,
            message: "Please fill in an action",
          },
        ]}
        {...fromProps}
      >
        <Select mode="tags" options={actionOptions} />
      </Form.Item>
      {/* <Form.Item name="dateRange" label="Time frame" {...fromProps}>
        <DatePicker.RangePicker format={"YYYY-MM-DD"} />
      </Form.Item> */}
      <Form.Item name="end_date" label="Completion date" {...fromProps}>
        <DatePicker format={"YYYY-MM-DD"} />
      </Form.Item>
      <Form.Item name="responsible" label="Assigned to" {...fromProps}>
        <Input />
      </Form.Item>
      <Form.Item name="note" label="Note" {...fromProps}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="result" label="Result" {...fromProps}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Space style={{ float: "right" }}>
          {props.onDelete && (
            <Button danger onClick={props.onDelete}>
              Delete
            </Button>
          )}
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ActionForm;
