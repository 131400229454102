export const impactCategoriesOptions = [
  {
    key: "influencer",
    label: "Influencer",
  },
  {
    key: "implementer",
    label: "Implementer",
  },
  {
    key: "introducer",
    label: "Introducer",
  },
  {
    key: "information",
    label: "Source of info, insight, ideas",
  },
];

export const strengthOfRelationshipOptions = [
  {
    key: "0",
    label: "Don't know",
  },
  {
    key: "1",
    label: "Recognise",
  },
  {
    key: "2",
    label: "Know",
  },
  {
    key: "3",
    label: "Like",
  },
  {
    key: "4",
    label: "Trust",
  },
  {
    key: "5",
    label: "Support",
  },
  {
    key: "6",
    label: "Advocate",
  },
  {
    key: "7",
    label: "Friend",
  },
];

export const contactTypesOptions = [
  {
    key: "advocate",
    value: "advocate",
    label: "Advocate",
  },
  {
    key: "neutral",
    value: "neutral",
    label: "Neutral",
  },
  {
    key: "blocker",
    value: "blocker",
    label: "Blocker",
  },
];

export const actionOptions = [
  {
    key: "social-media-like",
    label: "Social Media 'Like'",
    value: "Social Media 'Like'",
  },
  {
    key: "social-media-comment",
    label: "Social Media Comment",
    value: "Social Media Comment",
  },
  {
    key: "email",
    label: "Email (Transactional)",
    value: "Email (Transactional)",
  },
  {
    key: "text-message",
    label: "Text Message (relational)",
    value: "Text Message (relational)",
  },
  {
    key: "conversation",
    label: "Conversation at event/Conference",
    value: "Conversation at event/Conference",
  },
  {
    key: "social-media-share",
    label: "Social Media Share",
    value: "Social Media Share",
  },
  {
    key: "phone-call-transactional",
    label: "Phone call (transactional)",
    value: "Phone call (transactional)",
  },
  {
    key: "1-2-1-meeting",
    label: "1-2-1 meeting (transactional)",
    value: "1-2-1 meeting (transactional)",
  },
  {
    key: "personal-event-invite",
    label: "Personal event invite",
    value: "Personal event invite",
  },
  {
    key: "thought-of-you",
    label: "'I Saw This and Thought of You'",
    value: "'I Saw This and Thought of You'",
  },
  {
    key: "personalised-thank-you",
    label: "Personalised 'Thank You' note or card",
    value: "Personalised 'Thank You' note or card",
  },
  {
    key: "phone-call-relational",
    label: "Phone call (relational)",
    value: "Phone call (relational)",
  },
  {
    key: "1-2-1-meeting-relational",
    label: "1-2-1 meeting (relational)",
    value: "1-2-1 meeting (relational)",
  },
  {
    key: "personalised-gift",
    label: "Personalised 'Thank You' gift",
    value: "Personalised 'Thank You' gift",
  },
  {
    key: "1-2-1-lunch-transactional",
    label: "1-2-1 Lunch/Dinner (transactional)",
    value: "1-2-1 Lunch/Dinner (transactional)",
  },
  {
    key: "1-2-1-lunch-relational",
    label: "1-2-1 Lunch/Dinner (relational)",
    value: "1-2-1 Lunch/Dinner (relational)",
  },
  {
    key: "give-referral-support",
    label: "Give referral/key support",
    value: "Give referral/key support",
  },
];
