import { AspectRatio, Container, Grid, Title } from "@mantine/core";
import { Spin } from "@pankod/refine-antd";
import ReactPlayer from "react-player/vimeo";
import { getCookieConsentValue } from "react-cookie-consent";
import useDeviceSize from "hooks/useDeviceSize";
import { ACCEPT_COOKIE_NAME } from "components/cookieConsent/CookieConsent";

export type VideoContainerProps = {
  id: string;
  title: string;
  videoId: string;
  colored?: boolean;
  reversed?: boolean;
};

export const VideoContainer: React.FC<VideoContainerProps> = ({
  id,
  title,
  colored = false,
  reversed = false,
  videoId,
  children,
}) => {
  const isAccepted = getCookieConsentValue(ACCEPT_COOKIE_NAME);

  const { state } = useDeviceSize();

  const reversComponents = state.isSmall ? false : reversed;

  const TitleElement: React.FC = () => {
    return (
      <Grid.Col md={5} xs={12}>
        <Grid.Col>
          <Title order={3}>{title}</Title>
        </Grid.Col>
        {children && <Grid.Col>{children}</Grid.Col>}
      </Grid.Col>
    );
  };

  const VideoElement: React.FC = () => {
    return (
      <Grid.Col md={7} xs={12}>
        <Spin spinning={false} style={{ width: "100%", height: "100%" }}>
          <AspectRatio
            ratio={16 / 9}
            style={{
              WebkitBoxShadow: "7px 0px 4px -4px rgba(0,0,0,0.4)",
              boxShadow: "0px 2px 7px 2px rgba(0,0,0,0.4)",
            }}
          >
            {isAccepted ? (
              <ReactPlayer
                url={`https://player.vimeo.com/video/${videoId}`}
                controls
                light
                width="100%"
                height="100%"
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "lightgray",
                  padding: "20px",
                  color: "black",
                }}
              >
                Video is not available before accepting cookies
              </div>
            )}
          </AspectRatio>
        </Spin>
      </Grid.Col>
    );
  };

  return (
    <Container
      fluid
      style={{
        backgroundColor: colored ? "rgb(153, 51, 153, 0.05)" : "",
        scrollMarginTop: "50px",
      }}
      id={id}
    >
      <Container py={80}>
        <Grid gutter="lg">
          {reversComponents ? (
            <>
              <VideoElement />
              <TitleElement />
            </>
          ) : (
            <>
              <TitleElement />
              <VideoElement />
            </>
          )}
        </Grid>
      </Container>
    </Container>
  );
};
