import { HttpError, useCreate } from '@pankod/refine-core'
import { IBasicMatrixData } from 'interfaces';
import { ServerErrors } from 'types';

export type UseCreateMatrixProps = {
    onErrorCallBack?: (errors: ServerErrors) => void,
    onSuccessCallBack?: (matrix: IBasicMatrixData) => void
}

export type CreateMatrixProps = {
    name: string
}

export type UseChangePasswordResponse = {
    creteMatrix: (values: CreateMatrixProps) => Promise<void>,
    isLoading: boolean
}

export const useCreateMatrix = (props: UseCreateMatrixProps = {}): UseChangePasswordResponse => {
    const { onErrorCallBack = () => { }, onSuccessCallBack = () => { } } = props;
    const { isLoading, mutateAsync } = useCreate<IBasicMatrixData>();

    const creteMatrix = async (values: CreateMatrixProps) => {
        await mutateAsync({
            resource: 'matrices',
            values,
            successNotification: {
                message: "Matrix was created successfully",
                type: "success"
            },
            errorNotification: {
                message: "Could not create matrix",
                type: "error"
            }
        }, {
            onSuccess: (data) => {
                const matrix = data.data;
                onSuccessCallBack(matrix)
            },
            onError: (err: HttpError) => {
                const { response = {} } = err;
                const { data = {} } = response;
                const { errors = {} } = data;

                onErrorCallBack(errors);
            },
        })
    }

    return {
        creteMatrix,
        isLoading
    }
}
