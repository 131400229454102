import React from "react";
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Typography,
} from "@pankod/refine-antd";
import { IMatrixForm } from "interfaces";
import { FieldErrors, LegacyButtonType, ServerErrors } from "types";
import { CreateMatrixProps, useCreateMatrix } from "hooks/useCreateMatrix";
import { Link } from "@pankod/refine-react-router-v6";

export type CreateMatrixModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleOk?: (matrixId: number) => void;
  title?: string;
  okText?: string;
  okType?: LegacyButtonType;
};

export const CreateMatrixModal: React.FC<CreateMatrixModalProps> = (props) => {
  const {
    isOpen,
    handleClose,
    handleOk = () => {},
    title = "New Relationship Matrix",
    okText = "Create",
    okType = "primary",
  } = props;

  const [form] = Form.useForm<IMatrixForm>();

  const handleCreateMatrixErrors = (errors: ServerErrors) => {
    const formFields: FieldErrors[] = Object.entries(errors).map((error) => {
      const [name, filedErrors] = error;
      return { name, errors: filedErrors };
    });
    form.setFields(formFields);
  };

  const { creteMatrix, isLoading } = useCreateMatrix({
    onErrorCallBack: handleCreateMatrixErrors,
    onSuccessCallBack: (matrix) => {
      const matrixId = matrix.id;
      handleOk?.(matrixId);
    },
  });

  const onCloseModal = () => {
    form.resetFields();
    handleClose();
  };

  const handleFinish = async (values: CreateMatrixProps) => {
    await creteMatrix(values);
    onCloseModal();
  };

  return (
    <Modal
      title={title}
      visible={isOpen}
      onOk={form?.submit}
      onCancel={onCloseModal}
      okText={okText}
      okType={okType}
    >
      <Spin spinning={isLoading}>
        <Form<IMatrixForm>
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          requiredMark={false}
          name="create-matrix"
        >
          <Form.Item
            name="name"
            label="Matrix name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
        <Row gutter={[0, 20]}>
          <Col>
            <Typography.Text>
              We recommend that the name of the matrix corresponds to the
              objective you want to set. And you want to keep that objective
              fairly focused – if it is too broad (such as ‘win new business’ or
              ‘career development’), you’ll struggle to identify the right
              people to help you.
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>
              Try something like ‘Win new business in the healthcare sector’,
              ‘Create more opportunities within Coca Cola’ or ‘Get promoted to a
              Board role’.
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>
              Learn more by following the{" "}
              <Link to={"/quick-start-guide"} target="_blank">
                <strong>Quick Start Guide</strong>.
              </Link>
            </Typography.Text>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};
