import React from "react";
import { Container, Grid, Image, Text, Title } from "@mantine/core";
import { StepProps } from ".";
import config from "config.json";
import { StepContainer } from "./StepContainer";

export const StepOne: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          1. Creating an account
        </Title>
      </Container>
      <Grid style={{ marginBottom: "40px" }} gutter={20}>
        <Grid.Col lg={8} md={6} sm={12}>
          <Text>
            If this is your first time using {config.APP_NAME} Web App, you will
            Web App, you will need to create an account. Go to the Registration
            page{" "}
            <a
              href="/register"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              Registration page
            </a>{" "}
            . Fill in your email address, your name and your password. You will
            need to review {config.APP_NAME}{" "}
            <a href="/terms" className="link" target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and accept the conditions for participating in the pilot before
            tapping <strong>Register</strong>.
          </Text>
        </Grid.Col>
        <Grid.Col lg={4} md={6} sm={12}>
          <Image src="images/user-guide/sign-up.jpeg" />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col lg={9} md={6} sm={12}>
          <Title
            order={5}
            id="activating-your-account"
            className="secondary"
            style={{ scrollMarginTop: "100px" }}
          >
            1.1. Activating your account
          </Title>
          <br />
          <Text>
            After completing the registration step you will see a page asking
            you to activate your account. You can do this by entering or copying
            and pasting the 6-digit activation code sent to the email address
            you used to register.
          </Text>
          <br />
          <Text>
            <strong>Note:</strong> You only need to activate your account once.
            The next time you open {config.APP_NAME} Web App, you will be able
            to sign in with the email address and password you used for
            registration.
          </Text>
        </Grid.Col>
        <Grid.Col lg={3} md={6} sm={12}>
          <Image src="images/user-guide/activation-code.jpg" />
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
