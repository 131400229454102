import { Container, List, Text, Title } from "@mantine/core";
import { PublicWrapper } from "components/publicLayout";
import config from "config.json";
import { VideoContainer, VideoContainerProps } from "./VideoContainer";
import { getInnerHtml } from "utils";

type VideoProps = VideoContainerProps & {
  content: string;
};

const videosProps: VideoProps[] = [
  {
    id: "introduction",
    title: "Introduction to the Pilot",
    // link="https://player.vimeo.com/video/815269492"
    videoId: "815269492",
    content: `${config.APP_NAME} tool, which Andy Lopata has been teaching and using
    with private clients for years, is now available in an online form.
    This video is an introduction to ${config.APP_NAME}
    philosophy and its focus on harnessing the full potential of your
    professional network.`,
  },
  {
    id: "seven-stages",
    title: "Seven Stages",
    // link="https://player.vimeo.com/video/816875655?h=08ae278b73"
    videoId: "816875655",
    colored: true,
    reversed: true,
    content: `${config.APP_NAME} offers a multilayered approach to your network.
    Answering the question "<strong>Why</strong> would people want to help
    me?" reveals the first layer: the strength of your relationships. This
    video explains the seven relationship stages and how ${config.APP_NAME}{" "}
    can help you nurture stronger connections.`,
  },
  {
    id: "four-Is",
    title: "Four Is",
    // link="https://player.vimeo.com/video/816876484?h=4da5c4574b"
    videoId: "816876484",
    content: `Another layer of your network is revealed when you ask yourself “
    <strong>How</strong> can people in my network help me?” In this video
    you are introduced to the four categories of support you can find,
    also known as <i>The four Is</i>.`,
  },
  {
    id: "first-look",
    title: "First Look",
    // link="https://player.vimeo.com/video/816880039?h=733414e1a8"
    videoId: "816880039",
    colored: true,
    reversed: true,
    content: `Take a broad view at the online version of ${config.APP_NAME} and its
    main components.`,
  },
  {
    id: "objectives-stakeholder-groups",
    title: "Objectives and Stakeholder Groups",
    // link="https://player.vimeo.com/video/816881718?h=c3782879d5"
    videoId: "816881718",
    content: ` In this video Andy dives deeper into how to use the matrix to define
    an effective objective and identify the stakeholder groups you need to
    focus on in order to achieve it.`,
  },
  {
    id: "adding-contacts",
    title: "Adding Contacts",
    // link="https://player.vimeo.com/video/816882353?h=807e1e4b71"
    videoId: "816882353",
    colored: true,
    reversed: true,
    content: `This video shows you how to start adding your contacts to ${config.APP_NAME}.`,
  },
  {
    id: "taking-actions",
    title: "Taking Action",
    // link="https://player.vimeo.com/video/816883309?h=0ce0382e4b"
    videoId: "816883309",
    content: `Having defined your objective and stakeholder groups and added
    contacts to the matrix, it’s time to determine what practical steps to
    take to achieve that objective. This video shows you how to work with
    Action Plans.`,
  },
  {
    id: "interpreting-the-data",
    title: "Interpreting the Data",
    // link="https://player.vimeo.com/video/816883591?h=0c2b41cb66"
    videoId: "816883591",
    colored: true,
    reversed: true,
    content: `There is a lot of information encoded in each entry of 
    ${config.APP_NAME}: from the position of a contact to the color codes
    of the Four Is. In this video you see how this information can help
    you quickly analyse where your focus needs to be and determine what
    actions to take.`,
  },
];

export const VideoGuidePage: React.FC = () => {
  return (
    <PublicWrapper>
      <Container
        id="agreement-container"
        fluid
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Container id="agreement-title" pt={70} pb={20}>
          <Title
            style={{ color: "var(--secondary-color)", marginBottom: "10px" }}
            align="center"
          >
            Video Guide to {config.APP_NAME}
          </Title>
        </Container>
        <Container id="contents-table-title" pb={40}>
          <Title
            style={{ color: "var(--secondary-color)", marginBottom: "10px" }}
            order={4}
          >
            Table of Contents:
          </Title>
          <List type="ordered" withPadding>
            {videosProps.map((video) => {
              return (
                <List.Item key={video.id}>
                  <a style={{ color: "black" }} href={`#${video.id}`}>
                    {video.title}
                  </a>
                </List.Item>
              );
            })}
          </List>
        </Container>
      </Container>
      {videosProps.map((video, index) => {
        const videoNumber = index + 1;
        return (
          <VideoContainer
            id={video.id}
            key={video.id}
            title={`${videoNumber}. ${video.title}`}
            videoId={video.videoId}
            colored={video.colored}
            reversed={video.reversed}
          >
            <Text dangerouslySetInnerHTML={getInnerHtml(video.content)}></Text>
          </VideoContainer>
        );
      })}
    </PublicWrapper>
  );
};
