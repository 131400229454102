import React from "react";
import { useNavigation } from "@pankod/refine-core";
import { Typography } from "antd";
import { ButtonProps } from "@pankod/refine-antd";
import "./styles.css";
import { Icon } from "@pankod/refine-antd";
import { ActionPlanSvgIcon } from "components/icons";
import { IconButton } from "components/buttons";

type Props = ButtonProps & {
  contactId: number;
  matrixId?: number;
  text?: string;
  tooltipText?: string;
  onClick: () => void;
};

const ActionPlanButton = (props: Props) => {
  const { text, tooltipText, contactId, matrixId, disabled = false } = props;
  const { push } = useNavigation();

  // const onClick = (e: React.MouseEvent) => {
  //   push(`/contacts/${contactId}/action-plans`); // TODO: add matrix id param
  // };

  return (
    <div className="action-plan-button">
      {text && (
        <Typography.Text className="action-plan-button-text">
          {text}
        </Typography.Text>
      )}
      <IconButton
        icon={
          <Icon
            component={ActionPlanSvgIcon}
            className={
              disabled
                ? "action-plan-button-icon-disabled"
                : "action-plan-button-icon"
            }
          />
        } // TODO
        tooltipTitle={tooltipText || text || ""}
        {...props}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          props.onClick();
        }}
      />
    </div>
  );
};

export default ActionPlanButton;
