import { useEffect } from "react";
import { IHookResponse, IBasicMatrixData, IActionPlansResponse, IBaseContact } from "interfaces";
import { useActionPlansStore } from "store/actionPlans";
import { useApiUrl, useCustomMutation, useOne } from "@pankod/refine-core";

export type UseActionPlansProps = {
    contactId: number,
    matrixId?: number
}

export interface IUseActionPlansResponse extends IHookResponse {
    state: {
        matrices: IBasicMatrixData[],
        // contactId: number | string | undefined,
        contactData: IBaseContact | null
        isLoading: boolean
    },
    operations: {
    }
}

type ActionPlansPayload = {
    id: number, // contact id
    matrix_id?: number
}

export const useActionPlans = (props: UseActionPlansProps): IUseActionPlansResponse => {
    const API_URL = useApiUrl();

    const { matrices, setActionPlans, clearStore } = useActionPlansStore(
        (state) => (
            {
                matrices: state.matrices,
                setActionPlans: state.setStore,
                clearStore: state.clearStore
            }
        )
    );

    // const { id: contactId } = useParams();
    // const { operations } = useQuery();

    // TODO: new hook ?
    const { data: response } = useOne<IBaseContact>({
        resource: 'contacts',
        id: props.contactId
    });

    const { mutateAsync, isLoading } = useCustomMutation<IActionPlansResponse>();

    const getActionPlans = async (payload: ActionPlansPayload) => {
        await mutateAsync({
            url: `${API_URL}/contacts/actions`,
            method: "post",
            values: payload,
        }, {
            onSuccess: ({ data }) => {
                setActionPlans(data.matrices, data.actions)
            }
        });
        // TODO: handle errors
    }

    const buildActionPlanPayload = (): ActionPlansPayload | null => {
        const payload: ActionPlansPayload = {
            id: props.contactId
        };

        if (!props.matrixId) return payload;

        payload.matrix_id = props.matrixId;
        return payload
    }

    useEffect(() => {
        if (!props.contactId) return;

        const payload = buildActionPlanPayload();

        if (!payload) return;

        getActionPlans(payload);
        return () => {
            console.log('clear store');
            clearStore();
        }
    }, []);

    return {
        state: {
            matrices,
            // contactId,
            contactData: response?.data || null,
            isLoading
        },
        operations: {
        }
    }
}