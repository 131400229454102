export const salesforceCreateReportSteps = [
    "On the Home page in Salesforce select <b>Reports</b> from the navigation bar, then <b>New Report</b>.",
    "On the <b>Create Report</b> screen, under <b>Category</b>, select <b>Accounts & Contacts</b>. Under <b>Report Type Name</b>, select <b>Contacts & Accounts</b>.",
    "Click the <b>Start Report</b> button. This will open a preview of the report, containing your contacts.",
];

export const salesforceSetUpReportSteps = [
    "Under <b>Outline</b>, find the <b>Columns</b> setting and include the following columns:",
    "<b>Run</b> the report.",
];

export const salesforceExportingSteps = [
    "With the report open, from the drop-down menu, under <b>Edit</b> select <b>Export</b>.",
    "On the <b>Export</b> screen, select <b>Details Only</b> and set up the <b>Format</b> to Excel Format .xls and the <b>Encoding</b> to ISO-8859-1 (General US & Western Europe ISO-Latin-1). ",
    "Click the <b>Export</b< button.",
];

export const salesforceExportFields = [
    {
        name: "First Name",
        required: false,
    },
    {
        name: "Last Name",
        required: true,
    },
    {
        name: "Account Name",
        required: false,
    },
    {
        name: "Email",
        required: false,
    },
    {
        name: "Contact ID",
        required: true,
    },
    {
        name: "Created Date",
        required: true,
    },
];

export const linkedInExportSteps = [
    "At the top of your LinkedIn home page, click the <b>Me</b> icon and select <b>Settings & Privacy</b> from the dropdown menu.",
    "From the menu on the left select <b>Data privacy</b>.",
    "Under the <b>How LinkedIn uses your data</b> section, click <b>Get a copy of your data</b>.",
    "Select <b>Want something in particular?</b>  and <b>Connections</b>.",
    "Click the <b>Request archive</b> button. ",
    "After your request has been approved, you should receive an email with a link for downloading a <b>CSV</b> file containing a list of your LinkedIn contacts.",
];

export const linkedInNote = `<b>Note</b>: How much information is exported depends on each of your
      contacts’ privacy settings. If you see a line like this in your CSV file:
      <br />
      <i>,,,,,28 Mar 2022</i>,
      <br />
      this means that this particular contact's data cannot be exported out of LinkedIn.`;