import { Text, Container } from "@mantine/core";
import { Link } from "@pankod/refine-react-router-v6";
import { useStyles } from "./styles";
import { DiaDrawLogo, Logo } from "components/logos";

interface Props {}

export const Footer: React.FC<Props> = () => {
  const { classes, cx } = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={cx(classes.inner, classes.container)}>
        <div className={classes.logo}>
          <Logo />
        </div>
      </Container>
      <Container className={cx(classes.afterFooter, classes.container)}>
        <Text size="sm" style={{ color: "#868e96" }}>
          © 2023 Andy Lopata. All rights reserved.
        </Text>

        <div className={classes.agreementsLinks}>
          <Link reloadDocument to="/privacy-policy" className={classes.link}>
            Privacy Policy
          </Link>
          <Link reloadDocument to="/terms" className={classes.link}>
            Terms & Conditions
          </Link>
        </div>
      </Container>
      <Container
        className={cx(classes.diadrawLogoContainer, classes.container)}
      >
        <DiaDrawLogo
          type="power-by"
          linkProps={{ className: classes.diadrawLogo }}
        />
      </Container>
    </footer>
  );
};
