import React from "react";
import { StepProps } from ".";
import { Container, Title } from "@mantine/core";

export const StepContainer: React.FC<StepProps> = (props) => {
  return (
    <Container
      py={40}
      px={20}
      id={props.id}
      style={{ scrollMarginTop: "100px" }}
    >
      {props.title && (
        <Container>
          <Title className="hr-with-text hr-line hr-one-line" order={3}>
            {props.title}
          </Title>
        </Container>
      )}
      {props.children}
    </Container>
  );
};
