import {
  useAuthenticated,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import React, { useMemo } from "react";
import { createId } from "utils";
import { CanAccess } from "./CanAccess";

export type AuthenticatedProps = {
  fallback?: React.ReactNode;
  loading?: React.ReactNode;
  options?: AuthOptions;
};

type AuthOptions = {
  checkAccess?: boolean;
};

export const Authenticated: React.FC<AuthenticatedProps> = ({
  children,
  fallback,
  loading,
  options = { checkAccess: true },
}) => {
  const queryId = useMemo(() => {
    return createId();
  }, []);
  const { isSuccess, isFetching, isError } = useAuthenticated(queryId);

  const { replace } = useNavigation();
  const { useLocation } = useRouterContext();
  const { pathname, search } = useLocation();

  if (isFetching) {
    return <>{loading}</> || null;
  }
  if (isError) {
    if (!fallback) {
      const toURL = `${pathname}${search}`;
      if (!pathname.includes("/login")) {
        replace(`/login?to=${encodeURIComponent(toURL)}`);
      }
      return null;
    }

    return <>{fallback}</>;
  }

  if (!isSuccess) return null;

  if (!options.checkAccess) return <>{children}</>;

  return <CanAccess>{children}</CanAccess>;
};
