import { IMatrix, IMatrixCommand } from "interfaces";

export interface IAddNewStakeholderGroupCommand extends IMatrixCommand {
    name: string
}

export interface IAddNewContactCommandResponse { }

export class AddNewStakeholderGroupCommand implements IAddNewStakeholderGroupCommand {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    execute(matrix: IMatrix) {
        return matrix;
    }

    undo(matrix: IMatrix) {
        throw new Error("Method not implemented.");
    }

    toJson(): Object[] {
        return [
            {
                name: "ADD_STAKEHOLDER_GROUP",
                payload: {
                    name: this.name
                }
            }
        ]
    }

}