import React from "react";
import { Row, Col, Layout, Card, Typography, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { ILoginForm } from "interfaces";
import { useLoginController } from "./useLoginController";
import { Logo } from "components/logos";
import { gaEvents } from "services/googleAnalytics";
// import logo from "../../../assets/images/refine.svg";

const { Text, Title } = Typography;

export const LoginPage: React.FC = () => {
  const { state, operations } = useLoginController();
  const { form, hasErrors, loginErrors, isLoading } = state;
  const { handleLogin, translate, clearLoginErrors } = operations;

  const CardTitle = (
    <Title level={3} className="sign-in-form-title">
      {translate("pages.login.title", "Sign in your account")}
    </Title>
  );

  const LoginErrors = (): React.ReactNode[] => {
    return loginErrors.map((error) => {
      return <Text type="danger">{error}</Text>;
    });
  };

  const loginFieldError = hasErrors ? "error" : undefined;

  return (
    <Layout className="sign-in-background">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="sign-in-form">
            <Logo
              containerStyle="sign-in-logo-container"
              imageStyle="sign-in-logo"
            />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                name="login-form"
                form={form}
                onFinish={async (values) => {
                  gaEvents.login();
                  await handleLogin(values);
                }}
                requiredMark
                initialValues={{
                  remember: false,
                }}
                onChange={clearLoginErrors}
              >
                <Form.ErrorList errors={LoginErrors()} />
                <Form.Item
                  name="email"
                  label={translate("pages.login.email", "Email")}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                  validateStatus={loginFieldError}
                >
                  <Input
                    size="large"
                    placeholder={translate("pages.login.email", "Email")}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={translate("pages.login.password", "Password")}
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                  validateStatus={loginFieldError}
                >
                  <Input.Password placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  {translate("pages.login.signin", "Sign in")}
                </Button>
              </Form>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  <Link to="/forgot-password" style={{ fontWeight: "bold" }}>
                    Forgot password?
                  </Link>
                </Text>
              </div>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  {translate("pages.login.noAccount", "Don’t have an account?")}{" "}
                  <Link to="/register" style={{ fontWeight: "bold" }}>
                    {translate("pages.login.signup", "Register")}
                  </Link>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
