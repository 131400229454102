import React from "react";
import { IBaseContact } from "interfaces";
import { Badge, Tooltip, Typography } from "@pankod/refine-antd";
import "./styles.css";
import ImpactBarChart from "components/impactBarChart";
import ContactTypeBadge, { BadgeTypes } from "components/contactTypeBadge";
import classNames from "classnames";
import { BellFilled } from "@ant-design/icons";

type Props = {
  contact: IBaseContact;
};

function ContactCard({ contact }: Props) {
  const {
    // name,
    first_name,
    last_name,
    implementer,
    influencer,
    information,
    introducer,
    has_action_plan,
  } = contact;

  const showChat = true; // TODO
  const showType = true; // TODO

  const getNotificationIcon = (): JSX.Element | null => {
    return has_action_plan ? (
      <BellFilled rotate={-25} className="badge" />
    ) : null;
  };

  return (
    <Badge className="badge-container" count={getNotificationIcon()}>
      <div className="card-container">
        {showChat && (
          <Tooltip title="How can they help you">
            <div className="bar-chart-container">
              <ImpactBarChart
                implementer={implementer || 0}
                influencer={influencer || 0}
                information={information || 0}
                introducer={introducer || 0}
              />
            </div>
          </Tooltip>
        )}
        <div
          className={classNames("contact-info-container", {
            "contact-info-name-container": !showType,
          })}
        >
          {showType && (
            <ContactTypeBadge
              contactType={contact.type}
              relationshipStrength={contact.relationship_strength}
              badgeType={BadgeTypes.ICON_AND_RELATIONSHIP}
            />
          )}
          <p className="name-container">
            <Typography.Text>{`${first_name} ${last_name}`}</Typography.Text>
          </p>
        </div>
      </div>
    </Badge>
  );
}

export default ContactCard;
