import React from "react";
import { Container, Grid, Image, Text, Title } from "@mantine/core";
import { StepProps } from ".";
import config from "config.json";
import { StepContainer } from "./StepContainer";

export const StepTwo: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          2. The main menu
        </Title>
      </Container>
      <Grid style={{ paddingBottom: "40px" }}>
        <Grid.Col lg={7} md={6} sm={12}>
          <Image src="images/user-guide/main-menu.jpg" />
        </Grid.Col>
        <Grid.Col lg={5} md={6} sm={12}>
          <Text>
            When you first sign in, you will be taken to the Matrices screen and
            will see the main menu on the left.
          </Text>
        </Grid.Col>
      </Grid>
      <Grid style={{ marginBottom: "40px" }}>
        <Grid.Col lg={7} md={6} sm={12}>
          <Text>
            If you are using {config.APP_NAME} on a mobile device, you may see a
            black button on the left instead. Tap it to see the main menu. Tap
            it again to hide the menu.
          </Text>
        </Grid.Col>
        <Grid.Col lg={5} md={6} sm={12}>
          <Image src="images/user-guide/main-menu-mobile.jpg" />
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
