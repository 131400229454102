import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Spin,
  SaveButton,
  Affix,
  Space,
} from "@pankod/refine-antd";
import { SaveButtonProps } from "@pankod/refine-antd/dist/components/buttons/save";
import { ContactForm } from "components/contactForm";
import { Create } from "components/crud";
import useDeviceSize from "hooks/useDeviceSize";
import { defaultContact } from "utils";
import { IAddContactForm } from "interfaces";
import SearchContactFrom from "components/searchContactFrom";
import { ServerErrors } from "types";

type AddContactDrawerProps = {
  drawerProps: DrawerProps;
  saveButtonProps: SaveButtonProps;
  formProps: FormProps;
  title?: string;
  isLoading?: boolean;
  mutationErrors?: ServerErrors;
  hideSearch?: boolean;
  onSearchSuccess?(contactId: number): void;
  close?(): void;
};

export const AddContactDrawer: React.FC<AddContactDrawerProps> = ({
  drawerProps,
  saveButtonProps,
  formProps,
  title,
  isLoading,
  mutationErrors,
  hideSearch,
  onSearchSuccess,
  close,
}) => {
  const [createFormVisible, setCreateFromVisible] = useState<boolean>(
    !!hideSearch
  );
  const { state: deviceSizeState } = useDeviceSize();

  const ToggleButton = () => {
    return (
      <Button onClick={() => setCreateFromVisible(!createFormVisible)}>
        {createFormVisible ? "Close form" : "Create new contact"}
      </Button>
    );
  };

  const [form] = Form.useForm<IAddContactForm>();
  const selectedContact = Form.useWatch("contactId", form);

  useEffect(() => {
    return () => {
      formProps.form?.resetFields();
    };
  }, []);

  return (
    <Drawer
      {...drawerProps}
      title={title}
      width={deviceSizeState.isSmall ? "100%" : "500px"}
    >
      <Spin spinning={isLoading}>
        {!hideSearch && (
          <>
            <SearchContactFrom
              formProps={{
                form: form,
                onFinish: (values: IAddContactForm) => {
                  const { contactId } = values;
                  onSearchSuccess?.(contactId);
                  close?.();
                },
              }}
              filedPath="contactId"
              submitButton
              submitButtonProps={{
                disabled: !(typeof selectedContact === "number"),
                text: "Add contact",
              }}
              footerButtons={<ToggleButton />}
            />
            <Divider />
          </>
        )}
        {createFormVisible && (
          <Create
            saveButtonProps={saveButtonProps}
            title=""
            footerButtons={
              <Affix offsetBottom={40}>
                <Space style={{ float: "right" }}>
                  {!hideSearch && <ToggleButton />}
                  <SaveButton {...saveButtonProps} />
                </Space>
              </Affix>
            }
            noPadding
            fullWidth
          >
            <ContactForm
              formProps={{ ...formProps, initialValues: defaultContact }}
              formErrors={mutationErrors}
            />
          </Create>
        )}
      </Spin>
    </Drawer>
  );
};
