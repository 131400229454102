import React from "react";
import {
    Typography
} from "@pankod/refine-antd";
import './styles.css'

type CreateMatrixCardProps = {
    onClick(): void
}

const CreateMatrixCard = (props: CreateMatrixCardProps) => {
    return <div
        onClick={props.onClick}
        className='matrix-card matrix-create-card-body'
    >
        <img src="images/matrices/create-matrix.svg" alt="create-matrix" height={"100px"} />
        <div className="matrix-create-title-container">
            <Typography.Title level={4} className="matrix-create-title">
                Create Matrix
            </Typography.Title>
        </div>
    </div>
}

export default CreateMatrixCard;