import {
  IBaseContact,
  IBaseMatrix,
  IHookResponse,
  IMatrix,
  IStakeholderGroup,
} from "interfaces";
import { useMatrixHistory } from "./useMatrixHistory";
import { useOne } from "@pankod/refine-core";
import { transformToMatrixData } from "utils";
import useMatrixCommands, {
  IUseMatrixCommandsOperations,
} from "./useMatrixCommands";
import { useMatrixStore } from "store";

export interface IUseMatrixProps {
  id: number | undefined;
}

export interface IUseMatrixOperations extends IUseMatrixCommandsOperations {
  getContactById(id: number): IBaseContact | null;
  getContactRelationship(id: number): number | null;
  getNextStakeholderGroupName(): string;
  getStakeholderGroups(): IStakeholderGroup[];
  reloadMatrix(): void;
}

export interface IUseMatrixResponse extends IHookResponse {
  state: {
    matrix: IMatrix | null;
    isLoading: boolean;
  };
  operations: IUseMatrixOperations;
}

export const useMatrix = ({ id }: IUseMatrixProps): IUseMatrixResponse => {
  const matrixId = id || "";

  const { state, operations } = useMatrixHistory({ id: matrixId });
  const { isLoading: isMatrixUpdateLoading } = state;
  const { updateMatrix } = operations;

  const { isFetching, refetch, isRefetching } = useOne<IBaseMatrix>({
    resource: "matrices",
    id: matrixId,
    queryOptions: {
      onSuccess: (responseData) => {
        console.log("here");
        const matrixData: IBaseMatrix = responseData.data;
        const transformedData: IMatrix = transformToMatrixData(matrixData);
        updateMatrix(transformedData);
      },
    },
  });

  const { matrix, getContactById, getContactRelationship } = useMatrixStore(
    (state) => ({
      matrix: state.matrix,
      getContactById: state.getContactById,
      getContactRelationship: state.getContactRelationship,
    })
  );

  const { operations: commands } = useMatrixCommands({
    executeCommand: operations.addCommand,
    getContactRelationship,
  });

  const getNextStakeholderGroupName = () => {
    const stakeholderGroupSize = matrix?.stakeholder_groups.size || 0;

    return `Stakeholder group ${stakeholderGroupSize + 1}`;
  };

  const getStakeholderGroups = (): IStakeholderGroup[] => {
    const stakeholderGroupsMap = matrix?.stakeholder_groups;

    if (!stakeholderGroupsMap) return [];

    return Array.from(
      stakeholderGroupsMap,
      ([key, stakeholder_group]) => stakeholder_group
    );
  };

  return {
    state: {
      matrix,
      isLoading: isFetching || isRefetching || isMatrixUpdateLoading,
    },
    operations: {
      getContactById,
      getContactRelationship,
      getNextStakeholderGroupName,
      getStakeholderGroups,
      moveContact: commands.moveContact,
      addNewContact: commands.addNewContact,
      addNewContacts: commands.addNewContacts,
      removeContactFromGroup: commands.removeContactFromGroup,
      addNewStakeholderGroup: commands.addNewStakeholderGroup,
      removeStakeholderGroup: commands.removeStakeholderGroup,
      renameMatrix: commands.renameMatrix,
      renameStakeholderGroup: commands.renameStakeholderGroup,
      reloadMatrix: refetch,
    },
  };
};
