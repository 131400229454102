import React from "react";
import { useAuthenticated, useNavigation } from "@pankod/refine-core";
import {
  Title,
  Text,
  Container,
  Button,
  Overlay,
  Grid,
  Image,
} from "@mantine/core";
import { useStyles } from "./style";
import { PublicWrapper } from "components/publicLayout";
import config from "config.json";

export const HomePage: React.FC = () => {
  const { push } = useNavigation();

  const { isSuccess: loggedIn } = useAuthenticated();

  const { classes, cx } = useStyles();

  return (
    <PublicWrapper>
      <div className={classes.heroWrapper}>
        <Overlay color="#000" opacity={0.7} zIndex={1} />

        <div className={classes.heroInner}>
          <Title className={cx(classes.text, classes.heroTitle)}>
            Welcome to {config.APP_NAME}
          </Title>

          <Container>
            <Text
              size="lg"
              className={cx(classes.text, classes.heroDescription)}
            >
              Build your professional relationships
            </Text>
          </Container>

          <div className={classes.heroControls}>
            {loggedIn && (
              <Button
                className={classes.heroControl}
                variant="white"
                size="lg"
                onClick={() => {
                  push("/matrices");
                }}
              >
                Dashboard
              </Button>
            )}
            {!loggedIn && (
              <>
                <Button
                  className={classes.heroControl}
                  variant="white"
                  size="lg"
                  onClick={() => {
                    push("/login");
                  }}
                >
                  Sign in
                </Button>
                <Button
                  className={classes.heroControl}
                  variant="white"
                  size="lg"
                  onClick={() => {
                    push("/register");
                  }}
                >
                  Register
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <Container className={classes.matrixWrapper}>
        <Grid>
          <Grid.Col md={8} xs={12}>
            <Image src="/images/relationship-matrix.jpg" />
          </Grid.Col>
          <Grid.Col md={4} xs={12}>
            <Text className={cx(classes.text, classes.purpose)}>
              {/* Find the most impactful relationships for your mission. */}
              Who will help you achieve your goals?
            </Text>
            <Text
              className={cx(classes.text, classes.purposeDescription)}
              mt="md"
            >
              {/* Find the most impactful relationships for your mission. */}
              Success becomes easier to reach with the help of others. Surround
              yourself with a network of people who are in a position to help
              you, want to help you and know how to help you and you will be far
              more effective in your role and successful in your career.
            </Text>
          </Grid.Col>
        </Grid>
      </Container>
    </PublicWrapper>
  );
};
