import { IMatrix, IMatrixCommand } from "interfaces";

export interface IRenameStakeholderGroupCommand extends IMatrixCommand {
    name: string,
    groupId: number
}

export interface IRenameStakeholderGroupCommandResponse { }

export class RenameStakeholderGroupCommand implements IRenameStakeholderGroupCommand {
    name: string;
    groupId: number;

    constructor(name: string, groupId: number) {
        this.name = name;
        this.groupId = groupId;
    }


    execute(matrix: IMatrix): IRenameStakeholderGroupCommandResponse {
        return {}
    }
    undo(matrix: IMatrix): IRenameStakeholderGroupCommandResponse {
        return {}
    }
    toJson(): Object[] {
        return [
            {
                name: "RENAME_STAKEHOLDER_GROUP",
                payload: {
                    name: this.name,
                    id: this.groupId
                }
            }
        ]
    }
}