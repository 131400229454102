import { Impact_Types, ImpactTypes } from "types";

type Tooltips = {
  title: string;
  stages: string[];
  stakeholders: string;
  impactCategories: {
    [key in ImpactTypes]: string;
  };
};

export const tooltips: Tooltips = {
  title:
    "Keep your objective specific enough to focus your attention but broad enough to involve more than one stakeholder.",
  stages: [
    "Who could introduce you to them? Look for the high-scoring introducers on your Matrix.",
    "Have you met at a couple of events but haven’t really had a conversation?",
    "They know who you are when they see you and, in general terms, what you do.",
    "They naturally engage in small talk with you. Sometimes you may not find rapport but can develop respect.",
    "They see you as reliable and unlikely to let them down.",
    "If you asked them for help they would be happy to provide it.",
    "They are likely to recognise opportunities and speak about you even if you’re not in the room.",
    "They move into your personal network.",
  ],
  stakeholders:
    "Who are the groups of people who can influence your success? Don’t just list individuals, you are trying to identify people you haven’t yet thought of.",
  impactCategories: {
    [Impact_Types.INFLUENCER]:
      "How influential are they on this objective specifically?",
    [Impact_Types.IMPLEMENTER]:
      "How involved are they in delivery related to your objective?",
    [Impact_Types.INTRODUCER]:
      "How well positioned are they to open doors for you to key stakeholders?",
    [Impact_Types.INFORMATION]:
      "Do they know what is going on, what the key priorities are and are they able to share with you?",
  },
};
