import React from "react";
import { RefineCrudListProps } from "@pankod/refine-ui-types";
import { List } from "@pankod/refine-antd";
import classNames from "classnames";
import "../styles.css";

export interface IListProps extends RefineCrudListProps {}

//  TODO
export const RelationshipMatrixList: React.FC<IListProps> = ({
  children,
  wrapperProps,
  ...props
}) => {
  return (
    <List
      wrapperProps={{
        className: classNames("crud-container-width"),
        ...wrapperProps,
      }}
      {...props}
    >
      {children}
    </List>
  );
};
