import React, { useEffect } from "react";
import { DrawerProps, Form, FormProps, SaveButton } from "@pankod/refine-antd";
import { useApiUrl, useCustomMutation } from "@pankod/refine-core";
import { Drawer } from "components/drawers";
import { IContactImpact } from "interfaces";
import ContactImpactForm from "components/contactImpactForm";
import { ContactTypes } from "types";
import { ImpactHelp } from "components/guidingQuestions";

type EditImpactDrawerProps = {
  drawerProps: DrawerProps;
  fromProps?: FormProps<IContactImpact>;
  matrixId: number;
  contactId: number;
  title?: string;
  isLoading?: boolean;
  close?(): void;
};

const defaultValues: IContactImpact = {
  type: ContactTypes.NEUTRAL,
  implementer: 0,
  influencer: 0,
  information: 0,
  introducer: 0,
};

type ContactProperties = IContactImpact & {
  // TODO: talk with Georgi O to remove additional fields
  id: number;
  contact_id: number;
  matrix_id: number;
};

export const EditImpactDrawer: React.FC<EditImpactDrawerProps> = ({
  drawerProps,
  isLoading = false,
  title,
  fromProps,
  contactId,
  matrixId,
  close,
}) => {
  const [form] = Form.useForm<IContactImpact>();
  const API_URL = useApiUrl();

  const { mutateAsync, isLoading: isMutationLoading } =
    useCustomMutation<ContactProperties>();

  const onMutationSuccess = (data: ContactProperties) => {
    const { implementer, influencer, information, introducer, type } = data;
    form.setFieldsValue({
      implementer,
      influencer,
      information,
      introducer,
      type,
    });
  };

  const onFinish = async (values: IContactImpact) => {
    await mutateAsync(
      {
        method: "post",
        url: `${API_URL}/contacts/properties/update`,
        values: {
          id: contactId,
          matrix_id: matrixId,
          ...values,
        },
      },
      {
        onSuccess: ({ data }) => {
          if (close) return close();

          onMutationSuccess(data);
        },
        onError: () => {}, //TODO
      }
    );
  };

  const getContactProperties = async () => {
    await mutateAsync(
      {
        method: "post",
        url: `${API_URL}/contacts/properties`,
        values: {
          id: contactId,
          matrix_id: matrixId,
        },
      },
      {
        onSuccess: ({ data }) => onMutationSuccess(data),
        onError: () => {}, //TODO
      }
    );
  };

  useEffect(() => {
    getContactProperties();

    return () => {
      form.resetFields();
    };
  }, []);

  return (
    <Drawer
      title={title || "Potential impact on success"}
      isLoading={isLoading || isMutationLoading}
      {...drawerProps}
    >
      <ContactImpactForm
        formProps={
          fromProps ?? {
            form,
            onFinish,
            initialValues: defaultValues,
          }
        }
      />
      <div style={{ display: "flex", justifyContent: "end" }}>
        <SaveButton onClick={form.submit} />
      </div>
      <br />
      <div>
        <ImpactHelp />
      </div>
    </Drawer>
  );
};
