import React, { useEffect } from "react";
import {
  Drawer,
  DrawerProps,
  FormProps,
  Spin,
  SaveButton,
  Affix,
} from "@pankod/refine-antd";
import { SaveButtonProps } from "@pankod/refine-antd/dist/components/buttons/save";
import { ContactForm } from "components/contactForm";
import { Edit } from "components/crud";
import useDeviceSize from "hooks/useDeviceSize";
import { ServerErrors } from "types";

type EditContactDrawerProps = {
  drawerProps: DrawerProps;
  saveButtonProps: SaveButtonProps;
  formProps: FormProps;
  title?: string;
  isLoading?: boolean;
  mutationErrors?: ServerErrors;
  close?(): void;
};

export const EditContactDrawer: React.FC<EditContactDrawerProps> = ({
  drawerProps,
  saveButtonProps,
  formProps,
  title,
  isLoading,
  mutationErrors,
  close,
}) => {
  const { state: deviceSizeState } = useDeviceSize();

  useEffect(() => {
    return () => {
      formProps.form?.resetFields();
    };
  }, []);

  return (
    <Drawer
      {...drawerProps}
      title={title}
      width={deviceSizeState.isSmall ? "100%" : "500px"}
    >
      <Spin spinning={isLoading}>
        <Edit
          saveButtonProps={saveButtonProps}
          title=""
          footerButtons={
            <Affix offsetBottom={40}>
              <SaveButton {...saveButtonProps} />
            </Affix>
          }
          fullWidth
          noPadding
        >
          <ContactForm formProps={formProps} formErrors={mutationErrors} />
        </Edit>
      </Spin>
    </Drawer>
  );
};
