import React from "react";
import { Edit } from "@pankod/refine-antd";
import { RefineCrudEditProps } from "@pankod/refine-ui-types";
import "../styles.css";
import classNames from "classnames";

export interface IEditProps extends RefineCrudEditProps {
  fullWidth?: boolean;
  noPadding?: boolean;
}

export const RelationshipMatrixEdit: React.FC<IEditProps> = ({
  saveButtonProps,
  title,
  children,
  wrapperProps,
  contentProps,
  headerProps,
  fullWidth,
  noPadding,
  ...props
}) => {
  const hasTitle = title !== "";

  return (
    <Edit
      wrapperProps={{
        className: classNames(
          { "crud-container-width": !fullWidth },
          {
            "hide-heading": !hasTitle,
          }
        ),
        ...wrapperProps,
      }}
      goBack={null}
      saveButtonProps={saveButtonProps}
      headerButtons={<></>}
      breadcrumb={<></>}
      contentProps={{
        bodyStyle: noPadding ? { padding: "0" } : { padding: "24px 0" },
        ...contentProps,
      }}
      headerProps={{
        className: classNames({ "crud-container-no-padding": noPadding }),
        ...headerProps,
      }}
      footerButtonProps={{
        style: { flexWrap: "wrap", gap: "8px", float: "right" },
      }}
      title={title}
      {...props}
    >
      {children}
    </Edit>
  );
};
