import { HttpError, useCreate, useLogin } from '@pankod/refine-core'
import { IRegisterForm } from 'interfaces';
import { ServerErrors } from 'types';

export type UseRegistrationProps = {
    onErrorCallBack?: (errors: ServerErrors) => void
}

export const useRegistration = ({ onErrorCallBack = () => { } }: UseRegistrationProps) => {
    const { data, isLoading: registrationLoading, mutate } = useCreate();
    const { mutate: login, isLoading: loginIsLoading } = useLogin<{ token: string, user_id: any }>();

    const register = (values: IRegisterForm) => {
        mutate({
            resource: 'register',
            values,
            errorNotification: {
                message: "Registration was unsuccessful",
                type: "error"
            }
        }, {
            onSuccess: ({ data: response }) => {
                const { token = "", user_id } = response;

                login({ token, user_id });
            },
            onError: (err: HttpError) => {
                const { response = {} } = err;
                const { data = {} } = response;
                const { errors = {} } = data;

                onErrorCallBack(errors);
            },
        })
    }

    return {
        register,
        isLoading: registrationLoading || loginIsLoading
    }
}
