import React from "react";
import config from "config.json";
import { AntdList, Collapse, Typography } from "@pankod/refine-antd";
import ImpactCategoriesRow from "components/impactCategoriesRow";

const { Panel } = Collapse;

type Props = {};

export const ImpactHelp = () => {
  return (
    <>
      <Typography.Title className="primary" level={4}>
        How can they help you?
      </Typography.Title>
      <div>
        Generally speaking, your network can help you to achieve your objective
        in four ways:
      </div>
      <br />
      <div>
        <Typography.Text>
          <span className="secondary bold">IMPLEMENT</span> It’s natural to try
          to win over the key decision makers but don’t forget the people who
          put your ideas into action. If they are not onboard, then your chances
          of success are substantially lower.
        </Typography.Text>
      </div>
      <br />
      <div>
        <Typography.Text>
          <span className="secondary bold">INFLUENCE</span> To whom are they
          talking and what message can they share that might sway key decision
          makers? You can’t be in the room every time your ideas or proposals
          are discussed, so who is fighting in your corner when you’re not
          there?
        </Typography.Text>
      </div>
      <br />
      <div>
        <Typography.Text>
          <span className="secondary bold">INTRODUCE</span> You won’t know
          everyone who can support your ideas and move them forward, so who can
          open those doors for you? People are more likely to take you and your
          proposals seriously if someone they trust makes the introduction.
        </Typography.Text>
      </div>
      <br />
      <div>
        <Typography.Text>
          <span className="secondary bold">
            SOURCES OF INFORMATION, INSIGHT & IDEAS
          </span>{" "}
          You need to know what people are thinking, what challenges they are
          facing and what their priorities are. If you don’t, it’s much harder
          to ensure that your ideas will resonate with them.
        </Typography.Text>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Typography.Title className="primary" level={4}>
          Implementers
        </Typography.Title>
        <AntdList split={false} size="small">
          <AntdList.Item>
            - What decision(s) do you need them to make?
          </AntdList.Item>
          <AntdList.Item>- Who decides and who implements?</AntdList.Item>
          <AntdList.Item>
            - How well do they know you or your reputation?
          </AntdList.Item>
          <AntdList.Item>- What is most important to them?</AntdList.Item>
          <AntdList.Item>
            - How much impact will each individual have on a positive outcome
            for you?
          </AntdList.Item>
          <AntdList.Item>
            - Who could be a negative influence or block your success?
          </AntdList.Item>
        </AntdList>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Typography.Title className="primary" level={4}>
          Influencers
        </Typography.Title>
        <AntdList split={false} size="small">
          <AntdList.Item>- Who can they influence for you?</AntdList.Item>
          <AntdList.Item>
            - What will resonate for them and how does the outcome impact them?
          </AntdList.Item>
          <AntdList.Item>- How influential are they?</AntdList.Item>
          <AntdList.Item>
            - How much impact will each individual have on a positive outcome
            for you?
          </AntdList.Item>
          <AntdList.Item>
            -Who could be a negative influence or block your success?
          </AntdList.Item>
        </AntdList>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Typography.Title className="primary" level={4}>
          Introducers
        </Typography.Title>
        <AntdList split={false} size="small">
          <AntdList.Item>- Who do you need to meet?</AntdList.Item>
          <AntdList.Item>- Who could introduce you to them?</AntdList.Item>
          <AntdList.Item>
            - How much/why would they want to introduce you?
          </AntdList.Item>
        </AntdList>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Typography.Title className="primary" level={4}>
          Information, Insight & Ideas
        </Typography.Title>
        <AntdList split={false} size="small">
          <AntdList.Item>- What do you need to know?</AntdList.Item>
          <AntdList.Item>- Who could help you?</AntdList.Item>
          <AntdList.Item>- Why would they want to help you?</AntdList.Item>
          <AntdList.Item>
            - What information can you share with them?
          </AntdList.Item>
        </AntdList>
      </div>
    </>
  );
};

const GuidingQuestions = (props: Props) => {
  return (
    <>
      <Collapse>
        <Panel header="Step One - What is your objective?" key={"step-one"}>
          <Typography.Title className="primary" level={4}>
            What is your objective?
          </Typography.Title>
          <div>
            <Typography.Text>
              Be <span className="primary bold">laser-focused</span> on what you
              are setting as your objective for each individual{" "}
              <span className="secondary bold">{config.APP_NAME}</span>. If you
              go too broad, you will be at the risk of missing key people who
              can help you. Start with a laser focus and broaden your sights if
              you need to.
            </Typography.Text>
          </div>
          <br />
          <div>
            <Typography.Text>
              For example, a broad goal for your career might be ‘I want to be a
              senior manager within five years’. For sales it might be ‘I want
              to increase my sales by the end of the year’.
            </Typography.Text>
          </div>
          <br />
          <div>
            <Typography.Text>
              Laser-focused goals could be ‘Within five years I want to lead our
              Communications Team’ or ‘I want to win a new contract with ABC
              Corporation to deliver all of their new widgets’. Those clearer
              objectives will make it easier to then complete the next stages of
              the <span className="secondary bold">{config.APP_NAME}</span>,
              identifying the key stakeholder groups and individuals within
              those groups who are going to help you reach your goals.
            </Typography.Text>
          </div>
        </Panel>
        <Panel
          header="Step Two - Who are your stakeholder groups?"
          key={"step-two"}
        >
          <div>
            <Typography.Title className="primary" level={4}>
              Who are your stakeholder groups?
            </Typography.Title>
            <div>
              <Typography.Text>
                Identify groups of people who can have an influence on your
                success. Now is the time to broaden your thinking. Many
                approaches will only ask you to consider directly relevant
                parties. But people across the board can influence the success
                of a project.
              </Typography.Text>
            </div>
            <br />
            <div>
              <Typography.Text>
                Possible stakeholder groups might be in your own organisation,
                within competitors, across your industry, among suppliers and
                consultants to the people you want to impact and beyond.
              </Typography.Text>
            </div>
            <br />
            <div>
              <Typography.Text>
                Think of everyone who has a say and ask yourself what
                stakeholder group they might belong to.
              </Typography.Text>
            </div>
            <br />
            <div>
              <Typography.Text>
                <span className="primary bold">Please note</span>: An individual
                is not a group! Challenge yourself and ask who else is in a
                similar position to them and might also have a say. This is
                where you can identify people you hadn’t previously thought of.
              </Typography.Text>
            </div>
          </div>
          <br />
          <div>
            <Typography.Title className="primary" level={4}>
              Guiding Questions:
            </Typography.Title>
            <AntdList split={false} size="small">
              <AntdList.Item>- Who is in this group?</AntdList.Item>
              <AntdList.Item>- Who makes the ultimate decision?</AntdList.Item>
              <AntdList.Item>
                - Who and what influences them (positively or negatively)?
              </AntdList.Item>
            </AntdList>
          </div>
        </Panel>
        <Panel header="Step Three - How can they help you?" key={"step-three"}>
          <ImpactHelp />
        </Panel>
        <Panel
          header="Step Four - How strong is your relationship?"
          key={"step-four"}
        >
          <Typography.Title className="primary" level={4}>
            How strong is your relationship?
          </Typography.Title>
          <div>
            <Typography.Text>
              Where on the{" "}
              <span className="primary bold">
                Seven Stages of Relationships
              </span>{" "}
              does each person in each stakeholder group sit? Put the
              corresponding names in the fields below of everyone you have
              identified so far based on how you would currently measure your
              relationship with them:
            </Typography.Text>
          </div>
          <br />
          <div>
            <Typography.Text>
              0 = Don’t know <br />
              1 = Recognise - You’ve met at various meetings but not necessarily
              spoken in depth. <br />
              2 = Know - They know who you are and what you do. <br />
              3 = Like - Even if you don’t positively like each other, do they
              respect you? <br />
              4 = Trust - Remember, trust has different layers. They may trust
              you but not your role or organisation. Think of all layers of
              trust that are relevant/important to your ask. <br />
              5 = Support - A supporter is someone who, if you needed help and
              asked, would be happy to help you. <br />
              6 = Advocate - An advocate is someone who recognises opportunities
              to mention you or support you even when you don’t ask. They know
              you well, understand what you’re trying to achieve and get
              pleasure from helping you reach your goals.
              <br />7 = Friend
            </Typography.Text>
          </div>
        </Panel>
        <Panel header="Step Five - What is your next step?" key={"step-five"}>
          <Typography.Title className="primary" level={4}>
            What is your next step?
          </Typography.Title>
          <div>
            <Typography.Text>
              Once you have filled in your Relationship Matrix with names, use
              the following guiding questions to help you determine your next
              best step:
            </Typography.Text>
          </div>
          <br />
          <div>
            <AntdList
              split={false}
              size="small"
              header={
                <Typography.Text className="bold">
                  Strengthening your network
                </Typography.Text>
              }
            >
              <AntdList.Item>
                - With whom do you have a relationship but it’s not yet strong
                enough?
              </AntdList.Item>
              <AntdList.Item>
                - What are your next steps to develop the relationship?
              </AntdList.Item>
              <AntdList.Item>
                - At what level of relationship are you comfortable asking for
                their help?
              </AntdList.Item>
            </AntdList>
          </div>
          <div>
            <AntdList
              split={false}
              size="small"
              header={
                <Typography.Text className="bold">
                  Asking for help
                </Typography.Text>
              }
            >
              <AntdList.Item>
                - With whom do you have a strong relationship but they are not
                helping you?
              </AntdList.Item>
              <AntdList.Item>
                - What help have you asked them for?
              </AntdList.Item>
              <AntdList.Item>
                - What help should you be asking them for?
              </AntdList.Item>
            </AntdList>
          </div>
          <div>
            <AntdList
              split={false}
              size="small"
              header={
                <Typography.Text className="bold">
                  Expanding your network
                </Typography.Text>
              }
            >
              <AntdList.Item>
                - Who do you need to know but haven’t met yet?
              </AntdList.Item>
              <AntdList.Item>- Why would they want to meet you?</AntdList.Item>
              <AntdList.Item>
                - How will you engineer an introduction?
              </AntdList.Item>
            </AntdList>
          </div>
        </Panel>
        <Panel header="Bonus Tips" key={"bonus-material"}>
          <Typography.Title className="primary" level={4}>
            How do you deepen your relationships with key contacts?
          </Typography.Title>
          <AntdList
            split={false}
            size="small"
            header={
              <Typography.Text>Here are some simple tips:</Typography.Text>
            }
          >
            <AntdList.Item>
              <span className="bold">1. 24-7-30*</span>
              <br />
              Follow up 24 hours after first meeting someone, again after a week
              (send a personalised LinkedIn connection request) and again after
              a month (a face-to-face meeting or virtual coffee would be ideal).
              Aim for three meaningful two way interactions to cement the
              connection. <br />
              Credit: Scanlon/Misner
            </AntdList.Item>
            <AntdList.Item>
              <span className="bold">
                2. Be consistent and frequent in your interactions
              </span>
              <br />
              Don’t think that you can connect once and the job is done, or that
              a meal together will lead to a lifelong relationship. Keep in
              touch, in sight and in mind so that all interactions feel natural
              and seamless.
            </AntdList.Item>
            <AntdList.Item>
              <span className="bold">3. Reach out without agenda</span>
              <br />
              Make the majority of your outreach about the other person, not
              about yourself. Asking for something should be the exception, not
              the rule. People are interested in those who are interested in
              them. Say ‘you’ and ‘we’ more than you say ‘I’ or ‘me’.
            </AntdList.Item>
            <AntdList.Item>
              <span className="bold">4. Stop counting</span>
              <br />
              Help others if you can without looking for what you can get in
              return. Not as a box-ticking exercise but because you genuinely
              can help them and feel comfortable doing so. You may end up
              helping one person in your network but getting support from
              another direction.
            </AntdList.Item>
            <AntdList.Item>
              <span className="bold">5. Play where they play</span>
              <br />
              If you want to build consistent and frequent communication with
              someone who is active on LinkedIn or Twitter, engage with them on
              LinkedIn or Twitter. It doesn’t matter whether it’s your network
              of choice, they are presenting you with an opportunity to engage,
              take it. But do so thoughtfully, don’t become a stalker by simply
              liking everything they post!
            </AntdList.Item>
          </AntdList>
        </Panel>
      </Collapse>
      <ImpactCategoriesRow
        rowColProps={{ md: 24 }}
        itemColProps={{ md: 24 }}
        rowStyles={{ borderColor: "white" }}
      />
    </>
  );
};

export default GuidingQuestions;
