import { IMatrix, IMatrixCommand } from "interfaces";
import { ChangeRelationshipCommand, IChangeRelationshipCommand, IChangeRelationshipResponse } from "./ChangeRelationshipCommand";
import { ChangeStakeholderGroupCommand, IChangeGroupResponse, IChangeStakeholderGroupCommand } from "./ChangeStakeholderGroupCommand";


export interface IChangeStakeholderGroupAndRelationshipCommand extends IMatrixCommand {
    relationshipCommand: IChangeRelationshipCommand;
    stakeholderGroupCommand: IChangeStakeholderGroupCommand
};

export interface IChangeRelationshipAndGroupResponse extends IChangeRelationshipResponse, IChangeGroupResponse { }

export class ChangeStakeholderGroupAndRelationshipCommand implements IChangeStakeholderGroupAndRelationshipCommand {
    relationshipCommand: IChangeRelationshipCommand;
    stakeholderGroupCommand: IChangeStakeholderGroupCommand;

    constructor(contactId: number, currentGroup: number, newGroup: number, newRelationship: number, currentRelationship: number) {
        this.relationshipCommand = new ChangeRelationshipCommand(contactId, currentRelationship, newRelationship);
        this.stakeholderGroupCommand = new ChangeStakeholderGroupCommand(contactId, currentGroup, newGroup);
    }

    execute(matrix: IMatrix): IChangeRelationshipAndGroupResponse {
        const relationshipData = this.relationshipCommand.execute(matrix);
        const groupData = this.stakeholderGroupCommand.execute(matrix);

        return {
            ...relationshipData,
            ...groupData
        }
    }
    undo(matrix: IMatrix): IChangeRelationshipAndGroupResponse {
        const relationshipData = this.relationshipCommand.undo(matrix);
        const groupData = this.stakeholderGroupCommand.undo(matrix);

        return {
            ...relationshipData,
            ...groupData
        }
    }
    toJson(): Object[] {
        const relationship: Object[] = this.relationshipCommand.toJson();
        const group: Object[] = this.stakeholderGroupCommand.toJson();

        return [...relationship, ...group];
    }
}