import { IMatrix, IMatrixCommand } from "interfaces";

export interface IAddNewContactCommand extends IMatrixCommand {
    contactId: number,
    groupId: number
}

export interface IAddNewContactCommandResponse { }

export class AddNewContactCommand implements IAddNewContactCommand {
    contactId: number;
    groupId: number;

    constructor(contactId: number, groupId: number) {
        this.contactId = contactId;
        this.groupId = groupId;
    }


    execute(matrix: IMatrix): IAddNewContactCommandResponse {
        return {}
    }
    undo(matrix: IMatrix): IAddNewContactCommandResponse {
        return {}
    }
    toJson(): Object[] {
        return [
            {
                name: "ADD_CONTACT_TO_GROUP",
                payload: {
                    contact_id: this.contactId,
                    stakeholder_group_id: this.groupId,
                }
            }
        ]
    }
}