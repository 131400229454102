import React from "react";
import { Container, Grid, Text, Title } from "@mantine/core";
import { StepProps } from ".";
import config from "config.json";
import { StepContainer } from "./StepContainer";

export const StepEight: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          8. The legal side
        </Title>
      </Container>
      <Grid>
        <Grid.Col span={12}>
          <Text>
            Here are the{" "}
            <a href="./terms" className="link" target="_blank">
              Terms and Conditions
            </a>{" "}
            for using {config.APP_NAME} and our{" "}
            <a href="./privacy-policy" className="link" target="_blank">
              Privacy Policy
            </a>
            .
          </Text>
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
