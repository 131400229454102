import { useEffect, useRef } from "react";
import {
  useNavigation,
  IResourceComponentsProps,
  useInfiniteList,
} from "@pankod/refine-core";
import {
  List,
  Row,
  Col,
  Spin,
  Space,
  Button,
  Affix,
} from "@pankod/refine-antd";
import React, { useState } from "react";
import { CreateMatrixModal } from "components/createMatrixModal";
import { IBaseMatrix } from "interfaces";
import { CreateMatrixCard, MatrixCard } from "components/matrixCards";
import { useIntersectionObserver } from "hooks/useIntersectionObserver";
import { gaEvents } from "services/googleAnalytics";

export const MatricesList: React.FC<IResourceComponentsProps> = (props) => {
  const { push } = useNavigation();
  const [creteModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const cardRef = useRef<HTMLDivElement | null>(null);

  const { state } = useIntersectionObserver(cardRef, { threshold: 0.5 });
  const isCreateCardVisible = state.entry?.isIntersecting;

  const {
    data,
    isLoading,
    hasNextPage,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage,
    remove,
  } = useInfiniteList<IBaseMatrix>({
    resource: props.name || "matrices",
    config: {
      pagination: {
        pageSize: 11,
      },
    },
  });

  const getMatricesData = (): IBaseMatrix[] => {
    const allMatrices: IBaseMatrix[] = [];

    return allMatrices.concat(...(data?.pages ?? []).map((page) => page.data));
  };

  const colProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
    xl: 6,
  };

  useEffect(() => {
    return () => {
      // remove cache for this query
      remove();
    };
  }, []);

  const getMatrices = (): JSX.Element[] | undefined => {
    const matrices = getMatricesData();
    if (!matrices) return;

    return matrices?.map((matrix) => {
      return (
        <Col key={matrix.id} {...colProps}>
          <MatrixCard
            id={matrix.id}
            name={matrix.name}
            onClick={() => {
              push(`/matrices/edit/${matrix.id}`);
            }}
          />
        </Col>
      );
    });
  };

  return (
    <List
      headerProps={{
        extra: null,
      }}
    >
      <Affix offsetTop={50}>
        <Space style={{ float: "right" }}>
          {!isCreateCardVisible && (
            <Button
              className="slide-in-right"
              type="primary"
              onClick={() => {
                setCreateModalOpen(true);
                gaEvents.createMatrix();
              }}
            >
              Create Matrix
            </Button>
          )}
        </Space>
      </Affix>
      <Spin spinning={isLoading || isFetchingNextPage || isRefetching}>
        {creteModalOpen && (
          <CreateMatrixModal
            isOpen={creteModalOpen}
            handleOk={(matrixId: number) => {
              push(`/matrices/edit/${matrixId}`);
            }}
            handleClose={() => setCreateModalOpen(false)}
          />
        )}
        <Row></Row>
        <Row gutter={[16, 16]}>
          <Col key="matrix-create" {...colProps}>
            <div ref={cardRef} id="create-card">
              <CreateMatrixCard
                onClick={() => {
                  setCreateModalOpen(true)
                  gaEvents.createMatrix();
                }}
              />
            </div>
          </Col>
          {getMatrices()}
        </Row>
        {hasNextPage && (
          <Row style={{ marginTop: "20px" }}>
            <Col span={24}>
              <Space style={{ float: "right" }}>
                <Button
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage}
                  type="primary"
                >
                  {isFetchingNextPage ? "Loading more..." : "Load More"}
                </Button>
              </Space>
            </Col>
          </Row>
        )}
      </Spin>
    </List>
  );
};
