import { Grid } from "@pankod/refine-antd";
import { IHookResponse } from "interfaces";

interface IUseDeviceSizeResponse extends IHookResponse {
    state: {
        isSmall: boolean,
        isMedium: boolean
    }
}

type Props = {}

function useDeviceSize(): IUseDeviceSizeResponse {
    const breakpoint = Grid.useBreakpoint();
    const isMedium = !breakpoint.lg;
    const isSmall = !breakpoint.md;

    return {
        state: {
            isSmall,
            isMedium
        },
        operations: {}
    }
}

export default useDeviceSize