import React, { useMemo, useCallback } from "react";
import { Form, Rate, Typography, Row, Col } from "@pankod/refine-antd";
import { getOptionsLabel } from "utils";
import { impactCategoriesOptions } from "options";
import { ImpactCategoriesColors, ImpactTypes, Impact_Types } from "types";
import useDeviceSize from "hooks/useDeviceSize";

type CategoryFieldProps = {
  id?: string;
  type: ImpactTypes;
  onChange?: (value: Object) => void;
  value?: number;
  showLabel?: boolean;
  disabled?: boolean;
};

const informationShortDescription = "source of info";

const CategoryField: React.FC<CategoryFieldProps> = (props) => {
  const {
    id = "",
    type,
    onChange = () => {},
    value = 0,
    disabled = false,
    showLabel = true,
  } = props;
  const { state: deviceState } = useDeviceSize();

  const color = ImpactCategoriesColors[type];

  const getCategoryName = useCallback((): string => {
    if (deviceState.isSmall && type === Impact_Types.INFORMATION) {
      return informationShortDescription;
    }

    return getOptionsLabel(type, impactCategoriesOptions).toLowerCase();
  }, [type, deviceState.isSmall]);

  const label = useMemo((): string => {
    let union = type === Impact_Types.INFORMATION ? "as a" : "as an"; // refactor

    return `${union} ${getCategoryName()}`;
  }, [type, getCategoryName]);

  return (
    <Form.Item id={id} className="category-form-item">
      <Row gutter={15}>
        <Col>
          <Rate
            value={value || 0}
            character={({ index }: { index: number }) => index + 1}
            onChange={onChange}
            style={{ color: color }}
            disabled={disabled}
          />
        </Col>
        {showLabel && (
          <Col
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography.Text style={{ fontSize: "14px", color: "#626262" }}>
              {label}
            </Typography.Text>
          </Col>
        )}
      </Row>
    </Form.Item>
  );
};
export default CategoryField;
