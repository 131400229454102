import { IHookResponse, IMatrixCommand } from "interfaces";
import {
  ChangeRelationshipCommand,
  IChangeRelationshipCommand,
} from "commands/matrix/ChangeRelationshipCommand";
import {
  ChangeStakeholderGroupAndRelationshipCommand,
  IChangeStakeholderGroupAndRelationshipCommand,
} from "commands/matrix/ChangeStakeholderGroupAndRelationshipCommand";
import {
  ChangeStakeholderGroupCommand,
  IChangeStakeholderGroupCommand,
} from "commands/matrix/ChangeStakeholderGroupCommand";
import {
  AddNewContactCommand,
  IAddNewContactCommand,
} from "commands/matrix/AddNewContactCommand";
import {
  AddNewStakeholderGroupCommand,
  IAddNewStakeholderGroupCommand,
} from "commands/matrix/AddNewStakeholderGroupCommand";
import {
  IRemoveStakeholderGroupCommand,
  RemoveStakeholderGroupCommand,
} from "commands/matrix/RemoveStakeholderGroupCommand";
import {
  IRemoveContactFromStakeholderGroupCommand,
  RemoveContactFromStakeholderGroupCommand,
} from "commands/matrix/RemoveContactFromStakeholderGroupCommand";
import {
  IRenameMatrixCommand,
  RenameMatrixCommand,
} from "commands/matrix/RenameMatrixCommand";
import {
  IRenameStakeholderGroupCommand,
  RenameStakeholderGroupCommand,
} from "commands/matrix/RenameStakeholderGroupCommand";
import {
  AddNewContactsCommand,
  IAddNewContactsCommand,
} from "commands/matrix/AddNewContactsCommand";
import { inNullish } from "utils";

// TODO - change props for movement
// type ChangeRelationshipProps = {
//     contactId: number,
//     relationship: number,
// }

// type ChangeGroupProps = ChangeRelationshipProps & {
//     currentGroup: number,
//     newGroup: number
// }

// type ChangeRelationShipAndGroupProps = ChangeGroupProps;

// enum MoveActions {
//     RELATIONSHIP = "relationship",
//     GROUP = "group",
//     RELATIONSHIP_AND_GROUP = "relationship_and_group",
// }

// export type MoveContactProps2 = {
//     action: MoveActions,
//     payload: ChangeRelationshipProps | ChangeGroupProps | ChangeRelationShipAndGroupProps
// }

export type MoveContactProps = {
  contactId: number;
  relationship: number | undefined;
  currentGroup: number;
  newGroup: number | undefined;
};

type MoveContactCommandProps = MoveContactProps & {
  currentRelationship: number;
  relationship: number;
  newGroup: number;
};

export interface IUseMatrixCommandsProps {
  executeCommand(cmd: IMatrixCommand): void;
  getContactRelationship(contactId: number): number | null;
}

export interface IUseMatrixCommandsOperations {
  moveContact(props: MoveContactProps): void;
  addNewContact(contactId: number, groupId: number): void;
  addNewContacts(contactsIds: number[], groupId: number): void;
  removeContactFromGroup(contactId: number, groupId: number): void;
  addNewStakeholderGroup(name: string): void;
  removeStakeholderGroup(id: number): void;
  renameMatrix(name: string): void;
  renameStakeholderGroup(name: string, groupId: number): void;
}

export interface IUseMatrixCommandsResponse extends IHookResponse {
  operations: IUseMatrixCommandsOperations;
}

function useMatrixCommands({
  executeCommand,
  getContactRelationship,
}: IUseMatrixCommandsProps): IUseMatrixCommandsResponse {
  const changeContactRelationship = (
    id: number,
    currentRelationship: number,
    newRelationship: number
  ) => {
    const cmd: IChangeRelationshipCommand = new ChangeRelationshipCommand(
      id,
      currentRelationship,
      newRelationship
    );
    executeCommand(cmd);
  };

  const changeContactGroup = (id: number, from: number, to: number) => {
    const cmd: IChangeStakeholderGroupCommand =
      new ChangeStakeholderGroupCommand(id, from, to);
    executeCommand(cmd);
  };

  const changeRelationshipAndGroup = ({
    contactId,
    currentGroup,
    newGroup,
    relationship,
    currentRelationship,
  }: MoveContactCommandProps) => {
    if (inNullish(newGroup) || inNullish(relationship)) return;

    const cmd: IChangeStakeholderGroupAndRelationshipCommand =
      new ChangeStakeholderGroupAndRelationshipCommand(
        contactId,
        currentGroup,
        newGroup,
        relationship,
        currentRelationship
      );
    executeCommand(cmd);
  };

  const moveContact = ({
    contactId,
    relationship,
    currentGroup,
    newGroup,
  }: MoveContactProps): void => {
    const currentRelationship: number | null =
      getContactRelationship(contactId);

    if (currentRelationship === null) return;

    if (
      relationship !== undefined &&
      newGroup !== undefined &&
      currentRelationship !== relationship &&
      currentGroup !== newGroup
    ) {
      // REFACTOR
      return changeRelationshipAndGroup({
        contactId,
        relationship,
        currentRelationship,
        currentGroup,
        newGroup,
      });
    }

    if (newGroup !== undefined && currentGroup !== newGroup) {
      return changeContactGroup(contactId, currentGroup, newGroup);
    }

    if (relationship !== undefined && relationship !== currentRelationship) {
      return changeContactRelationship(
        contactId,
        currentRelationship,
        relationship
      );
    }
  };

  const addNewContact = (contactId: number, groupId: number) => {
    const cmd: IAddNewContactCommand = new AddNewContactCommand(
      contactId,
      groupId
    );
    executeCommand(cmd);
  };

  const addNewContacts = (contactsIds: number[], groupId: number) => {
    const cmd: IAddNewContactsCommand = new AddNewContactsCommand(
      contactsIds,
      groupId
    );
    executeCommand(cmd);
  };

  const removeContactFromGroup = (contactId: number, groupId: number) => {
    const cmd: IRemoveContactFromStakeholderGroupCommand =
      new RemoveContactFromStakeholderGroupCommand(contactId, groupId);
    executeCommand(cmd);
  };

  const addNewStakeholderGroup = (name: string) => {
    const cmd: IAddNewStakeholderGroupCommand =
      new AddNewStakeholderGroupCommand(name);
    executeCommand(cmd);
  };

  const removeStakeholderGroup = (id: number) => {
    const cmd: IRemoveStakeholderGroupCommand =
      new RemoveStakeholderGroupCommand(id);
    executeCommand(cmd);
  };

  const renameMatrix = (name: string) => {
    const cmd: IRenameMatrixCommand = new RenameMatrixCommand(name);
    executeCommand(cmd);
  };

  const renameStakeholderGroup = (name: string, groupId: number) => {
    const cmd: IRenameStakeholderGroupCommand =
      new RenameStakeholderGroupCommand(name, groupId);
    executeCommand(cmd);
  };

  return {
    state: {},
    operations: {
      moveContact,
      addNewContact,
      addNewContacts,
      removeContactFromGroup,
      addNewStakeholderGroup,
      removeStakeholderGroup,
      renameMatrix,
      renameStakeholderGroup,
    },
  };
}

export default useMatrixCommands;
