import React from "react";
import { Container, Grid, Image, Text, Title } from "@mantine/core";
import { StepProps } from ".";
import { StepContainer } from "./StepContainer";

export const StepThree: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          3. Changing your password
        </Title>
      </Container>
      <Grid style={{ marginBottom: "40px" }}>
        <Grid.Col span={12}>
          <Text>
            To change your password, from the main menu select{" "}
            <strong>Account</strong>, then tap or click the{" "}
            <strong>Change Password</strong> button.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Image src="images/user-guide/change-password-navigation.jpeg" />
        </Grid.Col>
      </Grid>
      <Grid style={{ marginBottom: "40px" }}>
        <Grid.Col lg={6} md={6} sm={12}>
          <Text>
            A drawer will open where you will be asked to provide your{" "}
            <strong>current password</strong> and then to{" "}
            <strong>type your new password twice</strong>. You should see a
            confirmation of the password change once it has taken effect.
          </Text>
        </Grid.Col>
        <Grid.Col lg={6} md={6} sm={12}>
          <Image src="images/user-guide/change-password-drawer.jpg" />
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
