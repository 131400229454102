import { useEffect, useMemo } from "react";
import { Refine, ResourceProps } from "@pankod/refine-core";
import {
  // notificationProvider,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import routerProvider, { useLocation } from "@pankod/refine-react-router-v6";
import dataProvider from "./providers/dataProvider";
import { LoginPage } from "pages/login";
import { HomePage } from "pages/home";
import { Title, Sider, Footer, Layout, OffLayoutArea } from "components/layout";
import { authProvider } from "./providers/authProvider";
import { axios } from "./services/httpService";
import { notificationProvider } from "./providers/notificationProvider";
import { RegisterPage } from "pages/register";
import "./styles/dashboard.css";
import { getAuthResources } from "routes";
import EmailVerification from "pages/verification";
import { ForgotPassword, ResetPassword } from "pages/reset-password";
import { CookieConsent } from "components/cookieConsent";
import { VideoGuidePage } from "pages/video-guide";
import { TermsOfUsePage } from "pages/terms-of-use";
import PrivacyPolicyPage from "pages/privacy-policy";
import { UserGuidePage } from "pages/user-guide";
import ReactGA from "react-ga4";
import { initializeGA } from "services/googleAnalytics";
import { changePage, initializeHotjar } from "services/hotjar";
import { QuickStartGuidePage } from "pages/quick-start-guide";

// TODO: remove from here
const Analytics = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${pathname}${search}`,
      title: pathname,
    });

    changePage(pathname);
  }, [pathname, search]);

  return null;
};

function App() {
  const resources: ResourceProps[] = useMemo(() => {
    // get resources with additional auth validations
    return getAuthResources();
  }, []);

  return (
    <Refine
      notificationProvider={notificationProvider}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <HomePage />,
            path: "/",
            index: true,
          },
          {
            element: <PrivacyPolicyPage />,
            path: "/privacy-policy",
          },
          {
            element: <TermsOfUsePage />,
            path: "/terms",
          },
          {
            element: <RegisterPage />,
            path: "/register",
          },
          {
            element: <EmailVerification />,
            path: "/verification",
          },
          {
            element: <ForgotPassword />,
            path: "/forgot-password",
          },
          {
            element: <ResetPassword />,
            path: "/reset-password",
          },
          {
            element: <VideoGuidePage />,
            path: "/video-guide",
          },
          {
            element: <UserGuidePage />,
            path: "/user-guide",
          },
          {
            element: <QuickStartGuidePage />,
            path: "/quick-start-guide",
          },
        ],
      }}
      dataProvider={dataProvider(process.env.REACT_APP_SERVER_URL || "", axios)}
      authProvider={authProvider}
      LoginPage={LoginPage}
      resources={resources}
      Title={Title}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      options={{ warnWhenUnsavedChanges: true }}
      i18nProvider={undefined}
    >
      <Analytics />
      <CookieConsent
        onAccept={() => {
          initializeGA();
          initializeHotjar();
        }}
      />
    </Refine>
  );
}

export default App;
