import React from "react";
import { MantineProvider } from "@mantine/core";
import { Footer } from "./footer";
import { Navbar } from "./navbar";
import { homeTheme } from "styles/home";

type Props = {};

export const PublicWrapper: React.FC<Props> = (props) => {
  return (
    <MantineProvider theme={homeTheme}>
      {/* TODO: transfer navbar and footer to this layout folder */}
      <Navbar />
      {props.children}
      <Footer />
    </MantineProvider>
  );
};
