import React from "react";
import { Col, Row, Typography } from "@pankod/refine-antd";
import { getInnerHtml } from "utils";

type Props = {
  steps: string[];
};

const ExportSteps: React.FC<Props> = ({ steps }) => {
  return (
    <Row gutter={[0, 15]} style={{ marginBottom: "10px" }}>
      {steps.map((step, index) => {
        const fullStep = `${index + 1}. ${step}`;
        return (
          <Col span={24}>
            <Typography.Text>
              <span dangerouslySetInnerHTML={getInnerHtml(fullStep)}></span>
            </Typography.Text>
          </Col>
        );
      })}
    </Row>
  );
};

export default ExportSteps;
