import { IHookResponse, IMatrix, IBasicMatrixData, IAction } from "interfaces";
import { useEffect } from "react";
import { useActionPlansStore } from "store/actionPlans";
import { v4 as uuidv4 } from "uuid";
import { useActionCRUD } from "./useActionCRUD";

export interface IUseActionPlansResponse extends IHookResponse {
    state: {
        actions: IAction[];
        isLoading: boolean;
    };
    operations: {
        addNewAction: () => void;
        deleteAction: (actionId: number | undefined, collapseId: string) => void;
        onSaveAction: (actionId: number | undefined, action: IAction) => void;
    };
}

type UseActionProps = {
    matrixId: string | number;
    contactId: string | number;
    onAddAction?: (actionId: string) => void;
    onDeleteAction?: (actionId: string) => void;
};

const createDefaultAction = (
    matrixId: string | number,
    contactId: string | number
): IAction => {
    return {
        matrix_id: Number(matrixId),
        contact_id: Number(contactId),
        name: "",
        metadata: {
            collapseId: uuidv4()
        }
    };
};

export const useActions = ({
    matrixId,
    contactId,
    onAddAction,
    onDeleteAction,
}: UseActionProps): IUseActionPlansResponse => {
    const { getActions, addAction, removeAction, updateStoreAction } =
        useActionPlansStore((state) => ({
            getActions: state.getActionsByMatrixId,
            addAction: state.addAction,
            removeAction: state.deleteAction,
            updateStoreAction: state.updateAction,
        }));
    const { state, operations } = useActionCRUD();

    const actions = getActions(matrixId);

    useEffect(() => {
        if (actions.length > 0) return;

        addNewAction();
    }, []);

    const addNewAction = () => {
        const newAction = createDefaultAction(matrixId, contactId);
        addAction(newAction);
        onAddAction?.(newAction.metadata.collapseId);
    };

    const deleteAction = async (actionId: number | undefined, collapseId: string) => {
        if (typeof actionId === "number") {
            await operations.deleteById(actionId);
        }

        removeAction(collapseId);
        onDeleteAction?.(collapseId);
    };

    const createAction = async (action: IAction) => {
        const newAction = await operations.create(action);
        const collapseKey = newAction?.metadata?.collapseId || "";
        updateStoreAction(collapseKey, newAction);
    };

    const updateAction = async (actionId: number, actionData: IAction) => {
        const action: IAction = await operations.update(actionId, actionData);
        const collapseKey = action?.metadata?.collapseId || "";
        updateStoreAction(collapseKey, action);
    };

    const onSaveAction = async (actionId: number | undefined, action: IAction) => {
        if (!actionId) {
            await createAction(action);
        } else if (typeof actionId === "number") {
            await updateAction(actionId, action);
        }
    };

    return {
        state: {
            actions,
            isLoading: state.isLoading,
        },
        operations: {
            addNewAction,
            deleteAction,
            onSaveAction,
        },
    };
};
