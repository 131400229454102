import React from "react";
import { Center, Container, List, Title } from "@mantine/core";
import { PublicWrapper } from "components/publicLayout";
import {
  StepEight,
  StepFive,
  StepFour,
  StepNine,
  StepOne,
  StepSeven,
  StepSix,
  StepThree,
  StepTwo,
} from "./steps";
// TODO: add env file
import config from "config.json";

type Props = {};

const contentsList = [
  {
    id: "creating-an-account",
    title: "Creating an account",
    subContents: [
      {
        id: "activating-your-account",
        title: "Activating your account",
      },
    ],
  },
  { id: "main-menu", title: "The main menu" },
  { id: "changing-your-password", title: "Changing your password" },
  {
    id: "adding-contacts-to-your-account",
    title: "Adding contacts to your account",
    subContents: [
      {
        id: "adding-individual-contacts",
        title: "Adding individual contacts",
      },
      {
        id: "importing-contacts",
        title: "Importing contacts from a file",
      },
    ],
  },
  { id: "creating-matrix", title: "Creating a matrix" },
  { id: "adding-contacts-matrix", title: "Adding contacts to the matrix" },
  { id: "working-with-contacts", title: "Working with contacts" },
  { id: "legal-side", title: "The legal side" },
  { id: "support", title: "Providing feedback and getting support" },
];

export const UserGuidePage: React.FC<Props> = () => {
  return (
    <PublicWrapper>
      <Container
        id="help-title-container"
        fluid
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Container id="help-title" pt={80} pb={20}>
          <Center>
            <Title
              style={{ color: "var(--secondary-color)", marginBottom: "10px" }}
              align="center"
            >
              {config.APP_NAME}
            </Title>
          </Center>
          <Center>
            <Title order={3} align="center">
              User guide
            </Title>
          </Center>
        </Container>
        <Container id="contents-table-title" pb={40}>
          <Title
            order={4}
            className="secondary"
            style={{ marginBottom: "10px" }}
          >
            Table of Contents:
          </Title>
          <List type="ordered" withPadding>
            {contentsList.map((section, index) => {
              const parentNum = index + 1;
              return (
                <List.Item key={`${section.id}-list-item`}>
                  <a style={{ color: "black" }} href={`#${section.id}`}>
                    {section.title}
                  </a>
                  {Array.isArray(section.subContents) && (
                    <List withPadding type="ordered">
                      {section.subContents.map((subSection, index) => {
                        const childNum = index + 1;
                        return (
                          <List.Item
                            key={`${subSection.id}-sub-list-item`}
                            icon={`${parentNum}.${childNum}.`}
                          >
                            <a
                              style={{ color: "black" }}
                              href={`#${subSection.id}`}
                            >
                              {subSection.title}
                            </a>
                          </List.Item>
                        );
                      })}
                    </List>
                  )}
                </List.Item>
              );
            })}
          </List>
        </Container>
      </Container>
      <StepOne id="creating-an-account" />
      <StepTwo id="main-menu" />
      <StepThree id="changing-your-password" />
      <StepFour id="adding-contacts-to-your-account" />
      <StepFive id="creating-matrix" />
      <StepSix id="adding-contacts-matrix" />
      <StepSeven id="working-with-contacts" />
      <StepEight id="legal-side" />
      <StepNine id="support" />
    </PublicWrapper>
  );
};
