import React from "react";
import CookieConsent from "react-cookie-consent";
import { Typography, Row, Col } from "@pankod/refine-antd";

type Props = {
  onAccept?(): void;
};

export const ACCEPT_COOKIE_NAME = "accepted-cookies-v3";

export const CookieConsentWrapper: React.FC<Props> = ({ onAccept }) => {
  const Link = (props: { name: string; to: string }): JSX.Element => {
    return (
      <a
        style={{ color: "var(--primary-color)", fontWeight: "bold" }}
        href={props.to}
        target="_blank"
        rel="noreferrer"
      >
        {props.name}
      </a>
    );
  };

  return (
    <CookieConsent
      // debug={true}
      location="bottom"
      buttonText="Accept"
      cookieName={ACCEPT_COOKIE_NAME}
      containerClasses="cookie-consent-container"
      buttonClasses="cookie-consent-accept-button"
      expires={150}
      onAccept={() => {
        onAccept?.();
        // window.location.reload();
      }}
    >
      <Row>
        <Typography.Title level={5}>We use cookies</Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Text style={{ color: "black" }}>
            Cookies help us deliver the best experience on our website. By using
            our website, you agree to the use of the cookies
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text style={{ color: "black" }}>
            View our <Link to={"/privacy-policy"} name={"Privacy Policy"} /> and{" "}
            <Link to={"/terms"} name={"Terms and conditions"} /> for more
            information
          </Typography.Text>
        </Col>
      </Row>
    </CookieConsent>
  );
};
