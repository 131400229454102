import React from 'react'
import { IBaseUser, IHookResponse } from 'interfaces';
import {
    useOne,
    useDelete,
    useUpdate
} from "@pankod/refine-core";

type Props = {
    id: string | number
}

// WIP
export interface IUseUserResponse extends IHookResponse {
    state: {
        user: IBaseUser | undefined // TODO,
        isLoading: boolean,
    };
    operations: {
        update(values: IBaseUser, onError?: (errors: any) => void): void;
        delete(onError?: (errors: any) => void): Promise<void>
    };
}

function useUser(props: Props = { id: "me" }): IUseUserResponse {
    const resource = "user";
    const { id } = props;

    const { data: response, isFetching, refetch } = useOne<IBaseUser>({
        resource,
        id,
        metaData: {
            id
            // set data in the metadata object, 
            // because if the same request is triggered from a different component,
            // the useQuery will set all the similar request as loading and that may break logic linked with isRefetching
        },
    });

    const user = response?.data;

    const { mutateAsync: asyncDelete, isLoading: isDeleting } = useDelete<IBaseUser>();

    const deleteUser = async (onError?: (errors: any) => void) => {
        await asyncDelete({
            resource,
            id// "me",
        }, {
            onError: (errors) => {
                onError?.(errors);
            }
        });
    }

    const { mutateAsync: asyncUpdate, isLoading: isUpdating } = useUpdate<IBaseUser>();

    const updateUser = async (values: IBaseUser, onError?: (errors: any) => void) => {
        await asyncUpdate({
            resource,
            id,
            values
        },
            {
                onSuccess: () => {
                    refetch();
                },
                onError: (errors) => {
                    onError?.(errors);
                }
            },
        )
    }

    return {
        state: {
            user,
            isLoading: isFetching || isDeleting || isUpdating,
        },
        operations: {
            update: updateUser,
            delete: deleteUser,
        }
    }
}

export default useUser