import { IMatrix, IMatrixCommand } from "interfaces";

export interface IRemoveContactFromStakeholderGroupCommand extends IMatrixCommand {
    contactId: number,
    groupId: number
}

export interface IRemoveContactFromStakeholderGroupCommandResponse { }

export class RemoveContactFromStakeholderGroupCommand implements IRemoveContactFromStakeholderGroupCommand {
    contactId: number;
    groupId: number;

    constructor(contactId: number, groupId: number) {
        this.contactId = contactId;
        this.groupId = groupId;
    }

    execute(matrix: IMatrix) {
        return matrix;
    }

    undo(matrix: IMatrix) {
        throw new Error("Method not implemented.");
    }

    toJson(): Object[] {
        return [
            {
                name: "REMOVE_CONTACT_FROM_GROUP",
                payload: {
                    contact_id: this.contactId,
                    stakeholder_group_id: this.groupId
                }
            }
        ]
    }

}