import React, { useMemo } from "react";
import { TextField } from "@pankod/refine-antd";
import { relationshipStrengthData } from "utils";
import "./styles.css";

type RelationshipIconStyles = {
  containerClass?: string;
  valueClass?: string;
};

type RelationshipIconProps = {
  value: number | undefined;
  styles?: RelationshipIconStyles;
};

function RelationshipIcon({ value, styles }: RelationshipIconProps) {
  const color: string = useMemo(() => {
    return (
      relationshipStrengthData.find((relationship) => relationship.id === value)
        ?.color || ""
    );
  }, [value]);

  return (
    <div
      className={
        !!styles?.containerClass
          ? styles.containerClass
          : "relationship-container"
      }
      style={{ backgroundColor: color }}
    >
      <TextField
        value={value}
        className={!!styles?.valueClass ? styles.valueClass : "relationship"}
      />
    </div>
  );
}

export default RelationshipIcon;
