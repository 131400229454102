import React, { useState } from "react";
import { Col, Row, Spin, Tooltip, Typography } from "@pankod/refine-antd";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { IAddStakeholderGroupForm, IStakeholderGroup } from "interfaces";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import { IUseMatrixResponse, useMatrix } from "hooks/useMatrix";
import AddStakeholderGroupModal from "./modals/AddStakeholderGroupModal";
import useDeviceSize from "hooks/useDeviceSize";
import ImpactCategoriesRow from "components/impactCategoriesRow";
import "./styles.css";
import classNames from "classnames";
import { IconButton } from "components/buttons";
import { tooltips } from "./utils";

export const MatrixContext = React.createContext<IUseMatrixResponse>({
  state: {
    matrix: null,
    isLoading: false,
  },
  operations: {
    getContactById: (id) => null,
    getContactRelationship: (id) => null,
    getNextStakeholderGroupName: () => "",
    getStakeholderGroups: () => [],
    moveContact: (props) => {},
    addNewContact: (contactId, groupId) => {},
    addNewContacts: (contactsIds, groupId) => {},
    removeContactFromGroup: (contactId, groupId) => {},
    addNewStakeholderGroup: (name) => {},
    removeStakeholderGroup: (id) => {},
    renameMatrix: (name) => {},
    renameStakeholderGroup: (name, groupId) => {},
    reloadMatrix: () => {},
  },
});

type TableProps = {
  id: number | undefined;
};

const Table = (props: TableProps) => {
  const { state, operations } = useMatrix({ id: props.id });
  const { matrix } = state;

  const { state: deviceState } = useDeviceSize();
  const { isMedium } = deviceState;

  const [isAddGroupModalOpen, setAddGroupModalOpen] = useState(false);

  const handleAddNewGroup = ({ name }: IAddStakeholderGroupForm) => {
    operations.addNewStakeholderGroup(name);
  };

  const getStakeholderRows = (): JSX.Element[] => {
    if (!matrix) return [];

    if (!matrix?.stakeholder_groups) return [];

    const groups: [number, IStakeholderGroup][] = Array.from(
      matrix?.stakeholder_groups
    );

    return groups.map((test) => {
      const [key, stakeholder] = test;
      return (
        <TableRow
          key={key}
          {...stakeholder}
          addNewContacts={operations.addNewContacts}
          deleteRow={operations.removeStakeholderGroup}
          onEditGroupName={operations.renameStakeholderGroup}
        />
      );
    });
  };

  return (
    <MatrixContext.Provider value={{ state, operations }}>
      <Spin spinning={state.isLoading}>
        <Row
          key={`matrix-name`}
          style={{ alignItems: "center", marginBottom: "10px" }}
        >
          <Col flex={1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={tooltips.title}>
                <Typography.Title
                  className={classNames(
                    "editable-title",
                    "typography-edit-icon"
                  )}
                  editable={{
                    onChange: (newName) => {
                      if (!newName || newName === matrix?.name) return;

                      operations.renameMatrix(newName);
                    },
                  }}
                  style={{ color: "var(--secondary-color)" }}
                  level={2}
                >
                  {matrix?.name}
                </Typography.Title>
              </Tooltip>
            </div>
          </Col>
          <Col>
            <IconButton
              style={{ margin: "0 10px" }}
              icon={
                <UsergroupAddOutlined
                  style={{ fontSize: "25px", color: "gray" }}
                />
              }
              tooltipTitle="Add group"
              onClick={() => setAddGroupModalOpen(true)}
            />
          </Col>
        </Row>
        {!isMedium ? <TableHead /> : null}
        {getStakeholderRows()}
        <div style={{ margin: "10px 0" }}>
          <IconButton
            style={{ margin: "0 10px" }}
            tooltipTitle="Add group"
            icon={
              <UsergroupAddOutlined
                style={{ fontSize: "25px", color: "gray" }}
              />
            }
            onClick={() => setAddGroupModalOpen(true)}
          />
        </div>
        <ImpactCategoriesRow label="Four Is key" />
      </Spin>
      {isAddGroupModalOpen && (
        <AddStakeholderGroupModal
          isOpen={isAddGroupModalOpen}
          handleClose={() => setAddGroupModalOpen(false)}
          handleOk={handleAddNewGroup}
          getDefaultName={operations.getNextStakeholderGroupName}
        />
      )}
    </MatrixContext.Provider>
  );
};

export default Table;
