import { IMatrix, IMatrixCommand } from "interfaces";

export interface IRenameMatrixCommand extends IMatrixCommand {
    name: string
}

export interface IRenameMatrixCommandResponse { }

export class RenameMatrixCommand implements IRenameMatrixCommand {
    name: string;

    constructor(name: string) {
        this.name = name
    }


    execute(matrix: IMatrix): IRenameMatrixCommandResponse {
        return {}
    }
    undo(matrix: IMatrix): IRenameMatrixCommandResponse {
        return {}
    }
    toJson(): Object[] {
        return [
            {
                name: "RENAME_MATRIX",
                payload: {
                    name: this.name
                }
            }
        ]
    }
}