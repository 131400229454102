import React, { useState } from "react";
import { Typography, Row, Col } from "@pankod/refine-antd";
import { ImportFromTypes } from "types";
import { ExportStepsDrawer } from "./exportStepsDrawer";
import { Link } from "@pankod/refine-react-router-v6";

type Props = {
  title?: string;
};

const ExportInstructions: React.FC<Props> = ({ title }) => {
  const [exportFrom, setExportFrom] = useState<ImportFromTypes | null>(null);
  return (
    <>
      <Row gutter={[0, 8]} style={{ marginBottom: "24px" }}>
        <Col span={24}>
          <Typography.Text strong style={{ color: "#626262" }}>
            {title ? title : "Export your contacts to an XLS or a CSV file"}
          </Typography.Text>
        </Col>
        <Row gutter={[0, 5]}>
          <Col span={24}>
            <Typography.Text style={{ color: "#626262" }}>
              See how to export contacts from{" "}
              <Link
                to=""
                onClick={() => {
                  setExportFrom(ImportFromTypes.SALESFORCE);
                }}
              >
                Salesforce
              </Link>
              .
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ color: "#626262" }}>
              See how to export contacts from{" "}
              <Link
                to=""
                onClick={() => {
                  setExportFrom(ImportFromTypes.LINKEDIN);
                }}
              >
                LinkedIn
              </Link>
              .
            </Typography.Text>
          </Col>
        </Row>
      </Row>
      {!!exportFrom && (
        <ExportStepsDrawer
          visible={!!exportFrom}
          onClose={() => setExportFrom(null)}
          exportFrom={exportFrom}
        />
      )}
    </>
  );
};

export default ExportInstructions;
