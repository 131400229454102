import { IBaseMatrix, IHookResponse, IMatrix } from 'interfaces';
import { useApiUrl, useCustomMutation } from "@pankod/refine-core";
import { transformToMatrixData } from 'utils';

export type UseUpdateMatrixProps = {
    id: number | string,
    onErrorCallBack?: () => void,
    onSuccessCallBack?: (response: IMatrix) => void
}

export interface IUseUpdateMatrixResponse extends IHookResponse {
    state: {
        isLoading: boolean
    },
    operations: {
        update(commands: Object[]): Promise<void>
    }
}

export const useUpdateMatrix = (props: UseUpdateMatrixProps): IUseUpdateMatrixResponse => {
    const API_URL = useApiUrl()
    const { mutateAsync, isLoading } = useCustomMutation<any>();

    const update = async (commands: Object[]) => {
        await mutateAsync({
            url: `${API_URL}/matrix/${props.id}/commands`,
            method: "post",
            values: {
                commands
            },
        }, {
            onSuccess: ({ data }) => {
                const matrix: IBaseMatrix = data?.matrix;
                const newMatrix = transformToMatrixData(matrix)
                props.onSuccessCallBack?.(newMatrix);
            }
        });
    }

    return {
        state: {
            isLoading
        },
        operations: {
            update
        }
    }
}
