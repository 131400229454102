import React from "react";

import { LayoutProps } from "@pankod/refine-core";
import { useLocation } from "react-router-dom";
import { AntdLayout, Grid } from "@pankod/refine-antd";

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea,
}) => {
  const breakpoint = Grid.useBreakpoint();

  const { pathname } = useLocation();

  const getPadding = () => {
    if (pathname.startsWith('/matrices/edit')) return 0; // TODO: refactor to not be hardcoded 

    return breakpoint.sm ? 24 : 12;
  }

  return (
    <AntdLayout style={{ minHeight: "100vh", flexDirection: "row" }}>
      {Sider && <Sider />}
      <AntdLayout>
        {Header && <Header />}
        <AntdLayout.Content>
          <div
            style={{
              padding: getPadding(),
              minHeight: 360,
            }}
          >
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
    </AntdLayout>
  );
};
