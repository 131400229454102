import { IHookResponse, IMatrix, IMatrixCommand } from "interfaces";
import { useState, useCallback } from "react"
import { useMatrixStore } from "store";
import { useUpdateMatrix } from "./useUpdateMatrix";

export type UseMatrixHistoryProps = {
    id: number | string
}

export interface IUseMatrixHistoryResponse extends IHookResponse {
    state: {
        isLoading: boolean
    },
    operations: {
        updateMatrix(matrix: IMatrix | null): void,
        addCommand(cmd: IMatrixCommand): void,
        undoLastCommand(): void,
    }
}

export const useMatrixHistory = ({ id = "" }: UseMatrixHistoryProps): IUseMatrixHistoryResponse => {
    const { updateMatrix, getMatrix } = useMatrixStore((state) => ({ updateMatrix: state.setMatrix, getMatrix: state.getMatrix }));
    const [undos, setUndos] = useState<IMatrixCommand[]>([]); // TODO add store for undos

    const { state, operations } = useUpdateMatrix({ id, onSuccessCallBack: updateMatrix });

    const execute = (cmd: IMatrixCommand): void => {
        const matrix = getMatrix();

        if (matrix === null) return;

        // cmd.execute(matrix);
        const commandPayload: Object[] = cmd.toJson();
        operations.update(commandPayload);
    }

    const undo = (cmd: IMatrixCommand): void => {
    }

    const addCommand = (cmd: IMatrixCommand): void => {
        setUndos((curretUndos) => {
            return [...curretUndos, cmd]
        });

        execute(cmd);
    }

    const undoLastCommand = (): void => {
        const commands: IMatrixCommand[] = [...undos];
        const lastCmd: IMatrixCommand | undefined = commands.pop();

        if (!lastCmd) return;

        undo(lastCmd);
        setUndos(commands);
    };

    return {
        state: {
            isLoading: state.isLoading
        },
        operations: {
            updateMatrix,
            addCommand,
            undoLastCommand,
        }
    }
}