import React from "react";
import { DrawerProps } from "@pankod/refine-antd";
import { useActionPlans } from "hooks/useActionPlans";
import ActionPlan from "components/actionPlan";
import { Drawer } from "./Drawer";

type ActionPlansDrawerProps = {
  drawerProps: DrawerProps;
  contactId: number;
  matrixId?: number;
};

export const ActionPlansDrawer: React.FC<ActionPlansDrawerProps> = ({
  contactId,
  matrixId,
  drawerProps,
}) => {
  const { state } = useActionPlans({ contactId, matrixId });
  const { matrices, isLoading, contactData } = state;

  const getContactName = (): string => {
    if (!contactData) return "Contact";

    const { first_name = "", last_name = "" } = contactData;

    if (!first_name && !last_name) {
      return "Contact";
    }

    return `${first_name} ${last_name}`.trim();
  };

  const getActionPlans = (): JSX.Element[] | null => {
    if (!contactId) return null;

    return matrices.map((matrix) => {
      return (
        <div style={{ marginBottom: "30px" }} key={matrix.id}>
          <ActionPlan
            contactId={contactId}
            matrixId={matrix.id}
            matrixName={matrix.name}
          />
        </div>
      );
    });
  };

  return (
    <Drawer {...drawerProps} title={getContactName()} isLoading={isLoading}>
      {getActionPlans()}
    </Drawer>
  );
};
