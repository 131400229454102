import React from "react";
import { Link } from "react-router-dom";

type Props = {
  containerStyle?: string;
  imageStyle?: string;
};

export const Logo: React.FC<Props> = (props) => {
  return (
    <Link to="/" className={props.containerStyle}>
      <img
        src="/images/relationship-matrix-logo-red.svg"
        alt="Logo"
        className={props.imageStyle}
        style={{ width: "200px", height: "100px" }}
      />
    </Link>
  );
};
