import React from "react";
import {
  Button,
  DrawerProps,
  Form,
  FormItemProps,
  FormProps,
  Input,
  Select,
  Slider,
  Space,
} from "@pankod/refine-antd";
import { Drawer } from "components/drawers";
import { contactTypesOptions } from "options";
import useDeviceSize from "hooks/useDeviceSize";
import RelationshipSlider from "components/relationshipSlider";

type Props = {
  drawerProps: DrawerProps;
  formProps: FormProps<any>;
  clearFilters(): void;
  onClose(): void;
};

export const FilterContactsDrawer: React.FC<Props> = ({
  drawerProps,
  formProps,
  clearFilters,
  onClose,
}) => {
  const impactDefaultConfig: FormItemProps = {
    style: { width: "72%" },
  };

  const { state } = useDeviceSize();

  const afterClick = () => {
    if (state.isSmall) {
      onClose();
    }
  };

  return (
    <Drawer title="Filter contacts" {...drawerProps} isLoading={false}>
      <Form {...formProps} layout="vertical" name="contacts-filters">
        <Form.Item name="q" label="Name/Organization">
          <Input />
        </Form.Item>
        <Form.Item label="Strength of relationship" name="relationship">
          {/* <Slider range defaultValue={[0, 7]} min={0} max={7} dots /> */}
          <RelationshipSlider
            rangeProps={{ range: true, defaultValue: [0, 7] }}
          />
        </Form.Item>
        {/* TODO; remove filters from useContactsFilters */}
        {/* <Form.Item
          label="Influencer"
          name="influencer"
          {...impactDefaultConfig}
        >
          <Slider range defaultValue={[0, 5]} min={0} max={5} dots />
        </Form.Item>
        <Form.Item
          label="Implementer"
          name="implementer"
          {...impactDefaultConfig}
        >
          <Slider range defaultValue={[0, 5]} min={0} max={5} dots />
        </Form.Item>
        <Form.Item
          label="Introducer"
          name="introducer"
          {...impactDefaultConfig}
        >
          <Slider range defaultValue={[0, 5]} min={0} max={5} dots />
        </Form.Item>
        <Form.Item
          label="Source of info, insight, ideas"
          name="information"
          {...impactDefaultConfig}
        >
          <Slider range defaultValue={[0, 5]} min={0} max={5} dots />
        </Form.Item> */}
        <Form.Item label="Types of support" name="types">
          <Select allowClear mode="multiple" options={contactTypesOptions} />
        </Form.Item>
        <Space style={{ float: "right" }}>
          <Form.Item>
            <Button
              onClick={() => {
                clearFilters();
                afterClick();
              }}
            >
              Clear Filters
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => afterClick()}
            >
              Filter
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Drawer>
  );
};
