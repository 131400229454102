import { useEffect } from "react";
import { Form, FormProps, Input } from "@pankod/refine-antd";
import { getFormFiledErrors } from "utils";
import { FieldErrors, ServerErrors } from "types";
import RelationshipSlider from "components/relationshipSlider";
import { IBaseContact } from "interfaces";

type ContactFormProps = {
  formProps: FormProps;
  formErrors?: ServerErrors;
};

export const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { formProps, formErrors = {} } = props;
  const { form } = formProps;

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) return;

    const filedErrors: FieldErrors[] = getFormFiledErrors(formErrors);

    if (filedErrors.length < 1) return;

    form?.setFields(filedErrors);
  }, [formErrors, form]);

  return (
    <Form<IBaseContact>
      {...formProps}
      form={form}
      layout="vertical"
      name="contact-form"
    >
      <Form.Item name="id" hidden />
      <Form.Item label="First name" name="first_name">
        <Input />
      </Form.Item>
      <Form.Item
        label="Last name"
        name="last_name"
        rules={[
          {
            required: true,
            type: "string",
            message: "Field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="How strong is your relationship?"
        name="relationship_strength"
      >
        <RelationshipSlider />
      </Form.Item>
      <Form.Item label="Job Title" name="job_title">
        <Input />
      </Form.Item>
      <Form.Item label="Organisation" name="organization">
        <Input />
      </Form.Item>
      <Form.Item label="Notes" name="notes">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};
