import React, { memo } from "react";
import { Col, ColProps, Row, Tooltip, Typography } from "@pankod/refine-antd";
import { impactCategoriesOptions } from "options";
import { ImpactCategoriesColors, ImpactTypes } from "types";
import { tooltips } from "components/matrix/utils";
import "./styles.css";

type Props = {
  rowColProps?: ColProps;
  rowStyles?: React.CSSProperties;
  itemColProps?: ColProps;
  label?: string;
};

// TODO: refactor
function ImpactCategoriesRow({
  itemColProps = {},
  rowColProps = {},
  rowStyles = {},
  label,
}: Props) {
  return (
    <Row key="impact-categories-container">
      <Col md={18} xs={24} {...rowColProps}>
        <Row
          key="impact-categories-row"
          gutter={[0, 16]}
          className="impact-row"
          style={rowStyles}
        >
          {label && (
            <Col span={24}>
              <Typography.Title level={5} style={{ margin: "0 5px" }}>
                {label}
              </Typography.Title>
            </Col>
          )}
          {impactCategoriesOptions.map((impactCategory) => {
            const colorString = impactCategory.key.toUpperCase();

            const color = Object.keys(ImpactCategoriesColors).includes(
              colorString
            )
              ? ImpactCategoriesColors[
                  colorString as keyof typeof ImpactCategoriesColors
                ]
              : "white";

            return (
              <Col md={6} xs={24} key={color} {...itemColProps}>
                <div
                  className="impact-container"
                  style={{
                    backgroundColor: color,
                  }}
                >
                  <Tooltip
                    title={
                      tooltips.impactCategories[colorString as ImpactTypes]
                    }
                  >
                    <Typography.Text className="impact-name">
                      {impactCategory.label}
                    </Typography.Text>
                  </Tooltip>
                </div>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}

export default memo(ImpactCategoriesRow);
