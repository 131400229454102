import React from "react";
import { Modal } from "@pankod/refine-antd";
import { TermsOfUseContent } from "pages/terms-of-use";

export type AgreementModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleOk: () => void;
  title?: string;
  okText?: string;
};

export const AgreementModal: React.FC<AgreementModalProps> = (props) => {
  const {
    isOpen,
    handleClose,
    handleOk,
    title = "Terms and Conditions",
    okText = "Accept",
  } = props;

  return (
    <Modal
      title={title}
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleClose}
      okText={okText}
    >
      <div style={{ maxHeight: "250px", overflowY: "auto" }}>
        <TermsOfUseContent
          titleOrder={3}
          titlePaddingBottom={5}
          titlePaddingTop={5}
        />
      </div>
    </Modal>
  );
};
