import React from "react";
import {
  ResourceProps,
  IResourceComponents,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import { UserOutlined, TeamOutlined, TableOutlined } from "@ant-design/icons";
import { Account, AccountEdit } from "pages/account";
import { ContactsList } from "pages/contacts";
import { MatricesList, MatrixEdit } from "pages/matrices";
import { CanAccess } from "components/authenticated";

export const resources: ResourceProps[] = [
  {
    icon: <TableOutlined />,
    name: "matrices",
    list: MatricesList,
    edit: MatrixEdit,
  },
  {
    icon: <TeamOutlined />,
    name: "contacts",
    list: ContactsList,
  },
  {
    icon: <UserOutlined />,
    name: "user",
    list: Account,
    edit: AccountEdit,
    options: {
      label: "Account",
      route: "account",
    },
  },
];

const getResource = (
  resource: ResourceProps,
  componentType: keyof IResourceComponents
) => {
  return (props: IResourceComponentsProps) => {
    const Component = resource[componentType];

    return <CanAccess>{Component && <Component {...props} />}</CanAccess>;
  };
};

export const getAuthResources = (): ResourceProps[] => {
  // get resources with wrapper component to check if user is verified
  // the reason we do this is so that we don't have to wrap every component separately
  // and this way we can add new resources without specificity adding check for user verification
  return resources.map((resource) => {
    const authResource = { ...resource };

    if (resource?.list !== undefined) {
      authResource.list = getResource(resource, "list");
    }

    if (resource?.edit !== undefined) {
      authResource.edit = getResource(resource, "edit");
    }

    if (resource?.create !== undefined) {
      authResource.create = getResource(resource, "create");
    }

    if (resource?.show !== undefined) {
      authResource.show = getResource(resource, "show");
    }

    return authResource;
  });
};
