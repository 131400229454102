import React from "react";
import { Container, List, Table, Text, Title } from "@mantine/core";
import { PublicWrapper } from "components/publicLayout";
import config from "config.json";
import { aggregatedData } from "./common";

type Props = {};

const PrivacyPolicyPage = (props: Props) => {
  return (
    <PublicWrapper>
      <Container
        id="policy-container"
        pt={80}
        pb={20}
        style={{ overflow: "hidden" }}
      >
        <Title
          id="policy-title"
          style={{ color: "var(--secondary-color)", marginBottom: "10px" }}
        >
          Data Privacy Statement
        </Title>
        <Text style={{ color: "black", marginBottom: "10px" }}>
          Updated 14 June 2023
        </Text>
      </Container>
      <Container id="summary">
        <Text>
          We are H & A Lopata Limited with registered number 06120639 and
          address 1st & 2nd Floor, 2 West Street, Ware, Hertfordshire, United
          Kingdom, SG12 9EE (<b>‘We’</b>, <b>‘our’</b>). Our Data Protection
          Lead can be contacted at 21 Vancouver Road, Broxbourne, Hertfordshire,
          United Kingdom EN10 6FB. We have produced this privacy notice in order
          to keep our website users and visitors (<b>‘you’</b>, <b>‘your’</b>)
          informed of how we and our affiliates handle your personal data when
          you visit our website or access our Relationship Matrix. All handling
          of your personal data is done in compliance with the Data Protection
          Act 2018, the General Data Protection Regulation (EU) 2016/679 and UK
          GDPR (<b>‘Data Protection Legislation’</b>). The terms{" "}
          <b>‘Personal Data’</b>,<b>‘Special Categories of Personal Data’</b>,{" "}
          <b>‘Personal Data Breach’</b>, <b>‘Data Protection Officer’</b>,{" "}
          <b>‘Data Controller’</b>, <b>‘Data Processor’</b>,{" "}
          <b>‘Data Subject’</b> and
          <b>‘process’</b> (in the context of usage of Personal Data) shall have
          the meanings given to them in the Data Protection Legislation.
        </Text>
      </Container>
      <Container id="questions" py={30}>
        <Container px={0}>
          <Title order={4}>What are your rights?</Title>
          <Text pt={10}>
            When reading this notice, it might be helpful to understand that
            your rights arising under Data Protection Legislation include:
          </Text>
          <List withPadding>
            <List.Item key={"rights-1"}>
              The right to be informed of how your Personal Data is used
              (through this notice);
            </List.Item>
            <List.Item key={"rights-2"}>
              The right to access any personal data held about you;
            </List.Item>
            <List.Item key={"rights-3"}>
              The right to withdraw consent at any time, by opting-out using the
              options present in communications;
            </List.Item>
            <List.Item key={"rights-4"}>
              The right to rectify any inaccurate or incomplete personal data
              held about you;
            </List.Item>
            <List.Item key={"rights-5"}>
              The right to erasure where it cannot be justified that the
              information held satisfies any of the criteria outlined in this
              policy;
            </List.Item>
            <List.Item key={"rights-6"}>
              The right to prevent processing for direct marketing purposes,
              scientific/historical research or in any such way that is likely
              to cause substantial damage to you or another, including through
              profile building; and
            </List.Item>
            <List.Item key={"rights-7"}>
              The right to object to processing that results in decisions being
              made about you by automated processes and prevent those decisions
              being enacted.
            </List.Item>
          </List>
          <Text pt={5}>
            If you have created an account with us, you can access key personal
            data held about you by logging in to your account on the Website.
            You are responsible to update your personal details on the account
            if they change.
          </Text>
          <Text pt={5}>
            You can also gain access to your personal data by emailing
            accounts@relationship-matrix.com with the subject line: “Subject
            Access Request”. When you submit a ‘subject access request’, you
            will need to provide confirmation of your identity by contacting us
            using the email address that you have provided us with initially.
            This is provided free of charge and our response will be made within
            thirty (30) days unless our Data Protection Lead deems your request
            as being excessive or unfounded. If this is the case, we will inform
            you of our reasonable administration costs in advance and/or any
            associated delays, giving you the opportunity to choose whether you
            would like to pursue your request. If you believe we have made a
            mistake in evaluating your request, please see the section ‘Who can
            you complain to?’.
          </Text>
          <Text pt={5}>
            If you have questions about any of the rights mentioned in this
            section, please contact our Data Protection Lead.
          </Text>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>Who is the Data Controller?</Title>
          <Text pt={10}>
            You may collect and upload other Personal Data when using{" "}
            {config.APP_NAME} and will be a Data Controller of that information.
            You, as a Data Controller, are responsible for complying with any
            regulations or laws that require providing notice, disclosure,
            and/or obtaining consent prior to collecting and uploading your
            contacts’ Personal Data using {config.APP_NAME}. In using{" "}
            {config.APP_NAME} you agree that you have the right to transfer, or
            provide access to the Personal Data that you upload to{" "}
            {config.APP_NAME}. Our terms of service, which can be found at{" "}
            <a
              style={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              href="/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            , together with this Data Privacy Statement contain and constitute
            your complete instructions to us in relation to the processing of
            the content including any personal data that you upload.
          </Text>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>
            What are the lawful bases for processing personal data?
          </Title>
          <Text pt={10}>
            Under Data Protection Legislation, there must be a ‘lawful basis’
            for the use of personal data. The lawful bases are:
          </Text>
          <Container>
            <Text pt={5}>{"a)"} your consent’;</Text>
            <Text pt={5}>{"b)"} 'performance of a contract';</Text>
            <Text pt={5}>{"c)"} 'compliance with a legal obligation';</Text>
            <Text pt={5}>
              {"d)"} 'protection of your, or another’s vital interests';
            </Text>
            <Text pt={5}>{"e)"} ‘public interest/official authority’; and</Text>
            <Text pt={5}>{"f)"} 'our legitimate interests'.</Text>
          </Container>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>What are our ‘legitimate interests’?</Title>
          <Text pt={10}>
            Legitimate interests are a flexible basis upon which the law permits
            the processing of an individual’s personal data. To determine
            whether we have a legitimate interest in processing your data, we
            balance the needs and benefits to us against the risks and benefits
            for you of us processing your data. This balancing is performed as
            objectively as possible by our Data Protection Lead. You are able to
            object to our processing and we shall consider the extent to which
            this affects whether we have a legitimate interest. If you would
            like to find out more about our legitimate interests, please contact
            [accounts@relationship-matrix.com].
          </Text>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>About our processing of your data</Title>
          <Text pt={10}>
            We might collect, use, store and transfer different kinds of
            Personal Data about you which we have grouped together as follows:
          </Text>
          <Text pt={5}>
            <b>Identity Data</b> such as names, usernames or similar; company
            name and job title (for you and those you choose to add as contacts
            to {config.APP_NAME}).
          </Text>
          <Text pt={5}>
            <b>Contact Data</b> such as addresses; email addresses and telephone
            numbers (for you and those you choose to add as contacts to{" "}
            {config.APP_NAME}).
          </Text>
          <Text pt={5}>
            <b>Financial Data</b> such as bank account and payment card
            information.
          </Text>
          <Text pt={5}>
            <b>Transaction Data</b> such as information about payments and
            details of purchases you have made.
          </Text>
          <Text pt={5}>
            <b>Technical Data</b> such as IP addresses; login data; browser
            info; time zone; location; browser plug-ins; operating systems;
            platforms and other technology on the device used to access this
            website.
          </Text>
          <Text pt={5}>
            <b>Profile Data</b> such as usernames; passwords; security answers;
            purchases/orders; interests; preferences; feedback and responses to
            surveys, blogs and messages.
          </Text>
          <Text pt={5}>
            <b>Usage Data</b> such as analytics relating to how you use the
            website.
          </Text>
          <Text pt={5}>
            <b>Marketing and Communications Data</b> such as your preferences
            about receiving communications from us or third parties.
          </Text>
          <Text pt={5}>
            We do not collect any Special Categories of Personal Data about you
            or any information about criminal convictions/offences.
          </Text>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>Children</Title>
          <Text pt={10}>
            This Website is not directed toward children (as defined by local
            law), nor do we knowingly collect information from children without
            parental consent except where in compliance with applicable law.
          </Text>
          <Text pt={5} pb={30}>
            We may also collect, use and share <b>Aggregated Data</b> such as
            statistical or demographic data. Aggregated Data can be derived from
            your Personal Data but is not itself Personal Data as it cannot be
            used to reveal your identity. If Aggregated Data is ever used in
            combination with your Personal Data and becomes identifiable, it
            will be treated in accordance with this notice.
          </Text>
          <Container style={{ overflowX: "auto" }} px={0}>
            <Table verticalSpacing="xs" striped fontSize="sm">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>
                    What categories of information about you do we process?
                  </th>
                  <th>Why are we processing your data?</th>
                  <th>Where did we get your personal data from?</th>
                </tr>
              </thead>
              <tbody className="agreements-table-body">
                {aggregatedData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Text>{data.reference}</Text>
                      </td>
                      <td>
                        <List withPadding size="sm">
                          {data.categoriesInformation.map((category, index) => (
                            <List.Item key={index}>{category}</List.Item>
                          ))}
                        </List>
                      </td>
                      <td>
                        <Text>{data.reason}</Text>
                      </td>
                      <td>
                        <Text>{data.personalDataFrom}</Text>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Container>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>
            What happens if I refuse to give you my personal data?
          </Title>
          <Text pt={10}>
            The information about you that we have collected for the performance
            of our contracts is required in order for us to successfully fulfil
            our obligations to you. If you choose not to provide the personal
            data requested, we will not be able to enter into a contract with
            you to provide the services we offer. If we are already processing
            your personal information under a contract, you must end our
            contractual relationship (as/where permitted) in order to exercise
            some of your rights. If we process some personal information as part
            of a contractual relationship with a Data Controller, then any
            requests to restrict this type of processing should be forwarded to
            the Data Controller; they will be responsible for discussing your
            concerns and making any decisions.
          </Text>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>
            What profiling or automated decision-making do we perform?
          </Title>
          <Text pt={10}>
            We do not perform any profiling or automated decision-making based
            on your personal data.
          </Text>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>How long will your personal data be kept?</Title>
          <Text pt={10}>
            We hold different categories of personal data for different periods
            of time. Wherever possible, we will endeavour to minimise the amount
            of personal data that we hold and the length of time for which it is
            held.
          </Text>
          <List withPadding>
            <List.Item>
              If ‘consent’ is the basis for our lawful processing of your data,
              we will retain your data so long as both the purpose for which it
              was collected, and your consent, are still valid. Occasionally, we
              might identify a legitimate interest in retaining some of your
              personal data that has been obtained by consent. If we do, we will
              inform you that we intend to retain it under these conditions and
              identify the interest specifically.
            </List.Item>
            <List.Item>
              If we process your data on the basis of ‘legitimate interests’, we
              will retain your data for as long as the purpose for which it is
              processed remains active.
            </List.Item>
            <List.Item>
              All categories of personal data that are held by us because they
              are essential for the performance of a contract, will be held for
              as long as is required for that purpose in accordance with our
              terms and conditions, or as otherwise required under applicable
              law. Personal data related to our commercial relationship with you
              may be held for a period of up to 6 years, for the purposes of
              exercising or defending legal claims.
            </List.Item>
          </List>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>Who else will receive your personal data?</Title>
          <Text pt={10}>
            We may pass your data to the third parties listed in the section
            ‘Third Party Interests’ below.
          </Text>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>Does your data leave the UK?</Title>
          <Text pt={10}>
            Yes. Details are included in the section ‘Third Party Interests’
            below.
          </Text>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>Third Party Interests</Title>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>Data Controllers</Title>
          <Container style={{ overflowX: "auto" }} px={0}>
            <Table verticalSpacing="xs" striped fontSize="sm">
              <thead>
                <tr>
                  <th>Name or Category of Third Party Controller</th>
                  <th>What processing is being performed?</th>
                  <th>
                    If applicable - who is their representative within the EU?
                  </th>
                </tr>
              </thead>
              <tbody className="agreements-table-body">
                <tr key="row-1">
                  <td>HMRC, regulatory authorities or other authorities</td>
                  <td>
                    We are joint Controller with these authorities who may
                    require reporting of processing in some situations.
                  </td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>Our Data Processors</Title>
          <Container style={{ overflowX: "auto" }} px={0}>
            <Table verticalSpacing="xs" striped fontSize="sm">
              <thead>
                <tr>
                  <th>Name or Category of Third Party Processor</th>
                  <th>Purposes for carrying out processing</th>
                  <th>
                    If applicable – where does data leaving the EEA go and what
                    safeguards are in place?
                  </th>
                </tr>
              </thead>
              <tbody className="agreements-table-body">
                <tr key="row-processors-1">
                  <td>Web hosting providers</td>
                  <td>
                    Hosting of our Website and Product Hub, including the
                    storage of data forming the Website and Product Hub content
                    and processing your Technical Data (and Profile Data, where
                    applicable) in order to provide you with access to our
                    Website and Product Hub.
                  </td>
                  <td>
                    In the interests of providing a quality service, we may use
                    providers located in [the EEA].
                  </td>
                </tr>
                <tr key="row-processors-2">
                  <td>Internal technology providers</td>
                  <td>
                    <List withPadding size="sm">
                      <List.Item>
                        CRM software providers, whose services we use in order
                        to manage our business with you.
                      </List.Item>
                      <List.Item>Telephony providers.</List.Item>
                      <List.Item>
                        Office software providers, such as email clients.
                      </List.Item>
                      <List.Item>
                        IT Support services, who might require access to our
                        systems (with our strict supervision) in order to remedy
                        faults with our technology.
                      </List.Item>
                    </List>
                  </td>
                  <td>
                    In the interests of providing a quality service, we may use
                    providers located in the United States. These providers are
                    bound by the contractual provisions of the EU Commissions
                    model clauses.
                  </td>
                </tr>
                <tr key="row-processors-3">
                  <td>Marketing technology providers</td>
                  <td>
                    Providers who enable us to send you our marketing emails.
                  </td>
                  <td>
                    In the interests of providing a quality service, we may use
                    providers located in the United States. These providers are
                    bound by the contractual provisions of the EU Commissions
                    model clauses.
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </Container>
        <Container px={0} pt={30}>
          <Title order={4}>Who can you complain to?</Title>
          <Text pt={10}>
            In addition to sending us your complaints directly to{" "}
            <a
              style={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              href="mailto:accounts@relationship-matrix.com"
              target="_blank"
              rel="noreferrer"
            >
              accounts@relationship-matrix.com
            </a>{" "}
            accounts@relationship-matrix.com, you can send complaints to our
            supervisory authority. As we predominantly handle the personal data
            of UK nationals, our supervisory authority is the Information
            Commissioner’s Office. If you believe that we have failed in our
            compliance with data protection legislation, complaints to this
            authority can be made by visiting{" "}
            <a
              style={{
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              href="https://ico.org.uk/concerns/"
              target="_blank"
              rel="noreferrer"
            >
              https://ico.org.uk/concerns/
            </a>
            .
          </Text>
        </Container>
      </Container>
    </PublicWrapper>
  );
};

export default PrivacyPolicyPage;
