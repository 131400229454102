import React, { useState } from "react";
import { Form, Input, Modal, Typography, Spin } from "@pankod/refine-antd";
import { useVerification, VerifyFuncProps } from "hooks/useVerification";
import { IVerifyForm } from "interfaces";

const { Text } = Typography;

declare const ButtonTypes: [
  "default",
  "primary",
  "ghost",
  "dashed",
  "link",
  "text"
];
export declare type ButtonType = (typeof ButtonTypes)[number];
export declare type LegacyButtonType = ButtonType | "danger";

export type VerifyModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleOk: () => void;
  title: string;
  filedName?: string;
  okText?: string;
  okType?: LegacyButtonType;
};

export const VerifyModal: React.FC<VerifyModalProps> = (props) => {
  const {
    isOpen,
    handleClose,
    handleOk,
    title,
    filedName = "Password",
    okText = "Ok",
    okType = "primary",
  } = props;

  const [verificationErrors, setVerificationErrors] = useState<string[]>([]);
  const [form] = Form.useForm<IVerifyForm>();

  const handleVerificationErrors = (errors: string[]) => {
    setVerificationErrors(errors);
  };

  const clearErrors = () => {
    if (verificationErrors.length < 1) return;

    setVerificationErrors([]);
  };

  const { verify, isLoading } = useVerification({
    onErrorCallBack: handleVerificationErrors,
  });

  const onCloseModal = () => {
    form.resetFields();
    handleClose();
  };

  const handleFinish = async (values: VerifyFuncProps) => {
    await verify(values);
    handleOk();
    onCloseModal();
  };

  const getVerificationErrors = (): React.ReactNode[] => {
    return verificationErrors.map((error) => (
      <Text type="danger">{error}</Text>
    ));
  };

  const hasError = verificationErrors.length > 0 ? "error" : undefined;

  return (
    <Modal
      title={title}
      visible={isOpen}
      onOk={form?.submit}
      onCancel={onCloseModal}
      okText={okText}
      okType={okType}
    >
      <Spin spinning={isLoading}>
        <Form<any>
          layout="vertical"
          name="verify-user"
          form={form}
          onFinish={handleFinish}
          onChange={clearErrors}
          requiredMark={false}
        >
          <Form.Item
            name="password"
            label={filedName}
            rules={[{ required: true }]}
            validateStatus={hasError}
          >
            <Input.Password
              type="password"
              placeholder="●●●●●●●●"
              size="large"
            />
          </Form.Item>
          <Form.ErrorList errors={getVerificationErrors()} />
        </Form>
      </Spin>
    </Modal>
  );
};
