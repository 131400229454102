import React from "react";
import { Button, ButtonProps, Tooltip } from "@pankod/refine-antd";

type Props = ButtonProps & {
  icon: React.ReactNode;
  tooltipTitle?: string;
};

export const IconButton: React.FC<Props> = (props: Props) => {
  return (
    <Tooltip title={props.tooltipTitle}>
      <Button type="text" className="icon-button" {...props} />
    </Tooltip>
  );
};
