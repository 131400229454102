import { createStyles } from "@mantine/core";
import { primaryColor } from "styles/home";

const useStyles = createStyles((theme) => ({
  text: {
    fontFamily: "Helvetica, sans-serif",
  },
  heroWrapper: {
    position: "relative",
    backgroundImage: "url(/images/home-image-1.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "60vh",

    [theme.fn.smallerThan("md")]: {
      minHeight: "calc(100vh - 95px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  heroInner: {
    position: "relative",
    zIndex: 1,
    paddingTop: 120,
    paddingBottom: 150,

    [theme.fn.smallerThan("md")]: {
      padding: 0,
    },
  },

  heroTitle: {
    fontWeight: 800,
    fontSize: "42pt",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    color: theme.white,
    marginBottom: theme.spacing.xs,
    textAlign: "center",

    [theme.fn.smallerThan("md")]: {
      fontSize: "36pt",
    },

    "@media (max-width: 550px)": {
      fontSize: "26pt",
      // textAlign: 'left',
    },

    "@media (max-width: 350px)": {
      // textAlign: 'left',
      fontSize: "20pt",
    },
  },
  heroDescription: {
    color: theme.colors.gray[0],
    fontWeight: 500,
    fontSize: "20pt",
    textAlign: "center",

    "@media (max-width: 550px)": {
      fontSize: 18,
      // textAlign: 'left',
    },

    "@media (max-width: 350px)": {
      fontSize: 12,
    },
  },

  heroControls: {
    marginTop: theme.spacing.xl * 1.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,

    "@media (max-width: 520px)": {
        flexDirection: 'column',
    //   gap: "10px",
    },
  },

  heroControl: {
    height: 42,
    fontSize: theme.fontSizes.md,
    color: primaryColor,

    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    "@media (max-width: 520px)": {
      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },

    "@media (max-width: 300px)": {
      fontSize: theme.fontSizes.sm,
    },

    "&:hover": {
      color: "white",
      backgroundColor: primaryColor,
    },
  },

  matrixWrapper: {
    paddingTop: 50,
    paddingBottom: 50,

    [theme.fn.smallerThan("md")]: {
      padding: 50,
    },
  },

  purpose: {
    fontSize: "20pt",
    fontWeight: "bold",
    color: "black",

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
    },

    [theme.fn.smallerThan("sm")]: {
      fontSize: "16pt",
      // textAlign: 'center',
    },

    "@media (max-width: 300px)": {
      fontSize: "12pt",
    },
  },

  purposeDescription: {
    fontSize: "12pt",
    // fontWeight: "bold",
    color: "black",

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
    },

    [theme.fn.smallerThan("sm")]: {
      fontSize: "10pt",
      // textAlign: 'center',
    },

    "@media (max-width: 300px)": {
      fontSize: "7pt",
    },
  },
}));

export { useStyles };
