import { useCreate, useDelete, useUpdate } from "@pankod/refine-core";
import { IHookResponse, IAction } from "interfaces";

export type UseActionCRUDProps = {}

export interface IUseActionCRUDResponse extends IHookResponse {
    state: {
        isLoading: boolean
    },
    operations: {
        create(action: IAction): Promise<IAction>
        update(actionId: number, action: IAction): Promise<IAction>,
        deleteById(actionId: number): Promise<void>
    }
}


export const useActionCRUD = (props: UseActionCRUDProps = {}): IUseActionCRUDResponse => {

    // Create action
    const { mutateAsync: createMutation, isLoading: createLoading } = useCreate<IAction>()
    const create = async (action: IAction): Promise<IAction> => {
        const { data } = await createMutation({
            resource: 'actions',
            values: action
        })

        return data;
    }

    // Update action
    const { mutateAsync: updateMutation, isLoading: updateLoading } = useUpdate<IAction>();
    const update = async (actionId: number, action: IAction): Promise<IAction> => {
        const { data } = await updateMutation({
            resource: 'actions',
            id: actionId,
            values: action
        })

        return data;
    }

    // Delete action
    const { mutateAsync: deleteMutation, isLoading: deleteLoading } = useDelete();
    const deleteById = async (actionId: number): Promise<void> => {
        await deleteMutation({
            resource: "actions",
            id: actionId
        })
    }

    return {
        state: {
            isLoading: createLoading || updateLoading || deleteLoading
        },
        operations: {
            create,
            update,
            deleteById
        }
    }
}