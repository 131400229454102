import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { IconButton } from "./IconButton";
import { Popconfirm } from "@pankod/refine-antd";
import { LegacyButtonType } from "types";

type Props = {
  onDelete: () => void;
  title?: string;
  okText?: string;
  cancelText?: string;
  okType?: LegacyButtonType;
  disabled?: boolean;
  iconStyle?: React.CSSProperties;
  tooltipTitle?: string;
};

export const DeleteIconButton: React.FC<Props> = (props) => {
  const {
    onDelete,
    title,
    okText,
    cancelText,
    okType,
    disabled,
    iconStyle = {},
    tooltipTitle,
  } = props;
  return (
    <Popconfirm
      placement="left"
      key="delete-button"
      okText={okText || "Delete"}
      cancelText={cancelText || "Cancel"}
      okType={okType || "danger"}
      title={title || "Are you sure?"}
      okButtonProps={{ disabled: disabled }}
      onConfirm={(e) => {
        e?.stopPropagation();
        onDelete();
      }}
      onCancel={(e) => {
        e?.stopPropagation();
      }}
      disabled={disabled}
      className="icon-button"
    >
      <IconButton
        tooltipTitle={tooltipTitle}
        icon={<DeleteOutlined style={iconStyle} />}
        onClick={(e) => e.stopPropagation()}
      />
    </Popconfirm>
  );
};
