import React from "react";
import { Container, Text, Title, TitleOrder } from "@mantine/core";
import { PublicWrapper } from "components/publicLayout";
import config from "config.json";
import { TermPoints } from "./TermPoints";
import { ClausesTable } from "./ClausesTable";

type TermsOfUseContentProps = {
  titlePaddingTop?: number;
  titlePaddingBottom?: number;
  titleOrder?: TitleOrder;
};

// for modal use
export const TermsOfUseContent: React.FC<TermsOfUseContentProps> = ({
  titlePaddingTop = 80,
  titlePaddingBottom = 20,
  titleOrder,
}) => {
  return (
    <>
      <Container id="terms-title" pt={titlePaddingTop} pb={titlePaddingBottom}>
        <Title
          id="terms-of-use-title"
          style={{ color: "var(--secondary-color)", marginBottom: "10px" }}
          order={titleOrder}
        >
          {config.APP_NAME} Terms of Use
        </Title>
        <Text style={{ color: "black", marginBottom: "10px" }}>
          Updated 12 June 2023
        </Text>
      </Container>
      <ClausesTable />
      <TermPoints />
    </>
  );
};

type Props = {};

export const TermsOfUsePage: React.FC<Props> = (props: Props) => {
  return (
    <PublicWrapper>
      <TermsOfUseContent />
    </PublicWrapper>
  );
};
