import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-0LL25VBRFL";

export const initializeGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID, {
    gtagUrl: `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`,
  });
  ReactGA._gtag("config", GA_MEASUREMENT_ID);
};

const login = () => ReactGA.event("login", { method: "normal" });

const addContactToMatrix = () =>
  ReactGA.event({
    action: "Add contact to matrix",
    category: "Matrix",
    label: "Add-contact-to-matrix",
  });

const createMatrix = () =>
  ReactGA.event({
    action: "create-matrix",
    category: "Matrix",
    label: "Create-matrix",
  });

const gaEvents = {
  login,
  addContactToMatrix,
  createMatrix,
};

export { gaEvents };
