import React from "react";
import { Col, Image, Row, Typography } from "@pankod/refine-antd";
import ExportSteps from "./ExportSteps";
import {
  salesforceCreateReportSteps,
  salesforceExportFields,
  salesforceExportingSteps,
} from "./constants";

const ExportFiledName = (props: { name: string; required?: boolean }) => {
  const requiredStyle = props.required
    ? { color: "var(--secondary-color)" }
    : {};

  const filedType = props.required ? "required" : "optional";

  return (
    <Typography.Text style={requiredStyle}>
      <b>{props.name}</b> - {filedType}
    </Typography.Text>
  );
};

type Props = {};
// TODO: refactor with LinkedIn steps
const SalesforceExportSteps: React.FC<Props> = ({}) => {
  const getExportFieldNames = (): JSX.Element => {
    return (
      <Row>
        {salesforceExportFields.map((field) => {
          return (
            <Col span={24}>
              <ExportFiledName name={field.name} required={field.required} />
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <>
      <Typography.Title level={4} style={{ color: "#626262" }}>
        Creating a report
      </Typography.Title>
      <ExportSteps steps={salesforceCreateReportSteps} />
      <div style={{ marginBottom: "10px" }}>
        <Typography.Title
          level={4}
          style={{ color: "#626262", marginBottom: "0" }}
        >
          Setting up the report
        </Typography.Title>
        <Typography.Text>
          Before running the report, we need to set it up to include the
          necessary information.
        </Typography.Text>
      </div>
      <Row gutter={[0, 15]} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          <Typography.Text>
            1. Under <b>Outline</b>, find the <b>Columns</b> setting and include
            the following columns:
          </Typography.Text>
        </Col>
        <Row gutter={[20, 10]}>
          <Col sm={9}>
            <Image src="images/export-steps/salesforce_export_step_1.jpg" />
          </Col>
          <Col sm={14}>
            {getExportFieldNames()}
            <Row style={{ marginTop: "10px" }}>
              <div style={{ fontSize: "12px" }}>
                <Col span={24}>
                  <b>Note: Account Name</b> will appear as Organisation in the
                  Relationship Matrix.
                </Col>
                <Col span={24}>
                  The three required columns enable the Relationship Matrix to
                  keep track of which contacts have already been imported.{" "}
                  <br />
                  <span style={{ color: "var(--secondary-color)" }}>
                    <b>Warning</b>: The import will fail if <b>Last Name</b>,
                    <b>Contact ID</b> and <b>Created Date</b> are not included.
                  </span>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
        <Col></Col>
        <Col span={24}>
          <Typography.Text>
            2. <b>Run</b> the report.
          </Typography.Text>
        </Col>
      </Row>
      <Typography.Text>
        Make sure that the report contains the contacts you want to import into
        the Relationship Matrix. If you need to make corrections, click Edit and
        make changes to the report’s Outline and Filters.
      </Typography.Text>
      <Typography.Title level={4} style={{ color: "#626262" }}>
        Exporting the report
      </Typography.Title>
      <ExportSteps steps={salesforceExportingSteps} />
      <Row gutter={[0, 8]}>
        <Col span={24}>
          This will download an XLS file to your browser's default download
          folder. Find and open the file to make sure that it contains the
          necessary columns:
        </Col>
        <Col span={24}>
          <Image src="images/export-steps/salesforce_export_step_2.jpg" />
        </Col>
        <Col span={24}>
          You can now use this file to import your contacts into your
          Relationship Matrix account.
        </Col>
      </Row>
    </>
  );
};

export default SalesforceExportSteps;
