import { MantineThemeOverride } from "@mantine/core";

export const primaryColor = "#993399";
export const secondaryColor = "#aa1e2f";

export const homeTheme: MantineThemeOverride = {
  headings: { fontFamily: "Roboto" },
  fontFamily: "Helvetica, sans-serif",
  components: {
    Text: {
      styles: {
        root: { color: "#000000" },
      },
    },
    Title: {
      styles: {
        root: { color: "#000000" },
      },
    },
  },
};
