import React, { useState } from "react";
import {
  Button,
  CreateButton,
  DrawerProps,
  Empty,
  Form,
  Space,
  Table,
  TextField,
  Tooltip,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { Drawer } from "components/drawers";
import { useContacts } from "hooks/contacts/useContacts";
import { IBaseContact, IContact } from "interfaces";
import RelationshipIcon from "components/relationshipIcon";
import { FilterContactsDrawer } from "./FilterContactsDrawer";
import { AddContactDrawer } from "./AddContactDrawer";
import { FiltersIconButton } from "components/buttons";

type Props = {
  drawerProps: DrawerProps;
  onSelectContacts(contactsIds: number[]): void;
};

export const ContactsDrawer: React.FC<Props> = (props) => {
  const { state, operations } = useContacts();
  const { table, pageSize, filtersForm, createMutation } = state;

  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

  // create contact
  const [addContactDrawerVisible, setAddContactDrawerVisible] =
    useState<boolean>(false);

  const [form] = Form.useForm();

  const onCreateContactFinish = (values: IBaseContact) => {
    createMutation.mutate(values, {
      onSuccess: (newContact) => {
        props.onSelectContacts([newContact.id]);
      },
    });
  };
  //

  const [selectedContactsIds, setSelectedContactsIds] = useState<React.Key[]>(
    []
  );

  const handleRowSelection = (contactId: number | undefined) => {
    if (contactId === undefined) return;

    setSelectedContactsIds((currSelected) => {
      const isInArray = currSelected.some(
        (selectedId) => selectedId === contactId
      );

      if (!isInArray) return [...currSelected, contactId];

      return currSelected.filter((selectedId) => selectedId !== contactId);
    });
  };

  const handleSelectAll = (selected: boolean, rows: IContact[]) => {
    if (!selected) {
      setSelectedContactsIds([]);
      return;
    }
    const rowIds = rows.map((row) => row.id);
    setSelectedContactsIds(rowIds);
  };

  const handleAddContacts = () => {
    if (selectedContactsIds.length < 1) return;

    props.onSelectContacts(selectedContactsIds as number[]);
  };

  return (
    <Drawer {...props.drawerProps} isLoading={false} title="Contacts">
      <Space className="contacts-drawer-buttons">
        <Space>
          <FiltersIconButton
            onClick={() => {
              setFiltersVisible(true);
            }}
            tooltipText="Filter Contacts"
            filtersCount={table.filters?.length}
          />
          <Tooltip title="Create contact">
            <CreateButton
              // hideText
              icon={null}
              onClick={() => setAddContactDrawerVisible(true)}
            >
              Add new contact
            </CreateButton>
          </Tooltip>
        </Space>
        <Button
          onClick={handleAddContacts}
          type="primary"
          disabled={selectedContactsIds.length < 1}
          style={{ width: "100%" }}
        >
          Add selected contacts
        </Button>
      </Space>
      {/* TODO: Create table component */}
      <Table
        {...table.tableProps}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No contacts"
            />
          ),
        }}
        onRow={(record) => {
          return {
            onClick: () => handleRowSelection(record.id),
          };
        }}
        rowSelection={{
          selectedRowKeys: selectedContactsIds,
          onSelect: (record) => handleRowSelection(record.id),
          hideSelectAll: true,
          // onSelectAll: (selected, rows) => handleSelectAll(selected, rows),
        }}
        pagination={{
          ...table.tableProps.pagination,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: [8, 10, 15, 20, 50, 100],
          onShowSizeChange(_, pageSize) {
            operations.setPageSize(pageSize);
          },
        }}
      >
        <Table.Column
          dataIndex="relationship_strength"
          key="relationship_strength"
          title=""
          className="contacts-list-cell"
          width={50}
          sorter
          defaultSortOrder={getDefaultSortOrder(
            "relationship_strength",
            table.sorter
          )}
          render={(value) => <RelationshipIcon value={value} />}
        />
        <Table.Column<IContact>
          dataIndex="name"
          key="first_name"
          title="Name"
          className="contacts-list-cell"
          sorter
          render={(_, record) => (
            <TextField
              value={`${record.first_name} ${record.last_name}`}
              className="single-row-text"
            />
          )}
          defaultSortOrder={getDefaultSortOrder("first_name", table.sorter)}
        />
      </Table>
      <FilterContactsDrawer
        drawerProps={{
          visible: filtersVisible,
          onClose: () => {
            setFiltersVisible(false);
          },
          getContainer: "div", // needed for the drawer inside drawer
        }}
        formProps={filtersForm}
        clearFilters={operations.clearFilters}
        onClose={() => setFiltersVisible(false)}
      />
      {addContactDrawerVisible && (
        <AddContactDrawer
          title="Create contact"
          drawerProps={{
            visible: addContactDrawerVisible,
            onClose: (e) => {
              setAddContactDrawerVisible(false);
              operations.clearMutationState();
            },
          }}
          formProps={{ form, onFinish: onCreateContactFinish }}
          saveButtonProps={{ onClick: form.submit }}
          isLoading={createMutation.isLoading}
          mutationErrors={createMutation.errors}
          hideSearch
        />
      )}
    </Drawer>
  );
};
