import { IMatrix, IMatrixCommand } from "interfaces";

export interface IRemoveStakeholderGroupCommand extends IMatrixCommand {
    id: number
}

export interface IRemoveStakeholderGroupResponse { }

export class RemoveStakeholderGroupCommand implements IRemoveStakeholderGroupCommand {
    id: number;

    constructor(id: number) {
        this.id = id;
    }

    execute(matrix: IMatrix) {
        return matrix;
    }

    undo(matrix: IMatrix) {
        throw new Error("Method not implemented.");
    }

    toJson(): Object[] {
        return [
            {
                name: "REMOVE_STAKEHOLDER_GROUP",
                payload: {
                    id: this.id
                }
            }
        ]
    }

}