import React from "react";
import { Row, Col, Layout, Card, Typography } from "antd";
import { Logo } from "components/logos";

const { Title } = Typography;

type Props = {
  title?: React.ReactNode;
  bodyStyle?: React.CSSProperties;
};

export const AuthenticateLayout: React.FC<Props> = ({
  title = "",
  bodyStyle = {},
  children,
}) => {
  const CardTitle =
    typeof title === "string" ? (
      <Title level={3} className="sign-in-form-title">
        {title}
      </Title>
    ) : (
      title
    );

  return (
    <Layout className="sign-in-background">
      <Row
        justify="center"
        align="middle"
        style={{
          // TODO: change this style
          height: "fit-content",
          minHeight: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="sign-in-form">
            <Logo
              containerStyle="sign-in-logo-container"
              imageStyle="sign-in-logo"
            />
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
              bodyStyle={bodyStyle}
            >
              {children}
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
