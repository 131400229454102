import React from "react";
import { EditButton, DeleteButton, Typography } from "@pankod/refine-antd";
import "./styles.css";

type MatrixCardProps = {
  id: number;
  name: string;
  onClick?(): void;
};

const MatrixCard = (props: MatrixCardProps) => {
  const { name, id, onClick } = props;
  return (
    <div className="matrix-card">
      <div
        className="matrix-card-body"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <img
          src="images/matrices/matrix-logo.svg"
          alt="matrix-logo"
          className="matrix-image"
        />
        <p className="matrix-name">
          <Typography.Title level={4}>{name}</Typography.Title>
        </p>
      </div>
      <div className="matrix-card-actions">
        <DeleteButton recordItemId={id} hideText />
        <EditButton recordItemId={id} hideText className="matrix-edit-btn" />
      </div>
    </div>
  );
};

export default MatrixCard;
