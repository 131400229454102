import React, { useMemo } from "react";
import { Drawer, DrawerProps } from "@pankod/refine-antd";
import useDeviceSize from "hooks/useDeviceSize";
import { ImportFromTypes } from "types";
import LinkedInExportSteps from "./LinkedInExportSteps";
import SalesforceExportSteps from "./SalesforceExportSteps";

type ExportStepsDrawerProps = DrawerProps & {
  visible: boolean;
  exportFrom: ImportFromTypes;
  onClose(): void;
};

export const ExportStepsDrawer: React.FC<ExportStepsDrawerProps> = ({
  title,
  exportFrom,
  ...props
}) => {
  const { state: deviceSizeState } = useDeviceSize();

  const Steps: React.FC<any> = useMemo(() => {
    return exportFrom === ImportFromTypes.LINKEDIN
      ? LinkedInExportSteps
      : SalesforceExportSteps;
  }, [exportFrom]);

  return (
    <Drawer
      title={title || `Export from ${exportFrom}`}
      width={deviceSizeState.isSmall ? "100%" : "500px"}
      {...props}
    >
      <Steps />
    </Drawer>
  );
};
