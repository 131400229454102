import React, { useMemo } from "react";
import "./styles.css";

type BarChartProps = {
  value: number;
  color: string;
  maxValue: number;
};

const ImpactBar = (props: BarChartProps) => {
  const chartElements = useMemo(() => {
    const elements = [];
    for (let index = 0; index < props.maxValue; index++) {
      const element = (
        <div
          className="bar-element"
          style={{
            backgroundColor: index < props.value ? props.color : "transparent",
          }}
        ></div>
      );
      elements.push(element);
    }
    return elements;
  }, [props.color, props.value, props.maxValue]);

  return <div className="bar-container">{chartElements}</div>;
};

export default ImpactBar;
