import {
  IResourceComponentsProps,
  useDelete,
  useLogout,
} from "@pankod/refine-core";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  useForm,
  Spin,
} from "@pankod/refine-antd";
import { IEditUserForm } from "interfaces";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useState } from "react";
import { VerifyModal } from "components/modals";
import { Edit } from "components/crud";
import { ChangePasswordDrawer } from "components/drawers";

export const Account: React.FC<IResourceComponentsProps> = () => {
  const [isChangePasswordVisible, setChangePasswordVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { mutateAsync: logout } = useLogout();

  const { formProps, saveButtonProps, queryResult, formLoading } =
    useForm<IEditUserForm>({
      action: "edit",
      resource: "user",
      id: "me",
    });

  const { mutateAsync: deleteAccount } = useDelete();

  const handleDelete = async () => {
    await deleteAccount({
      resource: "user",
      id: "me",
    });
    logout();
  };

  return (
    <Edit title="Account" saveButtonProps={saveButtonProps}>
      <Spin spinning={formLoading}>
        <Row>
          <Col xs={24} lg={24}>
            <Form<any>
              {...formProps}
              layout="vertical"
              initialValues={queryResult?.data?.data}
              name="edit-account"
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row style={{ marginBottom: "24px" }}>
          <Col xs={24} md={12} lg={8}>
            <Button
              onClick={() => setChangePasswordVisible(true)}
              style={{ width: "100%" }}
            >
              Change password
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12} lg={8}>
            <Button
              // danger
              // icon={<DeleteOutlined />}
              onClick={() => setIsModalVisible(true)}
              style={{ width: "100%" }}
            >
              Close account
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </Spin>
      {isModalVisible && (
        <VerifyModal
          isOpen={isModalVisible}
          title="Close Account"
          filedName="This will delete your account and the data associated with it. Please enter your password if you want to proceed:"
          handleClose={() => setIsModalVisible(false)}
          handleOk={handleDelete}
          okText="Delete"
          okType="danger"
        />
      )}
      {isChangePasswordVisible && (
        <ChangePasswordDrawer
          drawerProps={{
            visible: isChangePasswordVisible,
            onClose: () => {
              setChangePasswordVisible(false);
            },
          }}
        />
      )}
    </Edit>
  );
};
