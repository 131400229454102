import React from "react";
import { useDeleteMany } from "@pankod/refine-core";
import {
  Button,
  Tooltip,
  DeleteButtonProps,
  Popconfirm,
} from "@pankod/refine-antd";
import { DeleteOutlined } from "@ant-design/icons";
import { IBaseContact } from "interfaces";

type IDeleteManyButtonProps = {
  resource: string;
  ids: any[];
  onSuccess?(): void;
  deleteButtonProps?: DeleteButtonProps & {
    hideText: boolean;
  };
};
export const DeleteManyButton: React.FC<IDeleteManyButtonProps> = ({
  resource,
  ids,
  deleteButtonProps,
  onSuccess,
}) => {
  const { mutateAsync, isLoading: deleteManyIsLoading } =
    useDeleteMany<IBaseContact>();

  const deleteMany = () => {
    mutateAsync({ resource, ids }, { onSuccess });
  };

  const disabled = ids.length < 1;

  return (
    <Tooltip title="Delete contact">
      <Popconfirm
        key="delete"
        okText="Delete"
        cancelText="Cancel"
        okType="danger"
        title="Are you sure?"
        okButtonProps={{ disabled: deleteManyIsLoading }}
        onConfirm={() => {
          deleteMany();
        }}
        disabled={disabled}
      >
        <Button
          danger
          loading={deleteManyIsLoading}
          icon={<DeleteOutlined />}
          disabled={disabled}
          {...deleteButtonProps}
        >
          {!deleteButtonProps?.hideText && "Delete"}
        </Button>
      </Popconfirm>
    </Tooltip>
  );
};
