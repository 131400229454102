import React from "react";
import { Badge, ButtonProps, Icon } from "@pankod/refine-antd";
import { IconButton } from "./IconButton";
import { FiltersSvgIcon } from "components/icons";

type Props = ButtonProps & {
  tooltipText?: string;
  filtersCount?: number;
};

export const FiltersIconButton: React.FC<Props> = (props: Props) => {
  return (
    <Badge count={props.filtersCount}>
      <IconButton
        icon={<Icon component={FiltersSvgIcon} />}
        className=""
        type="default"
        {...props}
        tooltipTitle={props.tooltipText}
      />
    </Badge>
  );
};
