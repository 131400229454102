import { useEffect } from "react"
import { IHookResponse } from "interfaces";
import useUser from "hooks/useUser";
import { useCreate, useNavigation, useCustom, useApiUrl, HttpError } from "@pankod/refine-core";
import { ServerErrors } from "types";

export interface IUseEmailVerificationResponse extends IHookResponse {
    state: {
        isLoading: boolean
        userEmail: string | undefined
    },
    operations: {
        verifyEmail(pin: string): Promise<void>,
        resendPin(): void
    }
}

type UseEmailVerificationProps = {
    onError?(errors: ServerErrors): void
}

export const useEmailVerification = (props: UseEmailVerificationProps = {}): IUseEmailVerificationResponse => {
    const API_URL = useApiUrl()
    const { replace } = useNavigation();

    const redirect = () => {
        replace("/matrices");
    }

    const { state } = useUser();
    const { user, isLoading: isUserLoading } = state

    const { mutateAsync, isLoading } = useCreate<any>();

    const verifyEmail = async (pin: string) => {
        await mutateAsync({ resource: "verify-email", values: { pin } }, {
            onSuccess: redirect,
            onError: (err: HttpError) => {
                const { response = {} } = err;
                const { data = {} } = response;
                const { errors = {} } = data;

                props.onError?.(errors);
            }
        });
    }

    const { refetch, isFetching } = useCustom({
        url: `${API_URL}/resend-email`,
        method: "get",
        queryOptions: { enabled: false } // disable refetching when the query mounts 
    });

    const resendPin = () => {
        refetch();
    }


    useEffect(() => {
        if (!isUserLoading && user?.email_verified_at) {
            redirect();
        }
    }, [isUserLoading, user?.email_verified_at])

    return {
        state: {
            isLoading: isUserLoading || isLoading || isFetching,
            userEmail: user?.email
        },
        operations: {
            verifyEmail,
            resendPin
        }
    }
}