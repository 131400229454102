import { Form, Input, Modal, Select, Spin, useForm } from "@pankod/refine-antd";
import { IAddStakeholderGroupForm } from "interfaces";

type AddContactProps = {
  isOpen: boolean;
  handleClose(): void;
  handleOk(values: IAddStakeholderGroupForm): void;
  getDefaultName?(): string;
};

function AddStakeholderGroupModal({
  isOpen,
  handleClose,
  handleOk,
  getDefaultName = () => "",
}: AddContactProps) {
  const [form] = Form.useForm<IAddStakeholderGroupForm>();

  const defaultGroupName = getDefaultName();

  return (
    <Modal
      title="Add new stakeholder group"
      visible={isOpen}
      onOk={form?.submit}
      onCancel={handleClose}
      okText="Add"
    >
      <Form<IAddStakeholderGroupForm>
        layout="vertical"
        name="add-stakeholder-group"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          const formValues = { ...values };
          if (!formValues.name) formValues.name = defaultGroupName;

          console.log(formValues);

          handleOk(formValues);
          handleClose();
        }}
      >
        <Form.Item name="name" label="Stakeholder group name" rules={[]}>
          <Input placeholder={defaultGroupName} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddStakeholderGroupModal;
