import {
  Button,
  Col,
  Form,
  Input,
  Result,
  Row,
  Typography,
} from "@pankod/refine-antd";
import { useForgotPassword } from "@pankod/refine-core";
import { AuthenticateLayout } from "components/layout";
import React from "react";
import { Link } from "react-router-dom";

type Props = {};

type ForgotPasswordForm = {
  email: string;
};

export const ForgotPassword: React.FC<Props> = ({}) => {
  const [form] = Form.useForm<ForgotPasswordForm>();

  const {
    mutateAsync: forgotPassword,
    isLoading,
    isSuccess,
    error,
    reset,
  } = useForgotPassword<ForgotPasswordForm>();

  const getEmail = (): string => {
    return form.getFieldValue("email") || "email";
  };

  const emailSent = isSuccess && !error;

  const title = emailSent ? "Relationship Matrix" : "Forgot password?";

  return (
    <AuthenticateLayout title={title} bodyStyle={{ paddingTop: 0 }}>
      {!emailSent && (
        <Form<ForgotPasswordForm>
          layout="vertical"
          form={form}
          onFinish={(values) => {
            forgotPassword(values);
          }}
          requiredMark={true}
          initialValues={{
            has_subscribed: false,
          }}
          name="forgot-password"
        >
          <Row gutter={[0, 20]} style={{ paddingBottom: "20px" }}>
            <Col span={24}>
              <Typography.Text strong>
                {`Enter the email you used to register for the Relationship Matrix with to receive a password reset link:`}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
                style={{ marginBottom: "12px" }}
              >
                <Input size="large" />
              </Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                block
                style={{ marginTop: "12px" }}
              >
                Send password reset link
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {emailSent && (
        <>
          <Result
            status="success"
            title={
              <Typography.Text>
                {`Reset password link was sent to ${getEmail()}`}
              </Typography.Text>
            }
            style={{ padding: "20px 0" }}
            extra={[
              <Button
                block
                key="resend-code"
                type="primary"
                size="large"
                onClick={reset}
              >
                Send code again
              </Button>,
            ]}
          />
        </>
      )}
      <Row>
        <Col span={24}>
          <Typography.Text style={{ fontSize: 12 }}>
            Have an account?{" "}
            <Link to="/login" style={{ fontWeight: "bold" }}>
              Sign in
            </Link>
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text style={{ fontSize: 12 }}>
            Don’t have an account?{" "}
            <Link to="/register" style={{ fontWeight: "bold" }}>
              Register
            </Link>
          </Typography.Text>
        </Col>
      </Row>
    </AuthenticateLayout>
  );
};
