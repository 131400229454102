import React from "react";
import { Container, Grid, Image, List, Mark, Text, Title } from "@mantine/core";
import { StepProps } from ".";
import { StepContainer } from "./StepContainer";

export const StepSeven: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          7. Working with contacts
        </Title>
      </Container>
      <Grid>
        <Grid.Col span={12}>
          <Text>
            There is a lot of information coded in the way a contact’s name
            appears in the matrix:
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <List withPadding spacing={"md"}>
            <List.Item>
              The column which the name is in shows you the strength of your
              relationship.
            </List.Item>
            <List.Item>
              The smiley face to the left of the name indicates if that person
              is your advocate, blocker or neutral to the cause or project you
              have created the matrix for.
            </List.Item>
            <List.Item>
              The colourful bars to the left of the smiley face show the
              contact’s potential impact on your success as an{" "}
              <Mark className="influencer-background">
                <strong>i</strong>nfluencer
              </Mark>{" "}
              ,
              <Mark className="implementer-background">
                <strong>i</strong>mplementer
              </Mark>{" "}
              ,
              <Mark className="introducer-background">
                <strong>i</strong>ntroducer
              </Mark>{" "}
              , or a{" "}
              <Mark className="information-background">
                source of <strong>i</strong>nformation
              </Mark>
              , insight and ideas, also known as <i>The 4 Is</i>. Each bar has
              five boxes: the more boxes are filled in with the respective
              colour, the bigger the contact’s potential impact.
            </List.Item>
          </List>
        </Grid.Col>
        <Grid.Col span={12}>
          <Image
            src="images/user-guide/matrix-contacts.jpeg"
            alt="matrix-contacts"
          />
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
