import { Image, ImageProps } from "@pankod/refine-antd";
import React from "react";

type Props = {
  type: "small" | "power-by" | "power-by-short";
  linkProps?: React.AnchorHTMLAttributes<any>;
  imageProps?: ImageProps;
};

export const DiaDrawLogo: React.FC<Props> = ({
  type,
  imageProps,
  linkProps,
}) => {
  return (
    <a
      href="http://diadraw.com/"
      target="_blank"
      rel="noreferrer"
      {...linkProps}
    >
      <Image
        src={`/images/diadraw/${type}-logo.png`}
        alt="DiaDraw logo"
        preview={false}
        {...imageProps}
      />
    </a>
  );
};
