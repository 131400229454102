import React, { useMemo } from "react";
import ContactType from "components/contactType";
import { relationshipStrengthData } from "utils";
import { ContactTypes } from "types";
import "./styles.css";
import { Typography } from "@pankod/refine-antd";

export enum BadgeTypes {
  ICON = "ICON", // only icon
  RELATIONSHIP = "RELATIONSHIP", // only relationship
  ICON_AND_RELATIONSHIP = "ICON_AND_RELATIONSHIP", // icon and relationship
}

type ContactTypeBadgeProps = {
  contactType: ContactTypes;
  relationshipStrength: number;
  badgeType?: BadgeTypes;
};

function ContactTypeBadge({
  contactType,
  relationshipStrength,
  badgeType = BadgeTypes.ICON_AND_RELATIONSHIP,
}: ContactTypeBadgeProps) {
  const color = useMemo(() => {
    const contactRelationshipData = relationshipStrengthData.find(
      (relationshipData) => {
        return relationshipData.id === relationshipStrength;
      }
    );

    return contactRelationshipData?.color || "";
  }, [relationshipStrength]);

  const hasBackgroundColor = badgeType !== BadgeTypes.ICON;

  const isRelationshipType = badgeType === BadgeTypes.RELATIONSHIP;

  return (
    <div
      className={"contact-type-badge-container"}
      style={{
        backgroundColor: hasBackgroundColor ? color : "",
      }}
    >
      {isRelationshipType ? (
        <div>
          <Typography.Text className="relationship-strength">
            {relationshipStrength}
          </Typography.Text>
        </div>
      ) : (
        <ContactType
          type={contactType}
          showLabel={false}
          iconStyles={{ className: "contact-type-icon" }}
        />
      )}
    </div>
  );
}

export default ContactTypeBadge;
