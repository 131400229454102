import React, { useState } from "react";
import {
  Collapse,
  Drawer,
  Row,
  Table,
  TextField,
  Typography,
  Space,
  Button,
} from "@pankod/refine-antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import useDeviceSize from "hooks/useDeviceSize";
import { IImportResult, IImportDetails } from "interfaces";

type ImportResultProps = {
  result: IImportResult;
};

type ResultTable = {
  name: string;
  resultType: keyof IImportResult;
  entries: number;
  // entries: IImportDetails[];
};

type ImportResultKey = keyof Omit<
  IImportResult,
  "processed_entries" | "total_entries"
>;

export const ImportResult: React.FC<ImportResultProps> = ({ result }) => {
  // const [selectedImportDetails, setSelectedImportDetails] = useState<
  //   ImportResultKey | undefined
  // >();

  const { state: deviceSizeState } = useDeviceSize();

  // const contactsResultDetails: IImportDetails[] = !!selectedImportDetails
  //   ? result[selectedImportDetails]
  //   : [];

  return (
    <>
      <Table<ResultTable>
        size="small"
        title={() => (
          <>
            <Typography.Title level={5} style={{ color: "#626262" }}>
              Import Results
            </Typography.Title>
            {result.skipped === result.total_entries && (
              <div style={{ marginTop: "10px" }}>
                <Typography.Text
                  style={{
                    color: "var(--secondary-color)",
                    display: "block",
                  }}
                >
                  Contacts were not imported.
                </Typography.Text>
                <Typography.Text style={{ color: "var(--secondary-color)" }}>
                  Please, check if you have selected the correct platform and
                  try again.
                </Typography.Text>
              </div>
            )}
          </>
        )}
        showHeader={false}
        pagination={false}
        dataSource={[
          {
            name: "Imported",
            resultType: "imported",
            entries: result?.imported,
          },
          {
            name: "Updated",
            resultType: "overridden",
            entries: result?.overridden,
          },
          { name: "Skipped", resultType: "skipped", entries: result?.skipped },
        ]}
        // footer={() => {
        //   return (
        //     <TextField value={`Total entries: ${result?.total_entries}`} />
        //   );
        // }}
      >
        <Table.Column dataIndex="name" key="name" />
        <Table.Column
          dataIndex="entries"
          key="entries"
          render={(entries) => {
            return <TextField value={entries} />;
          }}
        />
        {/* <Table.Column<ResultTable>
          dataIndex="resultType"
          key="actions"
          render={(resultType, record) => (
            <Space
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={() => setSelectedImportDetails(resultType)}
                disabled={record.entries.length < 1}
              >
                Details {<ArrowRightOutlined />}
              </Button>
            </Space>
          )}
        /> */}
      </Table>
      {/* {selectedImportDetails && (
        <Drawer
          title="Import details"
          width={deviceSizeState.isSmall ? "100%" : "500px"}
          onClose={() => setSelectedImportDetails(undefined)}
          visible={!!selectedImportDetails}
        >
          <Collapse>
            {contactsResultDetails.map((row, index) => {
              const { import_data } = row;
              const { first_name, last_name } = import_data;

              const name = `${first_name} ${last_name}`;
              const hasName = !!name.trim();

              const messages =
                row.messages.length > 0
                  ? row.messages
                  : ["Imported successfully"];

              return (
                <Collapse.Panel
                  key={index}
                  header={hasName ? name : "Unknown contact"}
                >
                  {messages.map((message) => {
                    return (
                      <Row>
                        <TextField value={message} />
                      </Row>
                    );
                  })}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </Drawer>
      )} */}
    </>
  );
};
