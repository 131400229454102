import { IBaseContact, IMatrix } from "interfaces";
import create from 'zustand'

interface IMatrixStore {
    matrix: IMatrix | null;
    setMatrix(matrix: IMatrix | null): void,
    getContactById(contactId: number): IBaseContact | null,
    getContactRelationship(contactId: number): number | null,
    getMatrix(): IMatrix | null
}

export const useMatrixStore = create<IMatrixStore>()((set, get) => ({
    matrix: null,
    getMatrix: () => {
        return get().matrix;
    },
    setMatrix: (matrix) => {
        if (!matrix) return set((state) => ({ matrix }));

        set(state => ({ matrix: { ...state.matrix, ...matrix } }))
    },
    getContactById: (contactId: number) => {
        const contact: IBaseContact | undefined = get().matrix?.contacts.find((contact) => contact.id === contactId);

        if (!contact) return null;

        return contact
    },
    getContactRelationship: (id: number): number | null => {
        const contact: IBaseContact | null = get().getContactById(id);

        if (!contact || contact?.relationship_strength === null) return null

        return contact.relationship_strength;
    }
}));