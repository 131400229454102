import React from "react";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import { Logo } from "components/logos";
import { Authenticated } from "components/authenticated";
import { useEmailVerification } from "./useEmailVerification";
import { ServerErrors } from "types";
import { getFormFiledErrors } from "utils";
import { useLogout } from "@pankod/refine-core";

type VerificationProps = {};

interface IVerifyEmailForm {
  pin: string;
}

const EmailVerification: React.FC<VerificationProps> = (props) => {
  const [form] = Form.useForm<IVerifyEmailForm>();

  const { mutateAsync: logout } = useLogout();

  const onEmailVerificationError = (errors: ServerErrors) => {
    const fieldErrors = getFormFiledErrors(errors);
    form.setFields(fieldErrors);
  };

  const { state, operations } = useEmailVerification({
    onError: onEmailVerificationError,
  });

  const validatePin = (pin: string): Promise<void | string> => {
    if (!pin) return Promise.reject("Pin is required");

    if (pin.length < 6) return Promise.reject("Pin is invalid");

    return Promise.resolve();
  };

  return (
    <Authenticated options={{ checkAccess: false }}>
      <Layout className="sign-in-background">
        <Row
          justify="center"
          align="middle"
          style={{
            height: "100vh",
          }}
        >
          <Col xs={22}>
            <div className="sign-in-form">
              <Logo
                containerStyle="sign-in-logo-container"
                imageStyle="sign-in-logo"
              />
              <Card
                title={
                  <Typography.Title level={3} className="sign-in-form-title">
                    Welcome to the Relationship Matrix!
                  </Typography.Title>
                }
                headStyle={{ borderBottom: 0 }}
              >
                <Spin spinning={state.isLoading}>
                  <Row gutter={[0, 20]}>
                    <Col span={24}>
                      <Typography.Text strong style={{ paddingBottom: "20px" }}>
                        {`Please activate your account with the 6-digit code which was sent to ${state.userEmail}:`}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Form<IVerifyEmailForm>
                        layout="vertical"
                        name="activate-account"
                        form={form}
                        onFinish={async (values) => {
                          await operations.verifyEmail(values.pin);
                        }}
                      >
                        <Form.Item
                          name="pin"
                          rules={[
                            {
                              required: true,
                              type: "string",
                              validator(_, value: string) {
                                return validatePin(value);
                              },
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="6-digit code"
                            type="number"
                          />
                        </Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          loading={state.isLoading}
                          block
                        >
                          Activate Account
                        </Button>
                      </Form>
                    </Col>
                    <Row gutter={[0, 5]}>
                      <Col span={24} style={{ marginBottom: "20px" }}>
                        <Typography.Text>
                          <Link
                            to=""
                            style={{ fontWeight: "bold" }}
                            onClick={() => {
                              operations.resendPin();
                            }}
                          >
                            Click to receive a new code
                          </Link>
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        <Typography.Text style={{ fontSize: 12 }}>
                          Account already activated?{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              cursor: "pointer",
                              color: "var(--primary-color)",
                            }}
                            onClick={() => {
                              logout();
                            }}
                          >
                            Sign in
                          </span>
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        <Typography.Text style={{ fontSize: 12 }}>
                          Don’t have an account?{" "}
                          <Link to="/register" style={{ fontWeight: "bold" }}>
                            Register
                          </Link>
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Row>
                </Spin>
              </Card>
            </div>
          </Col>
        </Row>
      </Layout>
    </Authenticated>
  );
};

export default EmailVerification;
