import {
  IBaseContact,
  IBaseMatrix,
  IFieldValidation,
  IMatrix,
  IRelationshipStrengthData,
  IStakeholderGroup,
} from "interfaces";
import { FormInstance } from "@pankod/refine-antd";
import { ContactTypes, ListItem, ServerErrors, FieldErrors } from "types";

export const createId = (length: number = 6): string => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getFormFiledErrors = (errors: ServerErrors): FieldErrors[] => {
  const formFields: FieldErrors[] = Object.entries(errors).map((error) => {
    const [name, fieldErrors] = error;

    // some times the BE returns errors = "" , and not []
    // TODO: talk with Georgi O.
    const errors = Array.isArray(fieldErrors) ? fieldErrors : [fieldErrors];

    return { name, errors };
  });

  return formFields;
};

export const validateConfirmPassword = (
  form: FormInstance<any>,
  fieldData: IFieldValidation,
  password_confirmation: string
) => {
  const { required = false } = fieldData;

  if (required && !password_confirmation)
    return Promise.reject("'Confirm password' is required");

  const password = form.getFieldValue("password");

  if (!password) return Promise.resolve();

  if (password !== password_confirmation)
    return Promise.reject("Confirm password and password must match");

  return Promise.resolve();
};

export const getOptionsLabel = (
  optionsValue: string,
  options: ListItem[]
): string => {
  if (!optionsValue) return "";

  const option = options.find(
    (options) => options.key.toLowerCase() === optionsValue.toLowerCase()
  );

  return option?.label || "";
};

export const transformToMatrixData = (data: IBaseMatrix): IMatrix => {
  const matrix: IBaseMatrix = { ...data };
  const stakeholderGroups: [number, IStakeholderGroup][] =
    matrix.stakeholder_groups.map((stakeholderGroup) => {
      const { id, contacts } = stakeholderGroup;
      const contactsSet = new Set<number>(contacts);
      const stakeholderGroupData: IStakeholderGroup = {
        ...stakeholderGroup,
        contacts: contactsSet,
      };

      return [id, stakeholderGroupData];
    });

  const stakeholderGroupsMap = new Map<number, IStakeholderGroup>(
    stakeholderGroups
  );

  return {
    ...matrix,
    stakeholder_groups: stakeholderGroupsMap,
  };
};

export const getSelectOptions = (
  array: Record<string, any>[],
  labelProp: string,
  valueProp: string
) => {
  if (array.length < 1) return [];

  return array.map((element) => {
    return {
      label: element[labelProp] || "",
      value: element[valueProp] || "",
    };
  });
};

export const relationshipStrengthData: IRelationshipStrengthData[] = [
  {
    id: 7,
    color: "#993399",
    name: "Friend",
  },
  {
    id: 6,
    color: "#8E52B4",
    name: "Advocate",
  },
  {
    id: 5,
    color: "#806CC9",
    name: "Support",
  },
  {
    id: 4,
    color: "#7283D9",
    name: "Trust",
  },
  {
    id: 3,
    color: "#6897E4",
    name: "Like",
  },
  {
    id: 2,
    color: "#67ABEB",
    name: "Know",
  },
  {
    id: 1,
    color: "#70BCEE",
    name: "Recognise",
  },
  {
    id: 0,
    color: "#83CDF0",
    name: "Don't know",
  },
];

export const defaultContact: IBaseContact = {
  // name: "",
  first_name: "",
  last_name: "",
  job_title: "",
  organization: "",
  relationship_strength: 0,
  implementer: 0,
  influencer: 0,
  introducer: 0,
  information: 0,
  type: ContactTypes.NEUTRAL,
  notes: "",
};

export const getInnerHtml = (str: string) => {
  return { __html: str };
};

export const inNullish = (value: any) => {
  return value === null || value === undefined;
};
