import { useState } from "react";
import { IBaseContact, IContact, IHookResponse } from "interfaces";
import { useCreate, useUpdate, HttpError } from "@pankod/refine-core";
import { ServerErrors } from "types";

export type UseContactsMutationProps = {};

export type MutationOptions = {
  onSuccess?(contact: IContact): void;
  onError?(errors: any): void;
};

export interface IUseContactsMutationResponse extends IHookResponse {
  state: {
    createLoading: boolean;
    createErrors: ServerErrors;
    updateLoading: boolean;
    updateErrors: ServerErrors;
  };
  operations: {
    createContact(
      contact: IBaseContact,
      mutationOptions: MutationOptions
    ): Promise<void>;
    updateContact(
      contact: IContact,
      mutationOptions: MutationOptions
    ): Promise<void>;
    clearState(): void;
  };
}

const CONTACTS_RESOURCE = "contacts";

export const useContactsMutation = (
  props: UseContactsMutationProps = {}
): IUseContactsMutationResponse => {
  const [createErrors, setCreateErrors] = useState<ServerErrors>({});
  const [updateErrors, setUpdateErrors] = useState<ServerErrors>({});

  const clearState = () => {
    setCreateErrors({});
    setUpdateErrors({});
  };

  const { mutateAsync: asyncCreate, isLoading: createLoading } =
    useCreate<IContact>();

  const createContact = async (
    contact: IBaseContact,
    mutationOptions: MutationOptions
  ) => {
    await asyncCreate(
      {
        resource: CONTACTS_RESOURCE,
        values: contact,
      },
      {
        onSuccess(data) {
          const contact: IContact = data.data;

          mutationOptions.onSuccess?.(contact);
        },
        onError(error: HttpError) {
          const { response = {} } = error;
          const { data = {} } = response;
          const { errors = {} } = data;
          setCreateErrors(errors);
        },
      }
    );
  };

  const { mutateAsync: asyncUpdate, isLoading: updateLoading } =
    useUpdate<IContact>();

  const updateContact = async (
    contact: IContact,
    mutationOptions: MutationOptions = {}
  ) => {
    await asyncUpdate(
      {
        resource: CONTACTS_RESOURCE,
        id: contact.id,
        values: contact,
      },
      {
        onSuccess(data) {
          const contact = data.data;
          mutationOptions.onSuccess?.(contact);
        },
        onError(error: HttpError) {
          const { response = {} } = error;
          const { data = {} } = response;
          const { errors = {} } = data;
          setUpdateErrors(errors);
        },
      }
    );
  };

  return {
    state: {
      createLoading,
      createErrors,
      updateLoading,
      updateErrors,
    },
    operations: {
      createContact,
      updateContact,
      clearState,
    },
  };
};
