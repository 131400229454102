import React from "react";
import {
  Button,
  Form,
  Input,
  Spin,
  Drawer,
  DrawerProps,
  Space,
  Result,
} from "@pankod/refine-antd";
import useDeviceSize from "hooks/useDeviceSize";
import {
  ChangePasswordProps,
  useChangePassword,
} from "hooks/useChangePassword";
import { FieldErrors, ServerErrors } from "types";

type ChangePasswordDrawerProps = {
  drawerProps: DrawerProps;
};

export const ChangePasswordDrawer: React.FC<ChangePasswordDrawerProps> = (
  props
) => {
  const { state: deviceSizeState } = useDeviceSize();
  const [form] = Form.useForm();

  const onChangePasswordError = (errors: ServerErrors) => {
    const formFields: FieldErrors[] = Object.entries(errors).map((error) => {
      const [name, filedErrors] = error;
      return { name, errors: filedErrors };
    });
    form.setFields(formFields);
  };

  const { state, operations } = useChangePassword({
    onErrorCallBack: onChangePasswordError,
  });

  const validateConfirmNewPassword = (
    confirmNewPassword: string | undefined
  ): Promise<void> => {
    if (!confirmNewPassword)
      return Promise.reject("'Confirm New Password' is required");

    const newPassword = form.getFieldValue("new_password");

    if (!newPassword) return Promise.resolve();

    if (newPassword !== confirmNewPassword)
      return Promise.reject("Confirm New Password and New Password must match");

    return Promise.resolve();
  };

  return (
    <Drawer
      {...props.drawerProps}
      title="Change password"
      width={deviceSizeState.isSmall ? "100%" : "500px"}
    >
      <Spin spinning={state.isLoading}>
        {!state.isSuccess && (
          <Form<ChangePasswordProps>
            layout="vertical"
            form={form}
            onFinish={(values) => {
              operations.changePassword(values);
            }}
            requiredMark={true}
            initialValues={{}}
            name="change-password"
          >
            <Form.Item
              name="password"
              label="Enter current password"
              rules={[{ required: true, min: 6 }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="new_password"
              label="New password"
              rules={[{ required: true, min: 6 }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="new_password_confirmation"
              label="Confirm New Password"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    return validateConfirmNewPassword(value);
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Space style={{ float: "right" }}>
              <Button type="primary" htmlType="submit">
                Change password
              </Button>
            </Space>
          </Form>
        )}
        {state.isSuccess && (
          <Result
            status="success"
            title={`Password was changed successfully`}
            style={{ padding: "20px 0" }}
            extra={[
              <Button
                key="close"
                onClick={(e) => {
                  props.drawerProps.onClose?.(e as React.MouseEvent<any>);
                }}
              >
                Close
              </Button>,
            ]}
          />
        )}
      </Spin>
    </Drawer>
  );
};
