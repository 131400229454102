import React, { useMemo } from "react";
import { Form, FormProps, Radio } from "@pankod/refine-antd";
import CategoryField from "components/contactForm/CategoryField";
import { ContactTypes } from "types";
import ContactType from "components/contactType";
import { IContactImpact } from "interfaces";

type Props = {
  formProps: FormProps<IContactImpact>;
};

const ContactImpactForm: React.FC<Props> = ({ formProps }) => {
  const radioOptions = useMemo(() => {
    return Object.values(ContactTypes).map((type) => {
      return {
        label: <ContactType type={type as ContactTypes} />,
        value: type,
      };
    });
  }, []);

  return (
    <Form<IContactImpact> {...formProps}>
      <div style={{ marginBottom: "24px" }}>
        <Form.Item name="influencer" noStyle>
          <CategoryField type={"INFLUENCER"} />
        </Form.Item>
        <Form.Item name="implementer" noStyle>
          <CategoryField type={"IMPLEMENTER"} />
        </Form.Item>
        <Form.Item name="introducer" noStyle>
          <CategoryField type={"INTRODUCER"} />
        </Form.Item>
        <Form.Item name="information" noStyle>
          <CategoryField type={"INFORMATION"} />
        </Form.Item>
      </div>
      <Form.Item label="" name="type">
        <Radio.Group
          className="relationship-matrix-radio-buttons"
          optionType="button"
          options={radioOptions}
        />
      </Form.Item>
    </Form>
  );
};

export default ContactImpactForm;
