import config from "config.json";

export const clausesTable = [
  {
    clause: "Definitions",
    summary: "An explanation of our capitalised, defined terms.",
  },
  {
    clause: `${config.APP_NAME} Software Access`,
    summary: `Register an account to access ${config.APP_NAME} but we can’t promise that the Website will always be available or error-free.`,
  },
  {
    clause: "Pilot",
    summary: `We are running a pilot to trial ${config.APP_NAME} and in return for your participation in regular surveys, we will give you free access.`,
  },
  {
    clause: "Your Subscription",
    summary: `If the pilot is successful, we will offer a subscription to ${config.APP_NAME}. We will let you know how much we’ll charge for access but if you decide to subscribe, your subscription will renew automatically.`,
  },
  {
    clause: "Licence",
    summary: `We provide you with a licence to allow you to access and use ${config.APP_NAME} in accordance with our terms. This licence is just for you to use for your own purposes only.`,
  },
  {
    clause: "Your Obligations",
    summary: `You are responsible for everything that you upload to ${config.APP_NAME} so we ask you to comply with all laws and to ensure no one else can access your account. It is also your responsibility to ensure that you back-up any data because we won’t be responsible if it gets lost for any reason.`,
  },
  {
    clause: "Acceptable Use",
    summary: `Your access to ${config.APP_NAME} is contingent on you complying with our Acceptable Use requirements. These requirements ensure that you behave responsibly and in accordance with the law and not in any way that would damage or undermine us or ${config.APP_NAME}.`,
  },
  {
    clause: "Cancellation",
    summary:
      "You can cancel your subscription at any time! Please just give us 30 days’ notice to do so. We also have the right to terminate or suspend your account if you breach any of these terms. Once your subscription is cancelled you won’t be able to access your account and no Fees will be repayable.",
  },
  {
    clause: "Limitation of Liability",
    summary: `We do not limit our liability for anything that we are legally unable to. We will only ever be liable for direct losses and only ever up to a maximum of 125% of your Fees paid to us in the year before any claim. You should ensure that you bring any claims within 6 months of any claim event. You also confirm that, in deciding to use ${config.APP_NAME}, you have not relied on any statement made by us to you other than in these terms.`,
  },
  {
    clause: "Intellectual Property",
    summary: `We confirm that we either own or have the necessary consents to use all Intellectual Property Rights associated with ${config.APP_NAME} and our Website. You will not acquire (and you should not do anything to attempt to acquire) any Intellectual Property Rights through your use of ${config.APP_NAME}. You do own all the data that you upload to ${config.APP_NAME}. `,
  },
  {
    clause: "Confidentiality",
    summary:
      "We and you agree that we will respect the confidentiality of each other’s information.",
  },
  {
    clause: "Data Protection",
    summary:
      "We must comply with data protection laws and protect the privacy of your personal data. We will only process your personal data in accordance with your instructions and our Data Privacy Statement. We will tell you in our Data Privacy Statement when we transfer your data to a third party.",
  },
  {
    clause: "Contact",
    summary: "Some useful contact information.",
  },
  {
    clause: "General",
    summary:
      "Some general provisions that tell you more about how these terms operate.",
  },
];

export const definitionsTable = [
  {
    definition: "Confidential Information",
    meaning:
      "Any information (disclosed in any form) relating to either you or us and whether or not it is marked as confidential and which may reasonably be expected to be confidential in the circumstances.",
  },
  {
    definition: "Fee",
    meaning: `The subscription fee payable in advance to us by you to access ${config.APP_NAME}, as notified to you prior to your Subscription.`,
  },
  {
    definition: "Intellectual Property Rights",
    meaning:
      "Any and all patents, rights to inventions, copyright, trade secrets, trademarks, business names, goodwill and any other intellectual property rights, in each case whether registered or unregistered.",
  },
  {
    definition: "Launch Date",
    meaning: `The date, which shall be determined by us and notified to you in writing, following the successful completion of the pilot when users will be able to subscribe to ${config.APP_NAME}.`,
  },
  {
    definition: "Pilot Period",
    meaning: `The period, which shall be determined by us and notified to you, from the date of Registration until the Launch Date during which we will be trialling and developing ${config.APP_NAME}.`,
  },
  {
    definition: "Subscription Period",
    meaning:
      "The duration of your subscription (not including any Pilot Period), as elected by you when the Fee becomes payable.",
  },
  {
    definition: `${config.APP_NAME}`,
    meaning:
      "The services, as set out below, provided to you and accessed via your account on our Website.",
  },
];
