import React from "react";
import { Container, Table, Text, Title } from "@mantine/core";
import { definitionsTable } from "./common";
import config from "config.json";

type Props = {};

export const TermPoints = (props: Props) => {
  return (
    <Container py={30} id="points">
      <Container id="point-1" px={0}>
        <Title order={4}>1 Definitions</Title>
        <Text pt={10}>
          1.1 In these Terms and Conditions, the following capitalised terms
          have the following agreed meanings:
        </Text>
        <Table verticalSpacing="xs" pt={20}>
          <tbody>
            {definitionsTable.map(({ definition, meaning }, index) => {
              return (
                <tr key={index}>
                  <td>{definition}</td>
                  <td>{meaning}</td>
                </tr>
              );
            })}
            <tr key={"website"}>
              <td>Website</td>
              <td>
                The website through which you will access {config.APP_NAME},
                whose address is{" "}
                <a
                  style={{
                    color: "var(--primary-color)",
                    fontWeight: "bold",
                  }}
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://relationship-matrix.diadraw.com/
                </a>
                .
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
      <Container id="point-2" px={0} pt={20}>
        <Title order={4}>2 {config.APP_NAME} Software Access</Title>
        <Text pt={10}>
          2.1 In order to access {config.APP_NAME}, you must register for an
          account via the Website.
        </Text>
        <Text pt={10}>
          2.2 We will use reasonable endeavours to maintain the availability of
          the Website and {config.APP_NAME} at all times, but as we are reliant
          on a third-party hosting provider, we cannot guarantee 100%
          availability. We endeavour to make sure that {config.APP_NAME}
          will perform as described on our Website, however: (i) it is: made
          available only an ‘as-is’ basis; (ii) we cannot guarantee that it will
          be uninterrupted or bug/error free; and (iii) you accept that{" "}
          {config.APP_NAME} was not designed or produced to your individual
          requirements.
        </Text>
      </Container>
      <Container id="point-3" px={0} pt={20}>
        <Title order={4}>3 Pilot </Title>
        <Text pt={10}>
          3.1 In consideration of your participation (in accordance with clause
          3.3) in our pilot, we will provide you with free of charge access to
          and use of {config.APP_NAME} for the Pilot Period.
        </Text>
        <Text pt={10}>
          3.2 We welcome your feedback in order for us to develop and improve
          {config.APP_NAME}. During the Pilot Period, you agree for us to
          contact you to participate in periodic surveys related to the pilot.
          In order to retain access to {config.APP_NAME} and to your account
          during the Pilot Period, you agree to respond within the timeframe
          specified with the survey to at least one survey per calendar month
          during the Pilot
        </Text>
      </Container>
      <Container id="point-4" px={0} pt={20}>
        <Title order={4}>4 Your Subscription</Title>
        <Text pt={10}>
          4.1 Following the Launch Date, we may offer access to{" "}
          {config.APP_NAME} via a subscription.
        </Text>
        <Text pt={10}>
          4.2 In consideration of you paying the Fee and fulfilling your
          obligations in these terms, we shall provide access to{" "}
          {config.APP_NAME} in accordance with these terms.
        </Text>
        <Text pt={10}>
          4.3 The Fee payable for the subscription to {config.APP_NAME} will be
          notified to you in advance.
        </Text>
        <Text pt={10}>
          4.4 Your subscription will be valid for the Subscription Period which
          shall begin as soon as the first payment is processed. Subject to
          termination in accordance with clause 9, your subscription will renew
          automatically on the same terms and for the same duration as your
          initial Subscription Period.
        </Text>
      </Container>
      <Container id="point-5" px={0} pt={20}>
        <Title order={4}>5 Licence</Title>
        <Text pt={10}>
          5.1 For the duration of the pilot and any applicable Subscription
          Period, we grant you a personal, royalty-free, non-transferable,
          non-sublicensable, limited and revocable licence to access{" "}
          {config.APP_NAME} via the internet to the extent necessary for you to
          make reasonable use of the functionality and features of{" "}
          {config.APP_NAME} for your internal business purposes only.
        </Text>
        <Text pt={10}>
          5.2 Except to the extent expressly permitted in these terms, you agree
          that you shall not:
        </Text>
        <Container>
          <Text pt={5}>
            {"a)"} sub-license your right to access and use {config.APP_NAME};
          </Text>
          <Text pt={5}>
            {"b)"} permit any unauthorised person to access or use{" "}
            {config.APP_NAME} using your account;
          </Text>
          <Text pt={5}>
            {"c)"} use {config.APP_NAME} to provide services to third parties;
          </Text>
          <Text pt={5}>
            {"d)"} republish or redistribute any content or material from{" "}
            {config.APP_NAME};or
          </Text>
          <Text pt={5}>
            {"e)"} conduct or request that any other person conduct any load
            testing or penetration testing on {config.APP_NAME}.
          </Text>
        </Container>
      </Container>
      <Container id="point-6" px={0} pt={20}>
        <Title order={4}>6 Your Obligations</Title>
        <Text pt={10}>
          6.1 You shall comply with all applicable laws and regulations.
        </Text>
        <Text pt={10}>
          6.2 You are responsible for all data and content that is uploaded to
          {config.APP_NAME} and accordingly, you shall comply with our
          acceptable use requirements at clause 7.
        </Text>
        <Text pt={10}>
          6.3 You shall use reasonable endeavours, including reasonable security
          measures relating to account access details, to ensure that no person
          may gain access to {config.APP_NAME} using your account.
        </Text>
        <Text pt={10}>
          6.4 You agree that it is your sole responsibility to ensure that your
          systems are compatible with {config.APP_NAME}, to maintain your
          systems, create backups of any and all data, and ensure your systems
          are secure, as you see fit. You accept that we shall have no liability
          for any system failure, malfunction or data loss as a result of{" "}
          {config.APP_NAME} accessing your system, or your failure to comply
          with this clause.
        </Text>
      </Container>
      <Container id="point-7" px={0} pt={20}>
        <Title order={4}>7 Acceptable Use</Title>
        <Text pt={10}>
          7.1 You agree to use {config.APP_NAME} and the Website for lawful
          purposes only.
        </Text>
        <Text pt={10}>
          7.2 You agree that you shall not use {config.APP_NAME} or the Website
          in any way:
        </Text>
        <Container>
          <Text pt={5}>
            {"a)"} for accessing, storing, distributing or transmitting any
            Viruses or any material that is unlawful, harmful, threatening,
            defamatory, obscene, infringing, harassing or racially or ethnically
            offensive;
          </Text>
          <Text pt={5}>
            {"b)"} that facilitates illegal activity, depicts sexually explicit
            images, or promotes unlawful violence;
          </Text>
          <Text pt={5}>
            {"c)"} that is discriminatory based on race, gender, colour,
            religious belief, sexual orientation, disability;
          </Text>
          <Text pt={5}>
            {"d)"} that is otherwise illegal or causes damage or injury to any
            person or property including their privacy;
          </Text>
          <Text pt={5}>
            {"e)"} to undermine the purpose and provision of {config.APP_NAME}{" "}
            including to reproduce, duplicate, copy, sub-licence, broadcast,
            distribute, sell, re-brand or re-sell any part of the Website or{" "}
            {config.APP_NAME} without our express consent; or
          </Text>
          <Text pt={5}>
            {"f)"} to reverse engineer, decompile, disassemble, decipher or
            otherwise derive the code used on the website and the apps.
          </Text>
        </Container>
      </Container>
      <Container id="point-8" px={0} pt={20}>
        <Title order={4}>8 Fees and Payment </Title>
        <Text pt={10}>
          8.1 You shall pay the Fees in advance of the corresponding
          Subscription Period.
        </Text>
        <Text pt={10}>
          8.2 Upon registering your account and/or when subscribing to{" "}
          {config.APP_NAME}, you shall provide us with your payment card
          details, and we will automatically collect the Fees. You authorise us
          to keep your payment method on record and to automatically charge you
          the Fee for each Subscription Period until you cancel your
          subscription in accordance with clause 9.
        </Text>
        <Text pt={10}>
          8.3 If your card details are incorrect, or collection of the Fee is
          unsuccessful for any reason, we will contact you immediately to
          rectify the issue. If you are unable to successfully rectify the issue
          and pay the Fee within 5 business days from the date of our initial
          contact to inform you of such issue, we will suspend your account and
          access to {config.APP_NAME} until payment is made. You will remain
          liable for the Fee and we may charge you a reasonable administrative
          fee to reactivate your account.
        </Text>
        <Text pt={10}>
          8.4 We may increase the Fees by notifying you in writing with at least
          30 days’ notice prior to the renewal of your Subscription Period.
        </Text>
      </Container>
      <Container id="point-9" px={0} pt={20}>
        <Title order={4}>9 Cancellation</Title>
        <Text pt={10}>
          9.1 Your subscription will continue for the Subscription Period unless
          terminated earlier in accordance with this clause.
        </Text>
        <Text pt={10}>
          9.2 You may cancel your subscription at any time for any reason by
          providing us with 30 days’ notice in advance of any Subscription
          Period renewal.
        </Text>
        <Text pt={10}>
          9.3 If we believe that you are abusing {config.APP_NAME}
          system or you are in breach of our terms or our Acceptable Use Policy,
          we may cancel your subscription immediately.
        </Text>
        <Text pt={10}>
          9.4 Upon cancellation of the agreement by either party, you will no
          longer be able to access {config.APP_NAME} and we will not be obliged
          to refund any part of the Fee. Unless requested otherwise in writing,
          we will retain your data and any content uploaded to {config.APP_NAME}{" "}
          for a period of three months following the expiry or termination of
          your subscription after which point all data and content will be
          deleted.
        </Text>
        <Text pt={10}>
          9.5 Any rights, remedies, obligations or liabilities of the parties
          that have accrued up to the date of cancellation, including the right
          to claim damages in respect of any breach of the agreement which
          existed at or before the date of termination shall not be affected or
          prejudiced.
        </Text>
      </Container>
      <Container id="point-10" px={0} pt={20}>
        <Title order={4}>10 Limitation of Liability</Title>
        <Text pt={10}>
          10.1 We do not exclude liability for (i) fraud or other criminal act,
          (ii) personal injury or death caused by negligence, or (iii) any other
          liability that cannot be excluded by law.
        </Text>
        <Text pt={10}>
          10.2 In no event will we be liable under these terms for any damages
          resulting from loss of use, lost profits, loss of anticipated savings,
          loss of revenue, loss of opportunity, loss of goodwill, loss of
          reputation, loss of, damage to or corruption of data (including any
          information uploaded to {config.APP_NAME}), system malfunction or
          failure, or any indirect or consequential loss. Such liability is
          excluded whether such damages were reasonably foreseeable or actually
          foreseen.
        </Text>
        <Text pt={10}>
          10.3 Except as provided in clause 10.1 and in clause 10.2, our maximum
          aggregate liability to you for any cause whatsoever shall be for
          direct costs and damages only and will be limited to a sum equivalent
          to 125% of the Fee paid and payable by you for the year immediately
          prior to your claim in respect of service that is the subject of your
          claim.
        </Text>
        <Text pt={10}>
          10.4 To the fullest extent permitted by law, we exclude all liability
          that has not been expressly accepted in these terms. These limitations
          will apply regardless of the form of action, whether under statute, in
          contract, tort, including negligence, or any other form of action. For
          the purposes of this clause 10, ‘we’ includes our employees,
          sub-contractors, licensors and suppliers who shall therefore have the
          benefit of the limits and exclusions of liability set out in this
          clause 10 in terms of the Contracts (Rights of Third Parties) Act
          1999.
        </Text>
        <Text pt={10}>
          10.5 No action, regardless of form, arising out of or in relation to
          these terms may be brought more than six (6) months after the event
          giving rise to the cause of action.
        </Text>
        <Text pt={10}>
          10.6 You acknowledge that in entering into this agreement you have not
          relied on any representation or warranty not set out in these terms,
          and shall have no claim for innocent or negligent misrepresentation or
          negligent misstatement based on any statement made by us. We do not
          exclude or limit any liability for any misrepresentation made by us
          fraudulently.
        </Text>
      </Container>
      <Container id="point-11" px={0} pt={20}>
        <Title order={4}>11 Intellectual Property</Title>
        <Text pt={10}>
          11.1 We are the owner or licensee of the Intellectual Property Rights
          which subsist in {config.APP_NAME} and our Website. Title to{" "}
          {config.APP_NAME} shall remain vested in us or our licensors. For the
          avoidance of doubt title and all Intellectual Property Rights to any
          design, new software, new protocol, new interface, enhancement,
          template, mailer, web page, update, derivative works, revised screen
          text or any other items that we create for you shall remain vested in
          us or our licensors. Any rights not expressly granted herein are
          reserved to us.
        </Text>
        <Text pt={10}>
          11.2 You shall not attempt to access, reverse engineer, decompile or
          disassemble {config.APP_NAME} object code or source code, nor shall
          you examine any part of {config.APP_NAME} object code or source code
          for the purpose of developing a competing product with similar
          functionality to {config.APP_NAME}. Furthermore, you shall not, except
          to the extent permitted by law, permit any third party to access{" "}
          {config.APP_NAME} and/or its operating code for these purposes.
        </Text>
        <Text pt={10}>
          11.3 You hereby represent that you are the owner of all content
          uploaded to the Website and to {config.APP_NAME} and you grant us a
          licence to use and process such content solely for the purpose of
          providing you with access to and use of {config.APP_NAME}.
        </Text>
      </Container>
      <Container id="point-12" px={0} pt={20}>
        <Title order={4}>12 Confidentiality </Title>
        <Text pt={10}>
          12.1 We and you mutually agree that we will not disclose each other’s
          Confidential Information to any third-party.
        </Text>
        <Text pt={10}>
          12.2 Each party undertakes that it will not, without the prior written
          consent of the other party, use, disclose, copy or modify the other
          party's Confidential Information (or permit others to do so) other
          than is necessary for the performance of its rights and obligations
          under these terms. In any event, each party agrees that it shall treat
          the other's Confidential Information with the same degree of care as
          it employs with regard to its own Confidential Information of a like
          nature.
        </Text>
        <Text pt={10}>
          12.3 The provisions of clause 12.2 shall not apply to:
        </Text>
        <Container>
          <Text pt={5}>
            {"a)"} any information in the public domain otherwise than by breach
            of these terms;
          </Text>
          <Text pt={5}>
            {"b)"} information lawfully in the possession of the receiving party
            thereof before disclosure by the disclosing party, as evidenced by
            written documents;
          </Text>
          <Text pt={5}>
            {"c)"} information lawfully obtained without restriction from a
            third party, as evidenced by written documents; and
          </Text>
          <Text pt={5}>
            {"d)"} information required to be disclosed by a court of competent
            jurisdiction, governmental body or applicable regulatory authority
            provided that the party under such duty to disclose shall use all
            reasonable endeavours to give the other party as much prior notice
            of such disclosure as is reasonably practicable and permitted by
            law.
          </Text>
        </Container>
      </Container>
      <Container id="point-13" px={0} pt={20}>
        <Title order={4}>13 Data Protection</Title>
        <Text pt={10}>
          13.1 We and you will comply with all relevant data protection laws
          including the Data Protection Act 2018 and the General Data Protection
          Regulation 2016/679 (‘Data Protection Legislation’). We will process
          your personal data in accordance with your instructions and our
          current Data Privacy Statement which can be viewed at{" "}
          <a
            style={{
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </Text>
        <Text pt={10}>13.2 We shall ensure that:</Text>
        <Container>
          <Text pt={5}>
            {"a)"} the appropriate technical and organisational measures are in
            place to comply with the Data Protection Legislation and protect
            your rights;
          </Text>
          <Text pt={5}>
            {"b)"} persons authorised to access your data are subject to
            appropriate confidentiality undertakings;
          </Text>
          <Text pt={5}>
            {"c)"} we shall not transfer your data to any third party or engage
            another processor of your data without your consent except that you
            agree and hereby consent for us to transfer your data in accordance
            with our Data Privacy Statement; and
          </Text>
          <Text pt={5}>
            {"d)"} at the end of the Subscription Period your data is deleted in
            accordance with clause 9.4, subject to any legal obligation to store
            the copies.
          </Text>
        </Container>
      </Container>
      <Container id="point-14" px={0} pt={20}>
        <Title order={4}>14 Contact</Title>
        <Text pt={10}>
          14.1 Any questions about these terms, or about your use of{" "}
          {config.APP_NAME}, can be sent to us at{" "}
          <a
            style={{
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            href="mailto:enquiries@relationship-matrix.com"
            target="_blank"
            rel="noreferrer"
          >
            enquiries@relationship-matrix.com
          </a>
          .
        </Text>
        <Text pt={10}>
          14.2 For any technical support with your use of {config.APP_NAME},
          please contact{" "}
          <a
            style={{
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            target="_blank"
            rel="noreferrer"
            href="mailto:support@relationship-matrix.com"
          >
            support@relationship-matrix.com
          </a>
          .
        </Text>
        <Text pt={10}>
          14.3 If any clause requires you to give us notice in writing you can
          send this to us by email at{" "}
          <a
            style={{
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            target="_blank"
            rel="noreferrer"
            href="mailto:enquiries@relationship-matrix.com"
          >
            enquiries@relationship-matrix.com
          </a>{" "}
          or by pre-paid post to our registered address at the top of these
          terms.
        </Text>
      </Container>
      <Container id="point-15" px={0} pt={20}>
        <Title order={4}>15 General</Title>
        <Text pt={10}>
          15.1 These terms shall be governed by English law and subject to the
          exclusive jurisdiction of the English courts. This shall not prevent
          either you or us from seeking injunctive relief in any competent
          court.
        </Text>
        <Text pt={10}>
          15.2 You may not assign this agreement or otherwise transfer any
          rights or obligations without our prior written consent (not to be
          unreasonably withheld or delayed).
        </Text>
        <Text pt={10}>
          15.3 Provided that we notify you in advance, we may assign or novate
          our rights and obligations under these terms to any of our group
          companies (as defined in the Companies Act 2006) and you hereby give
          your explicit consent for such assignment or novation.
        </Text>
        <Text pt={10}>
          15.4 Neither party is responsible for any failure or delay in
          fulfilling its obligations due to any cause beyond its reasonable
          control that directly or indirectly delays or prevents its timely
          performance under these terms. Any specified dates or times for
          performance by the affected party will be postponed automatically for
          the extent of the delay or prevention. The affected party must use
          reasonable endeavours to overcome the cause as soon as possible and to
          mitigate the delay.
        </Text>
        <Text pt={10}>
          15.5 Any invalidity or unenforceability of any part of these terms and
          conditions will not affect the validity or enforceability of any other
          part. No failure or delay in the exercise of any right by either party
          shall constitute a waiver of any rights.
        </Text>
        <Text pt={10}>
          15.6 Except as set out herein, a person who is not a party to this
          agreement has no rights to enforce any term of it under the Contracts
          (Rights of Third Parties) Act 1999.
        </Text>
      </Container>
    </Container>
  );
};
