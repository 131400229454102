import { HttpError, useCreate } from '@pankod/refine-core'
import { ServerErrors } from 'types'

export type UseVerificationProps = {
    onErrorCallBack?: (errors: string[]) => void,
    onSuccessCallBack?: (response: any) => void
}

export type VerifyFuncProps = {
    password: string
}

export type UseVerificationResponse = {
    verify: (values: VerifyFuncProps) => void,
    isLoading: boolean
}

export const useVerification = (props: UseVerificationProps = {}): UseVerificationResponse => {
    const { onErrorCallBack = (error: ServerErrors) => { }, onSuccessCallBack = (response: any) => { } } = props;

    const { mutateAsync, isLoading } = useCreate();

    const verify = async (values: VerifyFuncProps) => {
        await mutateAsync({
            resource: 'user/verify',
            values,
            successNotification: {
                message: "Verification was successful",
                type: "success"
            },
            errorNotification: {
                message: "Wrong password",
                type: "error"
            }
        }, {
            onSuccess: onSuccessCallBack,
            onError: (err: HttpError) => {
                const { response = {} } = err;
                const { data = {} } = response;
                const { errors = {} } = data;
                const { credentials = [] } = errors;

                onErrorCallBack(credentials)
            },
        })
    }

    return {
        verify,
        isLoading
    }
}
