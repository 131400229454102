import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Typography, UploadFile, UploadProps } from "antd";
import { Upload } from "antd";
import classNames from "classnames";

const { Dragger } = Upload;

type Props = {
  value?: File;
  onChange?(value: File | undefined): void;
  fileExtensions: string[];
  uploadProps?: UploadProps;
  description?: string;
  error?: boolean;
};

function FileDraggerField({
  value,
  onChange,
  fileExtensions,
  uploadProps,
  description,
  error = false,
}: Props) {
  const getUploadedFiles = (): UploadFile[] => {
    if (!value) return [];

    const file = {
      name: value.name,
      uid: value.lastModified.toString(),
    };

    return [file];
  };

  const draggerProps: UploadProps = {
    name: "file",
    multiple: false,
    fileList: getUploadedFiles(),
    customRequest: (requestProps) => {
      const { file } = requestProps;
      if (typeof file === "string") return;
      onChange?.(file as File);
    },
    onRemove: (file) => {
      onChange?.(undefined);
    },
    accept: fileExtensions.join(","),
    ...uploadProps,
  };

  return (
    <Dragger
      {...draggerProps}
      className={classNames({ "file-drop-zone-error": error })}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined style={{ color: "var(--primary-color)" }} />
      </p>
      <Typography.Text strong>
        {description
          ? description
          : "Click to select the exported file or drag it here"}
      </Typography.Text>
    </Dragger>
  );
}

export default FileDraggerField;
