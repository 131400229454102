import { useTranslate, IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import {
    Button,
    Col,
    Edit,
    Form,
    Input,
    Row,
    SaveButton,
    Typography,
    useForm,
} from "@pankod/refine-antd";
import { IEditUserForm } from "interfaces";
import { ReactNode } from "react-markdown";

const { Text, Title } = Typography;


export const AccountEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const navigate = useNavigation();

    const { formProps, saveButtonProps, queryResult } = useForm<IEditUserForm>();

    const userData = queryResult?.data?.data || {};

    const getActionButtons = (): ReactNode => {
        return <>
            <Button onClick={() => navigate.push('change-password')}>Change password</Button>
            <SaveButton {...saveButtonProps} />
        </>
    }

    return (
        <Edit footerButtons={getActionButtons()} headerProps={{ extra: null }}>
            <Row>
                <Col xs={24} lg={10}>
                    <Form<any>
                        {...formProps}
                        layout="vertical"
                        initialValues={userData}
                    >
                        <Form.Item
                            name="email"
                            label='Email'
                            rules={[{ required: true, type: 'email' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label='Name'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Edit>
    );
};
