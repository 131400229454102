import config from "../config.json";
import { AuthProvider } from "@pankod/refine-core";
// import axios from "axios";
import { axios } from "../services/httpService";
import { ILoginForm } from "interfaces";
import { NotificationKeys } from "./notificationProvider";
export const TOKEN_KEY = "refine-auth";
export const USER_KEY = "refine-user";

export const setLocalStorageData = (token: string, user_id: any): void => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER_KEY, user_id);
};

export const removeLocalStorageData = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
};

export const setAxiosAuthorization = (token: string) => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };
};

const handleLogin = async ({
  email,
  password,
}: ILoginForm): Promise<void | false | string> => {
  try {
    const {
      data: response,
      status,
      statusText,
    } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, {
      email,
      password,
    });

    if (status === 200) {
      const { token, user_id } = response;

      setLocalStorageData(token, user_id);
      setAxiosAuthorization(token);

      return Promise.resolve("/matrices");
    }
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

const handleLoginAfterRegistration = (
  token: string,
  user_id: any
): Promise<void | false | string> => {
  setLocalStorageData(token, user_id);
  setAxiosAuthorization(token);
  return Promise.resolve("/verification");
};

const handleForgotPassword = async ({
  email,
}: {
  email: string;
}): Promise<void | false | string> => {
  const { data: response, status } = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/forgot-password`,
    {
      email,
    }
  );

  if (status !== 200) {
    return Promise.reject(response);
  }

  return Promise.resolve();
};

const handleUpdatePassword = async (payload: {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}): Promise<void | false | string> => {
  try {
    const { data: response, status } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/reset-password`,
      {
        email: payload.email,
        token: payload.token,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      }
    );

    if (status !== 200) {
      return Promise.reject(response);
    }

    return Promise.resolve();
  } catch (error: any) {
    return Promise.reject(error?.response);
  }
};

export const authProvider: AuthProvider = {
  login: async ({
    email,
    password,
    token,
    user_id,
  }): Promise<void | false | string> => {
    if (token) {
      return await handleLoginAfterRegistration(token, user_id);
    }

    return await handleLogin({ email, password });
  },
  logout: async () => {
    const token = localStorage.getItem(TOKEN_KEY) || "";

    if (!token) return Promise.resolve();

    setAxiosAuthorization(token);
    axios.post(`${process.env.REACT_APP_SERVER_URL}/logout`);

    removeLocalStorageData();
    return Promise.resolve();
  },
  forgotPassword: handleForgotPassword,
  updatePassword: handleUpdatePassword,
  checkError: (error) => {
    if (error?.response?.status === 401) {
      return Promise.reject(`/login?error=${NotificationKeys.UNAUTHORISED}`);
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      setAxiosAuthorization(token);
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1,
    });
  },
};
