import Hotjar from "@hotjar/browser";

const siteId = 3838834;
const hotjarVersion = 6;

export const initializeHotjar = () => {
  debugger;
  Hotjar.init(siteId, hotjarVersion);
};

export const changePage = (path: string) => {
  debugger;
  if (!Hotjar.isReady()) return;

  Hotjar.stateChange(path);
};
