import React from "react";
import { Container, Grid, Image, Text, Title } from "@mantine/core";
import { StepProps } from ".";
import config from "config.json";
import { StepContainer } from "./StepContainer";

export const StepSix: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          6. Adding contacts to the matrix
        </Title>
      </Container>
      <Grid style={{ marginBottom: "20px" }}>
        <Grid.Col span={12}>
          <Text>
            Use the <strong>Add Contact</strong> button of a stakeholder group
            to start adding contacts to your matrix. You will have the option of
            choosing from the contacts list you created in{" "}
            <a className="link" href="#adding-contacts-to-your-account">
              Step 4
            </a>{" "}
            or creating a new contact from within your matrix.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text>
            If you are using {config.APP_NAME} on a desktop computer, you
            can drag and drop contacts horizontally between columns when the
            strength of your relationship changes. You can also drag and drop
            contacts vertically to move them to different stakeholder groups.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Image
            src="images/user-guide/matrix-adding-contacts.jpeg"
            alt="matrix-adding-contacts"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col lg={8} md={6} sm={12}>
          <Text>
            To do the same on a mobile device, tap a contact name and from the
            context menu which appears, select a different stakeholder group
            from the drop-down or tap <strong>Edit</strong> to change the
            Strength of Relationship or any other contact details.
          </Text>
        </Grid.Col>
        <Grid.Col lg={4} md={6} sm={12}>
          <Image
            src="images/user-guide/matrix-contact-actions.png"
            alt="matrix-contact-actions"
          />
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
