import React, { HTMLAttributes } from "react";
import { ReactComponent as SalesforceSvgIcon } from "../../images/salesforce-logo.svg";
import { Icon } from "@pankod/refine-antd";

type Props = HTMLAttributes<any>;

export function SalesforceIcon(props: Props) {
  return (
    <Icon
      component={SalesforceSvgIcon}
      style={{
        color: "#1798c1",
      }}
      {...props}
    />
  );
}
