import React, { useState } from "react";
import { IContact, IContactSelectOption, IHookResponse } from 'interfaces';
import { useApiUrl, useCustomMutation, useList } from "@pankod/refine-core";

export type UseSearchContactProps = {
    onErrorCallBack?: () => void,
    onSuccessCallBack?: (response: IContactSelectOption[]) => void
}

export interface IUseSearchContactResponse extends IHookResponse {
    state: {
        allContacts: IContactSelectOption[]
        isLoading: boolean
    },
    operations: {
        search(name: string): Promise<void>
    }
}

export const useSearchContact = (props: UseSearchContactProps = {}): IUseSearchContactResponse => {
    const API_URL = useApiUrl();
    const [allContacts, setAllContacts] = useState<IContactSelectOption[]>([])

    const { mutateAsync, isLoading } = useCustomMutation<any>();

    const search = async (name: string) => {
        await mutateAsync({
            url: `${API_URL}/contacts/search`,
            method: "post",
            values: {
                name
            },
        }, {
            onSuccess: ({ data }) => {
                props.onSuccessCallBack?.(data);
            }
        });
    }

    const getOptionsFromContacts = (contacts: IContact[]): IContactSelectOption[] => {
        if (contacts.length < 1) return [];

        const options = contacts.map((contact) => {
            return {
                id: contact.id,
                name: `${contact.first_name} ${contact.last_name}`
            }
        })

        return options;
    }

    const { isFetching: listIsLoading } = useList<IContact>({
        resource: 'contacts',
        config: {
            pagination: {
                pageSize: 1000,
            },
            sort: [{ field: "first_name", order: "asc" }]
        },
        queryOptions: {
            onSuccess(data) {
                const allContactOptions = getOptionsFromContacts(data.data);
                setAllContacts(allContactOptions)
            },
        }
    });

    return {
        state: {
            allContacts,
            isLoading: isLoading || listIsLoading
        },
        operations: {
            search
        }
    }
}
