import {
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
} from "@mantine/core";
import {
  useAuthenticated,
  useLogout,
  useNavigation,
} from "@pankod/refine-core";
import { useDisclosure } from "@mantine/hooks";
import { useStyles, HEADER_HEIGHT } from "./styles";
import { Logo } from "components/logos";

interface NavbarProps {
  links?: {
    link: string;
    label: string;
    visible?: boolean;
    customAction?: () => void;
  }[];
}

export const Navbar: React.FC<NavbarProps> = ({ links }) => {
  const { push } = useNavigation();
  const [opened, { toggle, close }] = useDisclosure(false);
  const { classes } = useStyles();

  const { isSuccess: loggedIn } = useAuthenticated();
  const { mutate: logout } = useLogout();

  const openContactPage = (): void => {
    window.open("https://lopata.co.uk/contact/", "_blank");
  };

  const defaultLinks = [
    { link: "/matrices", label: "Dashboard", visible: loggedIn },
    {
      link: "/contact",
      label: "Contact",
      visible: loggedIn,
      customAction: openContactPage,
    },
    {
      link: "/quick-start-guide",
      label: "Quick Start Guide",
      visible: true,
    },
    {
      link: "/user-guide",
      label: "User Guide",
      visible: true,
    },
    {
      link: "/video-guide",
      label: "Video Guide",
      visible: true,
    },
    { link: "/login", label: "Sign in", visible: !loggedIn },
    { link: "/register", label: "Register", visible: !loggedIn },
    { link: "/", label: "Sign out", visible: loggedIn, customAction: logout },
  ];

  const getItems = () => {
    const items = links || defaultLinks;

    return items
      .filter((link) => link.visible)
      .map((link) => (
        <a
          key={link.label}
          href={link.link}
          className={classes.link}
          onClick={(event) => {
            event.preventDefault();

            if (link.customAction) {
              link.customAction();
              return;
            }

            push(link.link);
            close();
          }}
        >
          {link.label}
        </a>
      ));
  };

  return (
    <Header height={HEADER_HEIGHT} mb={120} className={classes.root}>
      <Container className={classes.header}>
        <Logo imageStyle={classes.logo} />
        <Group spacing={5} className={classes.links}>
          {getItems()}
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
          color="white"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {getItems()}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
};
