import React, { HTMLAttributes } from "react";
import { ReactComponent as LinkedInSvgIcon } from "../../images/linkedin-logo.svg";
import { Icon } from "@pankod/refine-antd";

type Props = HTMLAttributes<any>;

export function LinkedInIcon(props: Props) {
  return (
    <Icon
      component={LinkedInSvgIcon}
      style={{
        color: "#0072b1",
      }}
      {...props}
    />
  );
}
