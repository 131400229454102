import { useMemo } from 'react';
import { IHookResponse } from 'interfaces';
import { useLocation } from "react-router-dom";

export type UseQueryProps = {}

export interface IUseQueryResponse extends IHookResponse {
    operations: {
        query: URLSearchParams
    }
}

export const useQuery = (props: UseQueryProps = {}): IUseQueryResponse => {
    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    return {
        state: {},
        operations: {
            query
        }
    }
}
