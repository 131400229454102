export const aggregatedData = [
  {
    reference: "Client Correspondence and Professional Services",
    categoriesInformation: ["Identity Data", "Contact Data"],
    reason:
      "We use the personal data you have given us in order to communicate with you and provide our goods and services to you. This can also include processing data in relation to support. This processing is conducted lawfully on the basis of 'performance of a contract'.",
    personalDataFrom: "Obtained directly from you.",
  },
  {
    reference: "Direct Marketing",
    categoriesInformation: [
      "Identity Data",
      "Contact Data",
      "Marketing and Communications Data",
    ],
    reason:
      "We may use this personal data in order to market our products and services to you that we believe you will benefit from. This processing is conducted lawfully on the basis of 'our legitimate interests'.",
    personalDataFrom: "Obtained directly from you.",
  },
  {
    reference: "Legal Obligations",
    categoriesInformation: ["Identity Data", "Contact Data"],
    reason:
      "To ensure we comply with any legal and statutory obligations that might arise. This processing is conducted lawfully on the basis of 'compliance with a legal obligation'.",
    personalDataFrom: "Obtained directly from you.",
  },
  {
    reference: "Product Hub Account",
    categoriesInformation: [
      "Identity Data",
      "Contact Data",
      "Technical Data",
      "Profile Data",
    ],
    reason: "To provide you with access to your account.",
    personalDataFrom: "Obtained directly from you.",
  },
  {
    reference: "Enquiries ",
    categoriesInformation: [
      "Identity Data",
      "Contact Data",
      "Any other unsolicited personal data that you choose to submit",
    ],
    reason:
      "We use the contact information sent to us through web forms, by phone or by email in order to respond to enquiries from existing and potential clients. This processing is conducted lawfully on the basis of 'our legitimate interests'.",
    personalDataFrom: "Obtained directly from you.",
  },
];
