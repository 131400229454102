import React from "react";
import { IResourceComponentsProps, useResource } from "@pankod/refine-core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AntdLayout, Edit, Typography } from "@pankod/refine-antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Table from "components/matrix/Table";
import useDeviceSize from "hooks/useDeviceSize";
import { IconButton } from "components/buttons";
import GuidingQuestions from "components/guidingQuestions";

// TODO: refactor - remove in separate component
const MatrixSidebar = () => {
  const { state } = useDeviceSize();
  const [collapsed, setCollapsed] = React.useState<boolean>(true);

  return (
    <AntdLayout.Sider
      id="matrix-sider"
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      reverseArrow
      style={{
        position: state.isSmall ? "fixed" : "relative",
        right: 0,
        height: "100vh",
        zIndex: 999,
      }}
      trigger={<QuestionCircleOutlined />}
      collapsedWidth={0}
      width={state.isSmall ? "100%" : "500px"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid white",
          marginBottom: "20px",
        }}
      >
        <Typography.Text style={{ color: "white" }}>
          Guiding Questions
        </Typography.Text>
        <IconButton
          icon={<Typography.Text style={{ color: "white" }}>X</Typography.Text>}
          onClick={() => {
            setCollapsed(true);
          }}
        />
      </div>
      <div
        style={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <GuidingQuestions />
      </div>
    </AntdLayout.Sider>
  );
};

export const MatrixEdit: React.FC<IResourceComponentsProps> = (props) => {
  const { id } = useResource();
  const matrixId = parseInt(id?.toString() || "");

  return (
    <DndProvider backend={HTML5Backend}>
      <AntdLayout>
        <AntdLayout.Content>
          <div id="matrix-body">
            <Edit
              headerProps={{
                extra: null,
              }}
              footerButtons={() => {}}
              breadcrumb={null}
              title={""}
            >
              <Table id={isNaN(matrixId) ? undefined : matrixId} />
            </Edit>
          </div>
        </AntdLayout.Content>
        <MatrixSidebar />
      </AntdLayout>
    </DndProvider>
  );
};
