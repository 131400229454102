import React from "react";
import { Container, Grid, Image, Text, Title } from "@mantine/core";
import { StepProps } from ".";
import { StepContainer } from "./StepContainer";

export const StepFive: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          5. Creating a matrix
        </Title>
      </Container>
      <Grid>
        <Grid.Col lg={6} md={6} sm={12}>
          <Text>
            To create your first relationship matrix, navigate to the{" "}
            <strong>Matrices </strong>
            screen from the main menu and click or tap{" "}
            <strong>Create Matrix</strong>.
          </Text>
        </Grid.Col>
        <Grid.Col lg={6} md={6} sm={12}>
          <Image
            src="images/user-guide/create-matrix.jpg"
            alt="create-matrix"
          />
        </Grid.Col>
      </Grid>
      <Grid style={{ marginTop: "20px" }}>
        <Grid.Col span={12}>
          <Text>
            After you give your new matrix a name, for example Steps to Success,
            the matrix will open on the screen. A new matrix has four
            stakeholder groups with generic names by default. You can rename and
            delete them and add new ones as you need.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text>
            Across the top you will see columns representing the Strength of
            Relationship between you and the contacts you have added.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Image src="images/user-guide/matrix.jpeg" alt="matrix" />
        </Grid.Col>
        <Grid.Col span={12} mt={20}>
          <Text>
            Also see a step-by-step tutorial for how to set up your first matrix
            here:{" "}
            <a className="link" href="/quick-start-guide" target="_blank">
              Quick Start Guide
            </a>
            .
          </Text>
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
