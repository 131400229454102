import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  footer: {
    // marginTop: "1rem",
    padding: "2rem 0",
    backgroundColor: "#272d38",
    borderTop: `0.01rem solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: "12rem",

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    maxWidth: "80%",
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    // padding: "2rem",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  link: {
    display: "block",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: "0.2",
    paddingBottom: "0.2",

    "&:hover": {
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[6],
      textDecoration: "underline",
    },
  },

  afterFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `0.01rem solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      gap: "20px",
    },
  },

  agreementsLinks: {
    display: "flex",
    columnGap: "10px",

    [theme.fn.smallerThan("sm")]: {
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
      gap: "20px",
    },
  },

  diadrawLogoContainer: {
    display: "flex",
    flexDirection: "row-reverse",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  diadrawLogo: {
    width: "300px",

    [theme.fn.smallerThan("md")]: {
      width: "250px",
    },
  },
}));

export { useStyles };
