import {
  Center,
  Container,
  Grid,
  Image,
  List,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { PublicWrapper } from "components/publicLayout";
import { StepContainer } from "pages/user-guide/steps/StepContainer";
import config from "config.json";

type Props = {};

export const QuickStartGuidePage: React.FC<Props> = () => {
  return (
    <PublicWrapper>
      <Container
        id="help-title-container"
        fluid
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Container id="help-title" pt={80} pb={20}>
          <Center>
            <Title
              style={{ color: "var(--secondary-color)", marginBottom: "10px" }}
              align="center"
            >
              Five Steps to Developing Your First {config.APP_NAME_SHORT}
            </Title>
          </Center>
        </Container>
        <Container pb={40}>
          <Grid>
            <Grid.Col span={12}>
              <Text>
                Having checked out our{" "}
                <a
                  href="/user-guide"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  User guide
                </a>{" "}
                and{" "}
                <a
                  href="/video-guide"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video Guides
                </a>
                , you’re now itching to begin creating your first{" "}
                <strong>{config.APP_NAME_SHORT}</strong>. But where do you
                begin?
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Text>It’s really quite simple...</Text>
            </Grid.Col>
          </Grid>
        </Container>
      </Container>
      <StepContainer id="step-one" title="Step one">
        <Stack spacing={"lg"}>
          <Text>
            Click on <strong>Matrices</strong> in the left hand menu and then
            select the grey box that says <strong>'Create Matrix'</strong>.
          </Text>
          <Center>
            <Image
              src="images/getting-started/step-one-1.webp"
              alt="import-contacts-results"
              style={{
                maxWidth: "400px",
              }}
              className="default-box-shadow"
            />
          </Center>
          <Text>
            A new dialogue box will open, inviting you to name your new{" "}
            <strong>{config.APP_NAME_SHORT}</strong>.
          </Text>
          <Center>
            <Image
              src="images/getting-started/step-one-2.webp"
              alt="import-contacts-results"
              style={{
                maxWidth: "400px",
              }}
              className="default-box-shadow"
            />
          </Center>
          <Text>
            We recommend that the name of the matrix corresponds to the
            objective you want to set. And you want to keep that objective
            fairly focused – if it is too broad (such as ‘win new business’ or
            ‘career development’), you’ll struggle to identify the right people
            to help you.
          </Text>
          <Text>
            Try something like ‘Win new business in the healthcare sector’,
            ‘Create more opportunities within Coca Cola’ or ‘Get promoted to a
            Board role’.
          </Text>
        </Stack>
      </StepContainer>
      <StepContainer id="step-two" title="Step two">
        <Stack spacing={"lg"}>
          <Text>
            Once you have named your matrix and clicked on{" "}
            <strong>Create</strong>, a blank matrix will open up for you.
            Start down the left-hand side, by naming the stakeholder groups who
            can help you achieve your goal (click on the pencil by the
            stakeholder group title to edit the name). Four are listed but you
            can have as few, or as many, as you wish.
          </Text>
          <Text>
            We would encourage you to think of groups of people, not individuals
            though. One of the goals of <strong>{config.APP_NAME}</strong> is to
            uncover blind spots – identify people you haven’t thought of. If you
            name individuals only, you won’t challenge yourself to ask the
            question, <strong>‘Who else...?’</strong>.
          </Text>
          <Text>
            Throughout the matrix, you’ll note prompts to guide you as you hover
            over key areas.
          </Text>
          <Center>
            <Image
              src="images/getting-started/step-two.webp"
              alt="import-contacts-results"
              style={{
                maxWidth: "400px",
              }}
              className="default-box-shadow"
            />
          </Center>
        </Stack>
      </StepContainer>
      <StepContainer id="step-three" title="Step three">
        <Stack spacing={"lg"}>
          <Text>
            List everyone you can think of in each stakeholder group based on{" "}
            <strong>how well they know you</strong> (following the stages of
            professional relationship along the horizontal axis). More rather
            than less is the rule here, it’s better to add people and then
            remove them, having considered them first, than to rule people out
            without going through the process.
          </Text>
          <Text>
            Include people who don’t know you but who could be
            relevant/important. You will then be able to focus on changing that
            through your use of the matrix.
          </Text>
        </Stack>
      </StepContainer>
      <StepContainer id="step-four" title="Step four">
        <Stack spacing={"lg"}>
          <Text>
            Once you have added everyone (for now), click on each individual and
            select <strong>Edit Impact</strong>. You can then score them out
            of five for how they can best help you{" "}
            <strong>towards this specific objective</strong>. 1 means they
            cannot really help you in this context, 5 means that they are key.
            If they score 1 or 2 for all four elements, you might then want to
            remove them from your matrix.
          </Text>
          <Grid>
            <Grid.Col sm={12} md={6} lg={6}>
              <Center>
                <Image
                  src="images/getting-started/step-four-1.webp"
                  alt="import-contacts-results"
                  style={{
                    maxWidth: "200px",
                  }}
                  className="default-box-shadow"
                />
              </Center>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={6}>
              <Center>
                <Image
                  src="images/getting-started/step-four-2.webp"
                  alt="import-contacts-results"
                  style={{
                    height: "100%",
                    maxWidth: "400px",
                  }}
                  className="default-box-shadow"
                />
              </Center>
            </Grid.Col>
          </Grid>
        </Stack>
      </StepContainer>
      <StepContainer id="step-five" title="Step five">
        <Stack spacing={"lg"}>
          <Text>
            Now your <strong>{config.APP_NAME_SHORT}</strong> is set up, you can move
            towards taking some action. Look across your matrix and identify
            people who:
          </Text>
          <List spacing={"lg"} type="ordered" withPadding>
            <List.Item>
              Are in a position to help you (they have high impact scores) and
              would want to help you (their relationship level is 5-7). Ask
              yourself what conversation you need to have with these people if
              they are not currently giving you the help you need.
            </List.Item>
            <List.Item>
              Are in a position to help you but perhaps the relationship is not
              so strong that they’d feel highly motivated to (their relationship
              level is 1-4). What do you need to do to develop the relationship?
              Click on their record and select ‘Action Plan’ and pick an action
              to start developing the relationship. As the relationship grows,
              move them across the matrix, until it feels appropriate to ask for
              help.
            </List.Item>
            <List.Item>
              Are in a position to help you but you don’t know them.
              <List withPadding icon="-">
                <List.Item>
                  Look across your matrix for those who score high as potential
                  introducers. Can you ask them to make an introduction for you?
                </List.Item>
                <List.Item>
                  Look across your matrix for those who score high as potential
                  influencers. Can you ask them to talk about you to the people
                  who don’t know you yet?
                </List.Item>
              </List>
            </List.Item>
          </List>
          <Text>
            These five steps should help get you from a blank slate to some
            impactful conversations. Best of luck with them, and let us know how
            you get on.
            <br />
            <br />
            Andy Lopata, author of <strong>{config.APP_NAME}</strong>,{" "}
            <a href="mailto:andy@lopata.co.uk" className="link">
              andy@lopata.co.uk
            </a>
            <br />
            Rada and Hristo from the Diadraw team, responsible for the
            development of the web app,{" "}
            <a href="mailto:support@diadraw.com" className="link">
              support@diadraw.com
            </a>
          </Text>
        </Stack>
      </StepContainer>
    </PublicWrapper>
  );
};
