import { HttpError, useCreate } from '@pankod/refine-core'
import { IHookResponse } from 'interfaces';
import { setAxiosAuthorization, setLocalStorageData } from 'providers/authProvider';
import { ServerErrors } from 'types';

export type UseChangePasswordProps = {
    onErrorCallBack?: (errors: ServerErrors) => void,
    onSuccessCallBack?: (response: any) => void
}

export type ChangePasswordProps = {
    current_password: string,
    password: string;
    password_confirmation: string
}

export interface UseChangePasswordResponse extends IHookResponse {
    state: {
        isLoading: boolean,
        isSuccess: boolean
    },
    operations: {
        changePassword: (values: ChangePasswordProps) => void,
        resetResult: () => void
    }
}

export const useChangePassword = (props: UseChangePasswordProps = {}): UseChangePasswordResponse => {
    const { onErrorCallBack = () => { }, onSuccessCallBack = () => { } } = props;
    const { isLoading, mutate, isSuccess, error, reset } = useCreate<any>();

    const changePassword = (values: ChangePasswordProps) => {
        mutate({
            resource: 'user/change-password',
            values,
            successNotification: {
                message: "Password was changes successfully",
                type: "success"
            },
            errorNotification: {
                message: "Changing password was unsuccessful",
                type: "error"
            }
        }, {
            onSuccess: ({ data }) => {
                const { token = "", user_id } = data;
                setLocalStorageData(token, user_id);
                setAxiosAuthorization(token);

                onSuccessCallBack(data);
            },
            onError: (err: HttpError) => {
                const { response = {} } = err;
                const { data = {} } = response;
                const { errors = {} } = data;

                onErrorCallBack(errors);
            },
        })
    }

    return {
        state: {
            isLoading,
            isSuccess: isSuccess && !error
        },
        operations: {
            changePassword,
            resetResult: reset
        }
    }
}
