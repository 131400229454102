import React from "react";
import { Container, Table, Text } from "@mantine/core";
import { clausesTable } from "./common";
import config from "config.json";

type Props = {};

export const ClausesTable = (props: Props) => {
  return (
    <Container py={30} id="clauses-table">
      <Table verticalSpacing="xs">
        <thead>
          <tr>
            <th>Clause </th>
            <th>Clause Summary (provided for your convenience only)</th>
          </tr>
        </thead>
        <tbody>
          {clausesTable.map(({ clause, summary }, index) => {
            return (
              <tr key={index}>
                <td>
                  {index + 1}. {clause}
                </td>
                <td>{summary}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Text pt={20}>
        The clause summary is provided for convenience only and you should
        ensure you read and fully understand the full terms which will be
        legally binding on you when you register to use or access{" "}
        {config.APP_NAME}.
      </Text>
      <Text pt={20}>
        In these terms, references to ‘we’, ‘us’, or ‘our’ refer to H & A Lopata
        Limited trading as {config.APP_NAME}, a company registered in England
        with company number 06120639, whose registered address 1st & 2nd Floor,
        2 West Street, Ware, Herts, United Kingdom, SG12 9EE. ‘You’ and ‘your’
        refer to you, the company registering to use {config.APP_NAME}.
      </Text>
      <Text pt={20}>
        These terms govern your access to {config.APP_NAME} and constitute the
        entire agreement between you and us for the duration of our pilot and
        any subscription following our Launch Date.
      </Text>
      <Text pt={20}>
        We may modify these terms from time to time, and we will provide you
        notice (to the email address registered with your account) to give you
        the opportunity to review such changes before they become effective. If
        you object to any changes, you may cancel your account. Your continued
        use of {config.APP_NAME} after we notify you of our changes to these
        terms means that you consent to the updated terms as of their effective
        date.
      </Text>
    </Container>
  );
};
