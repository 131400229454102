import { useEffect, useState } from "react";
import { useNavigation } from "@pankod/refine-core";
import useUser from "hooks/useUser";
import { Spin } from "@pankod/refine-antd";

export const CanAccess: React.FC<any> = ({ children }) => {
  const [canAccess, setCanAccess] = useState<boolean>(false);

  const { state } = useUser();
  const { user, isLoading } = state;

  const { replace } = useNavigation();

  useEffect(() => {
    if (isLoading) return;

    if (!isLoading && !user?.email_verified_at) {
      replace("/verification");
      return;
    }

    if (!canAccess) {
      setCanAccess(true);
    }
  }, [isLoading, user?.email_verified_at]);

  if (isLoading)
    return (
      <Spin
        spinning={isLoading}
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></Spin>
    );

  if (!canAccess) return null;

  return <>{children}</>;
};
