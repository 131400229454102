import { useState } from "react";
import { Col, Row, Space, Tooltip, Typography } from "@pankod/refine-antd";
import { UserAddOutlined } from "@ant-design/icons";
import { IStakeholderGroup } from "interfaces";
import RelationshipsRow from "./RelationshipsRow";
import { ContactsDrawer } from "components/contactDrawers";
import { DeleteIconButton, IconButton } from "components/buttons";
import { tooltips } from "./utils";

type Props = IStakeholderGroup & {
  addNewContacts(contactsIds: number[], groupId: number): void;
  deleteRow(id: number): void;
  onEditGroupName(newName: string, groupId: number): void;
};

const TableRow = ({
  id,
  name,
  contacts,
  addNewContacts,
  deleteRow,
  onEditGroupName,
}: Props) => {
  const [contactsDrawerVisible, setContactsDrawerVisible] = useState(false);

  return (
    <>
      <Row
        key={`${name}-${id}-head`}
        style={{
          backgroundColor: "#002766",
          padding: "5px 0",
          alignItems: "center",
          borderBottom: "1px solid gray",
        }}
      >
        <Col flex={1}>
          <Tooltip title={tooltips.stakeholders}>
            <Typography.Text
              className="editable-typography"
              editable={{
                onChange: (newName) => {
                  if (!newName || newName === name) return;

                  onEditGroupName(newName, id);
                },
              }}
              style={{
                color: "white",
                alignSelf: "center",
                marginLeft: "10px",
              }}
            >
              {name}
            </Typography.Text>
          </Tooltip>
        </Col>
        <Col>
          <Space style={{ margin: "0 10px" }}>
            <IconButton
              tooltipTitle="Add contact"
              icon={<UserAddOutlined style={{ color: "white" }} />}
              onClick={() => {
                // show();
                setContactsDrawerVisible(true);
              }}
            />
            <DeleteIconButton
              tooltipTitle="Delete stakeholder group"
              onDelete={() => {
                deleteRow(id);
              }}
              iconStyle={{ color: "white" }}
            />
          </Space>
        </Col>
      </Row>
      <RelationshipsRow key={`${name}-${id}-row`} id={id} contacts={contacts} />
      {contactsDrawerVisible && (
        <ContactsDrawer
          drawerProps={{
            visible: contactsDrawerVisible,
            onClose: () => setContactsDrawerVisible(false),
          }}
          onSelectContacts={(contactsIds) => {
            addNewContacts(contactsIds, id);
            setContactsDrawerVisible(false);
          }}
        />
      )}
    </>
  );
};

export default TableRow;
