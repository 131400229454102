import React from "react";
import { Col, Row, Typography } from "@pankod/refine-antd";
import ExportSteps from "./ExportSteps";
import { getInnerHtml } from "utils";
import { linkedInExportSteps, linkedInNote } from "./constants";

type Props = {};

// TODO: refactor with Salesforce steps
const LinkedInExportSteps: React.FC<Props> = ({}) => {
  return (
    <>
      <Typography.Title level={4} style={{ color: "#626262" }}>
        How to export contacts from LinkedIn
      </Typography.Title>
      <ExportSteps steps={linkedInExportSteps} />
      <Row>
        <Col span={24}>
          <Typography.Text>
            <span dangerouslySetInnerHTML={getInnerHtml(linkedInNote)}></span>
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export default LinkedInExportSteps;
