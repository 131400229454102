import React from "react";
import { Container, Grid, Text, Title } from "@mantine/core";
import { StepProps } from ".";
import config from "config.json";
import { StepContainer } from "./StepContainer";

export const StepNine: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          9. Providing feedback and getting support
        </Title>
      </Container>
      <Grid>
        <Grid.Col span={12}>
          <Text>
            We look forward to hearing from you: what you found easy or
            challenging, what you liked about the current version of{" "}
            {config.APP_NAME} and what you would like to see changed in the next
            version.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title order={5}>Please, get in touch with us:</Title>
          <Text>
            Andy Lopata, author of {config.APP_NAME},{" "}
            <a href="mailto:andy@lopata.co.uk" className="link">
              andy@lopata.co.uk
            </a>
            <br />
            Rada and Hristo from the Diadraw team, responsible for the
            development of the web app,{" "}
            <a href="mailto:support@diadraw.com" className="link">
              support@diadraw.com
            </a>
          </Text>
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
