import { createStyles } from "@mantine/core";
import { primaryColor, secondaryColor } from "styles/home";

export const HEADER_HEIGHT = 95;

const useStyles = createStyles((theme) => ({
  root: {
    position: "sticky",
    zIndex: 99,
    backgroundColor: primaryColor,
    minHeight: HEADER_HEIGHT,
    borderBottom: "none",
    marginBottom: "0 !important",
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",
    border: `1px solid ${primaryColor}`,

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  logo: {
    width: "200px",
    height: "100px",
    padding:"2px",
    [theme.fn.smallerThan("sm")]: {
      width: "150px",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    maxWidth: "80%",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "white",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    fontFamily: "Helvetica, sans-serif",

    "&:hover": {
      backgroundColor: "#aa1e2f",
      color: "white",
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
      color: primaryColor,
    },
  },
}));

export { useStyles };
