import React, { useContext, useState } from "react";
import { Col, Row, useDrawerForm } from "@pankod/refine-antd";
import { MatrixContext } from "./Table";
import RelationshipCard from "./RelationshipCard";
import RelationshipGroup from "./RelationshipGroup";
import { tableHeadConfig } from "./TableHead";
import useDeviceSize from "hooks/useDeviceSize";
import { IBaseContact } from "interfaces";
import { EditContactDrawer, EditImpactDrawer } from "components/contactDrawers";
import ContactContextMenu from "./ContactContextMenu";
import { ServerErrors } from "types";
import { ActionPlansDrawer } from "components/drawers";

type RelationshipsRowProps = {
  id: number;
  contacts: Set<number>;
};

enum DrawerActionType {
  IMPACT = "IMPACT",
  ACTION_PLAN = "ACTION_PLAN",
}

type SelectedContactAction = {
  contactId: number;
  modalActionType: DrawerActionType;
};

function RelationshipsRow({
  id: stakeholderGroupId,
  contacts,
}: RelationshipsRowProps) {
  // this state holds the selected contacts and the type of action we want to do with this contact
  const [selectedContactAction, setSelectedContactAction] =
    useState<SelectedContactAction | null>(null);

  const openContactActionModal = (
    contactId: number,
    modalActionType: DrawerActionType
  ) => {
    setSelectedContactAction({
      contactId,
      modalActionType,
    });
  };

  const closeContactActionDrawer = () => {
    setSelectedContactAction(null);
  };

  // Memo ?
  const actionPlansDrawerOpen =
    selectedContactAction?.contactId &&
    selectedContactAction?.modalActionType === DrawerActionType.ACTION_PLAN;

  const impactDrawerIsOpen =
    selectedContactAction?.contactId &&
    selectedContactAction?.modalActionType === DrawerActionType.IMPACT;

  const { operations, state } = useContext(MatrixContext);

  const { moveContact, getContactRelationship, reloadMatrix } = operations;

  const { state: sizeState } = useDeviceSize();

  const {
    formProps,
    drawerProps,
    saveButtonProps,
    formLoading,
    show,
    mutationResult,
  } = useDrawerForm<IBaseContact>({
    action: "edit",
    resource: "contacts",
    redirect: false,
    onMutationSuccess: (data) => {
      reloadMatrix();
    },
  });

  const responseErrors: ServerErrors =
    mutationResult?.error?.response?.data?.errors || {};

  const getContactsByRelationship = (relationship: number) => {
    const relationshipContacts: JSX.Element[] = [];

    contacts.forEach((contactId) => {
      if (getContactRelationship(contactId) === relationship) {
        relationshipContacts.push(
          <ContactContextMenu
            contactId={contactId}
            stakeholderGroup={stakeholderGroupId}
            onEdit={show}
            onEditImpact={(contactId) => {
              openContactActionModal(contactId, DrawerActionType.IMPACT);
            }}
            onActionPlanOpen={(contactId) => {
              openContactActionModal(contactId, DrawerActionType.ACTION_PLAN);
            }}
          >
            <RelationshipCard
              key={contactId}
              id={contactId}
              stakeholderGroup={stakeholderGroupId}
            />
          </ContactContextMenu>
        );
      }
    });

    return relationshipContacts;
  };

  const columnsNum = tableHeadConfig.length;
  const getRelationships = () => {
    return tableHeadConfig.map((column) => {
      const groupContacts = getContactsByRelationship(column.id);

      if (groupContacts.length < 1 && sizeState.isSmall) return null;

      return (
        <Col
          className="relationship-row"
          key={`${stakeholderGroupId}-${column.id}`}
          lg={24 / columnsNum}
          md={24 / columnsNum}
          sm={24}
          xs={24}
        >
          <RelationshipGroup
            key={`${column.id}-group`}
            stakeholderGroup={column.id}
            moveContact={({
              id,
              stakeholderGroup,
            }: {
              id: number;
              stakeholderGroup: number;
            }) => {
              moveContact({
                contactId: id,
                currentGroup: stakeholderGroup,
                newGroup: stakeholderGroupId,
                relationship: column.id,
              });
            }}
          >
            {groupContacts}
          </RelationshipGroup>
        </Col>
      );
    });
  };

  const onClose = () => {
    closeContactActionDrawer();
    reloadMatrix();
  };

  return (
    <>
      <Row key={stakeholderGroupId}>{getRelationships()}</Row>
      {drawerProps.visible && (
        <EditContactDrawer
          title="Edit contact"
          drawerProps={drawerProps}
          formProps={formProps}
          saveButtonProps={saveButtonProps}
          isLoading={formLoading}
          mutationErrors={responseErrors}
        />
      )}
      {actionPlansDrawerOpen && (
        <ActionPlansDrawer
          drawerProps={{
            visible: actionPlansDrawerOpen,
            onClose,
          }}
          contactId={selectedContactAction.contactId}
          matrixId={state.matrix?.id}
        />
      )}
      {/* TODO */}
      {impactDrawerIsOpen && state.matrix?.id && (
        <EditImpactDrawer
          matrixId={state.matrix.id}
          contactId={selectedContactAction.contactId}
          drawerProps={{
            visible: impactDrawerIsOpen,
            onClose,
          }}
          close={onClose}
        />
      )}
    </>
  );
}

export default RelationshipsRow;
