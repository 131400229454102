import { IMatrix, IMatrixCommand, IStakeholderGroup } from "interfaces";

export interface IChangeStakeholderGroupCommand extends IMatrixCommand {
  contactId: number;
  currentGroup: number;
  newGroup: number;
  removeContactFromGroup(
    currentGroup: IStakeholderGroup,
    contactId: number
  ): IStakeholderGroup;
  addContactToGroup(
    newGroup: IStakeholderGroup,
    contactId: number
  ): IStakeholderGroup;
}

export interface IChangeGroupResponse {
  stakeholder_groups: Map<number, IStakeholderGroup>;
}

export class ChangeStakeholderGroupCommand
  implements IChangeStakeholderGroupCommand
{
  contactId: number;
  currentGroup: number;
  newGroup: number;

  constructor(contactId: number, currentGroup: number, newGroup: number) {
    this.contactId = contactId;
    this.currentGroup = currentGroup;
    this.newGroup = newGroup;
  }

  removeContactFromGroup(
    currentGroup: IStakeholderGroup,
    contactId: number
  ): IStakeholderGroup {
    const { contacts } = currentGroup;
    const newContacts = new Set(contacts);
    newContacts.delete(contactId);

    return {
      ...currentGroup,
      contacts: newContacts,
    };
  }

  addContactToGroup(
    newGroup: IStakeholderGroup,
    contactId: number
  ): IStakeholderGroup {
    const { contacts } = newGroup;
    const newContacts = new Set(contacts);
    newContacts.add(contactId);

    return {
      ...newGroup,
      contacts: newContacts,
    };
  }

  execute(matrix: IMatrix): IChangeGroupResponse {
    const { stakeholder_groups } = matrix;

    let currentSGroup = stakeholder_groups.get(this.currentGroup);
    if (!currentSGroup) return { stakeholder_groups };
    currentSGroup = this.removeContactFromGroup(currentSGroup, this.contactId);

    let newSGroup = stakeholder_groups.get(this.newGroup);
    if (!newSGroup) return { stakeholder_groups };
    newSGroup = this.addContactToGroup(newSGroup, this.contactId);

    const newStakeholderGroups = new Map(stakeholder_groups);
    newStakeholderGroups.set(this.currentGroup, currentSGroup);
    newStakeholderGroups.set(this.newGroup, newSGroup);

    return {
      stakeholder_groups: newStakeholderGroups,
    };
  }

  undo(matrix: IMatrix): IChangeGroupResponse {
    const { stakeholder_groups } = matrix;

    let currentSGroup = stakeholder_groups.get(this.newGroup);
    if (!currentSGroup) return { stakeholder_groups };
    currentSGroup = this.removeContactFromGroup(currentSGroup, this.contactId);

    let newSGroup = stakeholder_groups.get(this.currentGroup);
    if (!newSGroup) return { stakeholder_groups };
    newSGroup = this.addContactToGroup(newSGroup, this.contactId);

    const newStakeholderGroups = new Map(stakeholder_groups);
    newStakeholderGroups.set(this.currentGroup, currentSGroup);
    newStakeholderGroups.set(this.newGroup, newSGroup);

    return {
      stakeholder_groups: newStakeholderGroups,
    };
  }
  toJson(): Object[] {
    return [
      {
        name: "CHANGE_CONTACT_STAKEHOLDER_GROUP",
        payload: {
          contact_id: this.contactId,
          old_stakeholder_group_id: this.currentGroup,
          stakeholder_group_id: this.newGroup,
        },
      },
      // {
      //     name: "REMOVE_CONTACT_FROM_GROUP",
      //     payload: {
      //         contact_id: this.contactId,
      //         stakeholder_group_id: this.currentGroup,
      //     }
      // },
      // {
      //     name: "ADD_CONTACT_TO_GROUP",
      //     payload: {
      //         contact_id: this.contactId,
      //         stakeholder_group_id: this.newGroup,
      //     }
      // }
    ];
  }
}
