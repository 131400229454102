import React from "react";
import { Button, Col, Row, Space, Typography } from "@pankod/refine-antd";
import { Checkbox, Collapse, Spin } from "antd";
import { useActions } from "hooks/useActions";
import { IAction } from "interfaces";
import ActionForm from "./actionForm";
import useCollapse from "hooks/useCollapse";
import { DeleteIconButton } from "components/buttons";

type ActionPlanProps = {
  contactId: string | number;
  matrixId: string | number;
  matrixName: string;
};

const ActionPlan = (props: ActionPlanProps) => {
  const { matrixId, matrixName, contactId } = props;
  const { activeKeys, setKeys, addKey, removeKey } = useCollapse();

  const { state, operations } = useActions({
    matrixId,
    contactId,
    onAddAction: addKey,
    onDeleteAction: removeKey,
  });

  const getActions = (): JSX.Element[] => {
    return state.actions.map((action, index) => {
      const collapseKey = action.metadata.collapseId;
      return (
        <Collapse.Panel
          className="action-panel"
          header={action.name}
          key={collapseKey}
          extra={
            <div style={{ display: "flex", alignItems: "center" }}>
              {action.id && (
                <Checkbox
                  onClick={(e: any) => {
                    e.stopPropagation();
                    // Make Done From here
                    const checked: boolean = e?.target?.checked;

                    const actionWithDone = { ...action, done: checked };
                    operations.onSaveAction(action.id, actionWithDone);
                  }}
                  checked={action?.done}
                >
                  Done
                </Checkbox>
              )}
              <DeleteIconButton
                onDelete={() => operations.deleteAction(action.id, collapseKey)}
                iconStyle={{ color: "#fa541c" }}
              />
            </div>
          }
        >
          <ActionForm
            action={action}
            onSave={(newAction: IAction) =>
              operations.onSaveAction(action.id, newAction)
            }
          />
        </Collapse.Panel>
      );
    });
  };

  return (
    <Row>
      <Col xs={24}>
        <Typography.Title level={4}>Plan for: {matrixName}</Typography.Title>
        <Spin spinning={state.isLoading}>
          <Collapse
            activeKey={activeKeys}
            onChange={(keys) => {
              if (!Array.isArray(keys)) return;

              setKeys(keys);
            }}
          >
            {getActions()}
          </Collapse>
        </Spin>
        <Space style={{ float: "right", marginTop: "15px" }}>
          <Button type="primary" onClick={operations.addNewAction}>
            Add action
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default ActionPlan;
