import {
    useTranslate,
    IResourceComponentsProps,
    useShow,
} from "@pankod/refine-core";
import { Show, Spin, Typography } from "@pankod/refine-antd";
import { strengthOfRelationshipOptions } from "options";
import CategoryField from "components/contactForm/CategoryField";
import ContactType from "components/contactType";
import { IBaseContact } from "interfaces";

const { Title, Text } = Typography;

export const ContactShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { queryResult } = useShow<IBaseContact>();
    const { data, isFetching } = queryResult;
    const contact = data?.data;

    const hasData = !!contact;

    return (
        <Show isLoading={isFetching}>
            {/* <Title level={5}>Name</Title>
            <Text>{contact?.name}</Text>

            <Title level={5}>Job Title</Title>
            <Text>{contact?.job_title}</Text>

            <Title level={5}>Organisation</Title>
            <Text>{contact?.organization}</Text>

            <Title level={5}>Strength of relationship</Title>
            <Text>
                {hasData && strengthOfRelationshipOptions[contact.relationship_strength]?.label}
            </Text>

            <Title level={5}>Implementer</Title>
            <CategoryField id="implementer" value={contact?.implementer} disabled showLabel={false} />

            <Title level={5}>Influencer</Title>
            <CategoryField id="influencer" value={contact?.influencer} disabled showLabel={false} />

            <Title level={5}>Introducer</Title>
            <CategoryField id="introducer" value={contact?.introducer} disabled showLabel={false} />

            <Title level={5}>Source of info, insight, ideas</Title>
            <CategoryField id="information" value={contact?.information} disabled showLabel={false} />

            <Title level={5}>They are</Title>
            <Text>
                {hasData && <ContactType type={contact.type} />}
            </Text>

            <Title level={5}>Notes</Title>
            <Text>{contact?.notes}</Text> */}
        </Show>
    );
};
