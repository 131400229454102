import React, { HTMLAttributes } from "react";
import { Typography, Row, Col } from "@pankod/refine-antd";
import { SmileOutlined, FrownOutlined, MehOutlined } from "@ant-design/icons";
import { getOptionsLabel } from "utils";
import { contactTypesOptions } from "options";
import { ContactTypes } from "types";

const iconsMap = new Map<string, React.ForwardRefExoticComponent<any>>([
  [ContactTypes.ADVOCATE, SmileOutlined],
  [ContactTypes.NEUTRAL, MehOutlined],
  [ContactTypes.BLOCKER, FrownOutlined],
]);

type ContractTypeProps = {
  type: ContactTypes;
  showLabel?: boolean;
  iconStyles?: HTMLAttributes<any>;
  // iconsStyles?: IconComponentProps TODO
};

const ContactType: React.FC<ContractTypeProps> = ({
  type = "",
  showLabel = true,
  iconStyles = {},
}) => {
  const TypeIcon = iconsMap.has(type)
    ? iconsMap.get(type)
    : iconsMap.get(ContactTypes.NEUTRAL);

  return (
    <Row
      gutter={[10, 10]}
      style={{
        // margin: 0, // TODO style warning
        justifyContent: "center",
        // width: "fit-content",
        height: "fit-content",
      }}
    >
      {showLabel && (
        <Col>
          <Typography.Text>
            {getOptionsLabel(type, contactTypesOptions)}
          </Typography.Text>
        </Col>
      )}
       <Col>{TypeIcon && <TypeIcon {...iconStyles} />}</Col>
    </Row>
  );
};

export default ContactType;
