import React, { useState } from "react";

import {
  useTranslate,
  useLogout,
  useTitle,
  CanAccess,
  ITreeMenu,
  useMenu,
  useRefineContext,
  useRouterContext,
} from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icons } from "@pankod/refine-antd";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { DiaDrawLogo } from "components/logos";

const {
  UnorderedListOutlined,
  LogoutOutlined,
  VideoCameraOutlined,
  CaretRightOutlined,
} = Icons;

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const { mutate: logout } = useLogout();

  const { Link } = useRouterContext();
  const { hasDashboard } = useRefineContext();
  const Title = useTitle();
  const { SubMenu } = Menu;

  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;

      if (children.length > 0) {
        return (
          <SubMenu
            key={route}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
      >
        {hasDashboard && (
          <Menu.Item
            key="dashboard"
            style={{
              fontWeight: selectedKey === "/" ? "bold" : "normal",
            }}
            icon={<Icons.DashboardOutlined />}
          >
            <Link to="/">{translate("dashboard.title", "Dashboard")}</Link>
            {!collapsed && selectedKey === "/" && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        )}

        {renderTreeView(menuItems, selectedKey)}

        <Menu.Item key="introduction" icon={<VideoCameraOutlined />}>
          <a href="/video-guide" target="_blank">
            Video Guide
          </a>
        </Menu.Item>
        <Menu.Item key="user-guide" icon={<UnorderedListOutlined />}>
          <a href="/user-guide" target="_blank">
            User guide
          </a>
        </Menu.Item>
        <Menu.Item key="quick-start-guide" icon={<CaretRightOutlined />}>
          <a href="/quick-start-guide" target="_blank">
            Quick Start Guide
          </a>
        </Menu.Item>

        <Menu.Item
          key="logout"
          onClick={() => logout()}
          icon={<LogoutOutlined />}
        >
          {translate("buttons.logout", "Sign out")}
        </Menu.Item>
      </Menu>
      <DiaDrawLogo
        key={collapsed ? "small" : "power-by-short"}
        type={collapsed ? "small" : "power-by-short"}
        linkProps={{
          style: {
            visibility: collapsed && isMobile ? "hidden" : "visible",
            position: isMobile ? "absolute" : "fixed",
            bottom: isMobile ? "0px" : "50px",
            left: 0,
            zIndex: 99,
            width: collapsed ? "80px" : "200px",
          },
        }}
        imageProps={{
          style: { padding: "12px" },
        }}
      />
    </AntdLayout.Sider>
  );
};
