import React from "react";
import { Slider } from "@pankod/refine-antd";
import type { SliderMarks } from "antd/es/slider";
import { strengthOfRelationshipOptions } from "options";
import { getOptionsLabel } from "utils";

type RelationshipSliderProps = {
  id?: string;
  onChange?: (value: any) => void;
  value?: number;
  disabled?: boolean;
  rangeProps?: {
    range: boolean;
    value?: [number, number];
    defaultValue?: [number, number];
  };
};

function RelationshipSlider(props: RelationshipSliderProps) {
  const {
    id = "",
    onChange = () => {},
    value,
    disabled = false,
    rangeProps = {},
  } = props;

  const strengthOfRelationshipMarks: SliderMarks = {
    0: strengthOfRelationshipOptions[0].label,
    7: strengthOfRelationshipOptions[7].label,
  };

  return (
    <Slider
      id={id}
      onChange={onChange}
      value={value}
      disabled={disabled}
      min={0}
      max={7}
      // reverse
      dots
      marks={strengthOfRelationshipMarks}
      tipFormatter={(number) => {
        const strength = number?.toString() || "";
        return getOptionsLabel(strength, strengthOfRelationshipOptions);
      }}
      {...rangeProps}
    />
  );
}

export default RelationshipSlider;
