import { IBaseContact, IMatrix, IMatrixCommand } from "interfaces";

export interface IChangeRelationshipCommand extends IMatrixCommand {
    contactId: number,
    newRelationship: number,
    oldRelationship: number | null
}

export interface IChangeRelationshipResponse {
    contacts: IBaseContact[]
}

export class ChangeRelationshipCommand implements IChangeRelationshipCommand {
    contactId: number;
    newRelationship: number;
    oldRelationship: number | null = null;

    constructor(contactId: number, oldRelationship: number, newRelationship: number) {
        this.contactId = contactId;
        this.oldRelationship = oldRelationship;
        this.newRelationship = newRelationship;
    }

    execute(matrix: IMatrix): IChangeRelationshipResponse {
        const { contacts = [] } = matrix;

        const contactIndex = contacts.findIndex((contact) => contact.id === this.contactId);

        if (contactIndex < 0) throw new Error("Contact not found");

        const newContacts = [...contacts];
        this.oldRelationship = newContacts[contactIndex]?.relationship_strength;
        newContacts[contactIndex].relationship_strength = this.newRelationship;

        return {
            contacts: newContacts
        }
    }
    undo(matrix: IMatrix): IChangeRelationshipResponse {
        const { contacts = [] } = matrix;

        const contactIndex = contacts.findIndex((contact) => contact.id === this.contactId);

        if (contactIndex < 0) throw new Error("Contact not found");

        const newContacts = [...contacts];

        if (this.oldRelationship === null) return { contacts };

        newContacts[contactIndex].relationship_strength = this.oldRelationship;

        return {
            contacts: newContacts
        }
    }
    toJson(): Object[] {
        return [
            {
                name: "CHANGE_CONTACT_RELATIONSHIP_STRENGTH",
                payload: {
                    contact_id: this.contactId,
                    relationship_strength: this.newRelationship,
                    old_relationship_strength: this.oldRelationship,
                    reason: "test"
                }
            }
        ]
    }
}