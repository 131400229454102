import { useEffect } from "react";
import { useTable, useTableReturnType, FormProps } from "@pankod/refine-antd";
import { IBaseContact, IContact, IHookResponse } from "interfaces";
import { CrudSorting, parseTableParams } from "@pankod/refine-core";
import { useLocation } from "@pankod/refine-react-router-v6";
import { useContactsFilters } from "./useContactsFilters";
import { MutationOptions, useContactsMutation } from "./useContactsMutation";
import { ServerErrors } from "types";

export type UseContactsProps = {
  syncWithLocation?: boolean;
};

export interface IUseContactsResponse extends IHookResponse {
  state: {
    table: useTableReturnType<IContact>;
    filtersForm: FormProps;
    pageSize: number;
    createMutation: {
      mutate(
        contact: IBaseContact,
        mutationOptions: MutationOptions
      ): Promise<void>;
      isLoading: boolean;
      errors: ServerErrors;
    };
    editMutation: {
      mutate(
        contact: IContact,
        mutationOptions: MutationOptions
      ): Promise<void>;
      isLoading: boolean;
      errors: ServerErrors;
    };
  };
  operations: {
    clearFilters(): void;
    setPageSize(pageSize: number): void;
    clearMutationState(): void;
  };
}

const PageSizeKey = "contactsPageSize";

const defaultSorter: CrudSorting = [
  {
    field: "first_name",
    order: "asc",
  },
];

export const useContacts = (
  props: UseContactsProps = {}
): IUseContactsResponse => {
  const pageSize = parseInt(localStorage.getItem(PageSizeKey) || "10");
  const setPageSize = (pageSize: number) => {
    localStorage.setItem(PageSizeKey, pageSize.toString());
  };

  const getDefaultSorter = (): CrudSorting => {
    const savedSorterJSON = localStorage.getItem("contactsSorter");

    if (!savedSorterJSON) return defaultSorter;

    const savedSorter = JSON.parse(savedSorterJSON);

    if (!Array.isArray(savedSorter) || savedSorter.length === 0)
      return defaultSorter;

    return savedSorter;
  };

  const { search } = useLocation();
  useEffect(() => {
    const { parsedSorter } = parseTableParams(search);
    localStorage.setItem("contactsSorter", JSON.stringify(parsedSorter));
  }, [search]);

  const { tableProps, tableQueryResult, filters, setFilters, sorter, ...rest } =
    useTable<IContact>({
      resource: "contacts",
      initialPageSize: pageSize,
      syncWithLocation: props.syncWithLocation,
      defaultSetFilterBehavior: "replace",
      initialSorter: getDefaultSorter(),
    });

  const { state: filtersState, operations: filterOperations } =
    useContactsFilters({
      setFilters,
      filters,
    });

  const { state: mutationState, operations: mutationOperations } =
    useContactsMutation();

  return {
    state: {
      table: {
        tableProps,
        tableQueryResult,
        filters,
        setFilters,
        sorter,
        ...rest,
      },
      filtersForm: filtersState.formProps,
      pageSize,
      createMutation: {
        mutate: mutationOperations.createContact,
        isLoading: mutationState.createLoading,
        errors: mutationState.createErrors,
      },
      editMutation: {
        mutate: mutationOperations.updateContact,
        isLoading: mutationState.updateLoading,
        errors: mutationState.updateErrors,
      },
    },
    operations: {
      clearFilters: filterOperations.clearFilters,
      setPageSize,
      clearMutationState: mutationOperations.clearState,
    },
  };
};
