import { useState } from "react";
import { IHookResponse } from "interfaces";

export interface IUseCollapseResponse extends IHookResponse {
    state: {
        activeKeys: string[]
    };
    operations: {
        addNewAction: () => void;
        setKeys: (keys: string[]) => void;
        addKey: (key: string) => void,
        removeKey: (keyToRemove: string) => void,
        replaceKey: (oldKey: string, newKey: string) => void
    };
}

const useCollapse = () => {
    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    const setKeys = (keys: string[]) => {
        setActiveKeys(keys);
    };

    const removeKey = (keyToRemove: string) => {
        setActiveKeys((currKeys) => {
            return currKeys.filter((key) => key !== keyToRemove);
        });
    };

    const addKey = (key: string) => {
        setActiveKeys((currKeys) => [...currKeys, key]);
    };

    const replaceKey = (oldKey: string, newKey: string) => {
        setActiveKeys((currKeys) => {
            const keysCopy = [...currKeys];
            const oldKeyId = keysCopy.indexOf(oldKey);

            if (oldKeyId === -1) return currKeys;

            keysCopy[oldKeyId] = newKey;

            return keysCopy;
        });
    };

    return {
        activeKeys,
        setKeys,
        addKey,
        removeKey,
        replaceKey,
    };
};

export default useCollapse;