import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Result,
  Row,
  Typography,
} from "@pankod/refine-antd";
import { useUpdatePassword, useNavigation } from "@pankod/refine-core";
import { validateConfirmPassword } from "utils";
import { AuthenticateLayout } from "components/layout";
import { useQuery } from "hooks/useQuery";
import { Link } from "react-router-dom";

const { Text } = Typography;

type Props = {};

type ResetPasswordForm = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export const ResetPassword: React.FC<Props> = ({}) => {
  const [form] = Form.useForm<ResetPasswordForm>();
  const { replace } = useNavigation();
  const { operations } = useQuery();

  const [resetErrors, setResetErrors] = useState<string[]>([]);

  const { mutateAsync, isLoading, isSuccess, error } =
    useUpdatePassword<ResetPasswordForm>();

  const resetPassword = async (payload: ResetPasswordForm) => {
    try {
      await mutateAsync(payload);
    } catch (error: any) {
      const { data = {} } = error;
      const { errors = {} } = data;
      const { credentials = [] } = errors;

      if (credentials.length > 0) setResetErrors(credentials);
    }
  };

  const passwordRest = isSuccess && !error;

  const ResetErrors = (): React.ReactNode[] => {
    return resetErrors.map((error) => {
      return <Text type="danger">{error}</Text>;
    });
  };

  return (
    <AuthenticateLayout title="Reset password" bodyStyle={{ paddingTop: 0 }}>
      {!passwordRest && (
        <Form<ResetPasswordForm>
          layout="vertical"
          name="reset-password"
          form={form}
          onFinish={(values) => {
            const token = operations.query.get("token") || "";
            const payload: ResetPasswordForm = { ...values, token };
            resetPassword(payload);
          }}
          requiredMark={true}
          initialValues={{
            has_subscribed: false,
          }}
          onChange={() => {
            if (resetErrors.length < 1) return;

            setResetErrors([]);
          }}
        >
          <Row gutter={[0, 20]}>
            {resetErrors.length > 0 && (
              <Col span={24}>
                <Form.ErrorList errors={ResetErrors()} />
                <Typography.Text
                  style={{ paddingBottom: "15px", fontWeight: "bold" }}
                >
                  <Link to="/forgot-password" style={{ marginBottom: "15px" }}>
                    Request new password reset
                  </Link>
                </Typography.Text>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
                style={{ marginBottom: "12px" }}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, min: 6 }]}
                style={{ marginBottom: "12px" }}
              >
                <Input.Password placeholder="●●●●●●●●" size="large" />
              </Form.Item>
              <Form.Item
                name="password_confirmation"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    validator: (filedData, value) => {
                      return validateConfirmPassword(form, filedData, value);
                    },
                  },
                ]}
                style={{ marginBottom: "12px" }}
              >
                <Input.Password placeholder="●●●●●●●●" size="large" />
              </Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                block
                style={{ marginTop: "12px" }}
              >
                Reset password
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {passwordRest && (
        <Result
          status="success"
          title="Password changed"
          subTitle="Your password has been successfully changed."
          style={{ padding: "20px 0" }}
          extra={[
            <Button
              key="login"
              type="primary"
              block
              size="large"
              onClick={() => {
                replace("/login");
              }}
            >
              Sign in
            </Button>,
          ]}
        />
      )}
    </AuthenticateLayout>
  );
};
