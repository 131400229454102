import React from "react";
import {
  Drawer as RefineDrawer,
  DrawerProps as RefineDrawerProps,
  Spin,
} from "@pankod/refine-antd";
import useDeviceSize from "hooks/useDeviceSize";

type DrawerProps = RefineDrawerProps & {
  isLoading?: boolean;
};

export const Drawer: React.FC<DrawerProps> = ({
  children,
  ...props
}: DrawerProps) => {
  const { state: deviceSizeState } = useDeviceSize();

  return (
    <RefineDrawer
      getContainer={false}
      width={deviceSizeState.isSmall ? "100%" : "500px"}
      {...props}
      onClose={props.isLoading ? () => {} : props.onClose}
    >
      <Spin spinning={props.isLoading}>{children}</Spin>
    </RefineDrawer>
  );
};
