import React from 'react'
import { useDrop } from 'react-dnd'
import { DragAndDropTypes } from "../../types";

type Props = {
    stakeholderGroup: number,
    moveContact({ id, stakeholderGroup }: { id: number, stakeholderGroup: number }): void,
    children?: React.ReactNode;
}

function RelationshipGroup({ stakeholderGroup, moveContact, children }: Props) {
    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: DragAndDropTypes.CONTACT,
            drop: moveContact,
            canDrop: () => true,
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
        }),
        [stakeholderGroup]
    );

    return (
        <div
            ref={drop}
            className="relationship-group"
            style={{
                // alignItems: "start",
                // flexWrap: "wrap",
                backgroundColor: isOver ? "#b4d1ff" : "white"
            }}
        >
            {children}
        </div>
    )
}

export default RelationshipGroup