import { useEffect } from "react";
import { useLogin, useTranslate, useNotification } from "@pankod/refine-core";
import { Form, FormInstance } from "antd";
import { ILoginForm } from "interfaces";
import { useState } from "react";
import { useQuery } from "hooks/useQuery";
import { NotificationKeys } from "providers/notificationProvider";

export type LoginStateState = {
    form: FormInstance;
    isLoading: boolean;
    hasErrors: boolean;
    loginErrors: string[];
};

export type LoginControllerOperations = {
    handleLogin: (values: ILoginForm) => Promise<void>;
    translate: {
        (key: string, options?: any, defaultMessage?: string | undefined): string;
        (key: string, defaultMessage?: string | undefined): string;
    };
    clearLoginErrors: () => void;
};

export type LoginController = {
    state: LoginStateState;
    operations: LoginControllerOperations;
};

export function useLoginController(): LoginController {
    const [form] = Form.useForm<ILoginForm>();
    const [loginErrors, setLoginErrors] = useState<string[]>([]);
    const translate = useTranslate();
    const { mutateAsync: login, isLoading } = useLogin<ILoginForm>();

    const hasErrors = loginErrors.length > 0;

    const clearLoginErrors = (): void => {
        if (loginErrors.length < 0) return;

        setLoginErrors([]);
    };

    const handleLogin = async (values: ILoginForm) => {
        try {
            await login(values);
        } catch (error: any) {
            const { data = {} } = error;
            const { errors = {} } = data;
            const { credentials = [] } = errors;

            setLoginErrors(credentials);
        }
    };

    const { open, close } = useNotification();
    const { operations: queryOperations } = useQuery();

    useEffect(() => {
        const error = queryOperations.query.get("error");

        const unauthorisedKey = NotificationKeys.UNAUTHORISED;

        if (error === unauthorisedKey) {
            open?.({
                type: "error",
                message: "",
                description: "",
                key: unauthorisedKey,
            });
        }

        return () => {
            close?.(unauthorisedKey)
        }
    }, []);

    return {
        state: {
            form,
            isLoading,
            loginErrors,
            hasErrors,
        },
        operations: {
            handleLogin,
            translate,
            clearLoginErrors,
        },
    };
}
