import React from "react";
import { NotificationProvider } from "@pankod/refine-core";
import { notification } from "antd";
import { UndoableNotification } from "components/undoableNotification";

export enum NotificationKeys {
  UNAUTHORISED = "UNAUTHORISED",
}

export const notificationProvider: NotificationProvider = {
  open: ({
    key,
    message,
    description,
    type,
    cancelMutation,
    undoableTimeout,
  }) => {
    if (type === "success" || type === "progress") return;

    if (key === NotificationKeys.UNAUTHORISED) {
      notification.open({
        key: NotificationKeys.UNAUTHORISED,
        type: "error",
        message: "Sorry, something went wrong!",
        description: (
          <div style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.85)" }}>
            Please log in again.
          </div>
        ),
        duration: null, // default duration is 1.5s
      });
      return;
    }

    notification.open({
      key,
      description: message,
      message: description ?? null,
      type,
    });
  },
  close: (key) => notification.close(key),
};
