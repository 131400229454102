import React, { HTMLAttributes } from "react";
import { Row, Col } from "@pankod/refine-antd";
import { ImportFromTypes } from "types";
import { LinkedInIcon, SalesforceIcon } from "components/icons";

type Props = {
  type: string;
  showLabel?: boolean;
};

const importIcons = new Map<string, React.FC<HTMLAttributes<any>>>([
  [ImportFromTypes.SALESFORCE, SalesforceIcon],
  [ImportFromTypes.LINKEDIN, LinkedInIcon],
]);

// TODO: can it be combined with ContactType component ?
function ImportType({ type, showLabel = true }: Props) {
  const importType = type.toLocaleLowerCase();
  const Icon = importIcons.get(importType);
  return (
    <Row
      gutter={10}
      style={{
        justifyContent: "center",
        height: "fit-content",
      }}
    >
      {showLabel && <Col>{type}</Col>}
      {Icon && <Col>{<Icon />}</Col>}
    </Row>
  );
}

export default ImportType;
