import { Col, Row, Tooltip, Typography } from "@pankod/refine-antd";
import React from "react";
import { tooltips } from "./utils";

type TableHeadProps = {};

export const tableHeadConfig = [
  {
    id: 7,
    color: "#993399",
    name: "Friend",
  },
  {
    id: 6,
    color: "#8E52B4",
    name: "Advocate",
  },
  {
    id: 5,
    color: "#806CC9",
    name: "Support",
  },
  {
    id: 4,
    color: "#7283D9",
    name: "Trust",
  },
  {
    id: 3,
    color: "#6897E4",
    name: "Like",
  },
  {
    id: 2,
    color: "#67ABEB",
    name: "Know",
  },
  {
    id: 1,
    color: "#70BCEE",
    name: "Recognise",
  },
  {
    id: 0,
    color: "#83CDF0",
    name: "Don't know",
  },
];

const TableHead = (props: TableHeadProps) => {
  const getHeadColumns = (): JSX.Element[] => {
    const columnsNum = tableHeadConfig.length;
    return tableHeadConfig.map((column) => {
      return (
        <Col
          key={column.color}
          id={column.color}
          span={24 / columnsNum}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            padding: "5px",
            backgroundColor: column.color,
          }}
        >
          <Tooltip title={tooltips.stages[column.id]}>
            <Typography.Text style={{ color: "white" }}>
              {`${column.id}. ${column.name}`}
            </Typography.Text>
          </Tooltip>
        </Col>
      );
    });
  };
  return <Row>{getHeadColumns()}</Row>;
};

export default TableHead;
