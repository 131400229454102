import {
  CrudSorting,
  IResourceComponentsProps,
  parseTableParams,
} from "@pankod/refine-core";
import {
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  CreateButton,
  Empty,
  Button,
  Tooltip,
  useDrawerForm,
  getDefaultSortOrder,
  Alert,
} from "@pankod/refine-antd";
import { FileAddOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { IBaseContact, IContact } from "interfaces";
import RelationshipIcon from "components/relationshipIcon";
import { List } from "components/crud";
import {
  AddContactDrawer,
  EditContactDrawer,
  FilterContactsDrawer,
} from "components/contactDrawers";
import ActionPlanButton from "components/actionPlanButton";
import { ImportContactsDrawer } from "components/importContactsDrawer";
import {
  DeleteManyButton,
  FiltersIconButton,
  IconButton,
} from "components/buttons";
import { ActionPlansDrawer } from "components/drawers";
import { useContactsMutation, useContactsFilters } from "hooks/contacts";
import { useLocation } from "@pankod/refine-react-router-v6";
import useDeviceSize from "hooks/useDeviceSize";

const PageSizeKey = "contactsPageSize";

const defaultSorter: CrudSorting = [
  {
    field: "first_name",
    order: "asc",
  },
];

export const ContactsList: React.FC<IResourceComponentsProps> = ({
  name: resourceName,
}) => {
  const { state: deviceState } = useDeviceSize();
  const [selectedRowIds, setSelectedRowIds] = useState<React.Key[]>([]);

  const [importDrawerVisibility, setImportDrawerVisibility] =
    useState<boolean>(false);

  const [selectedContactActions, setSelectedContactsActions] = useState<
    number | null
  >(null);

  const pageSize = parseInt(localStorage.getItem(PageSizeKey) || "10");
  const setPageSize = (pageSize: number) => {
    localStorage.setItem(PageSizeKey, pageSize.toString());
  };

  const getDefaultSorter = (): CrudSorting => {
    const savedSorterJSON = localStorage.getItem("contactsSorter");

    if (!savedSorterJSON) return defaultSorter;

    const savedSorter = JSON.parse(savedSorterJSON);

    if (!Array.isArray(savedSorter) || savedSorter.length === 0)
      return defaultSorter;

    return savedSorter;
  };

  const { search } = useLocation();
  useEffect(() => {
    const { parsedSorter } = parseTableParams(search);
    localStorage.setItem("contactsSorter", JSON.stringify(parsedSorter));
  }, [search]);

  const { tableProps, tableQueryResult, filters, setFilters, sorter } =
    useTable<IContact>({
      resource: "contacts",
      initialPageSize: pageSize,
      syncWithLocation: true,
      defaultSetFilterBehavior: "replace",
      initialSorter: getDefaultSorter(),
    });

  const { state: filtersState, operations: filtersOperations } =
    useContactsFilters({
      setFilters,
      filters,
    });

  const { refetch: refetchContacts, isRefetching } = tableQueryResult;

  const handleRowSelection = (rowId: number | undefined) => {
    if (rowId === undefined) return;

    setSelectedRowIds((currIds) => {
      if (currIds.includes(rowId)) {
        return currIds.filter((currId) => currId !== rowId);
      }

      return [...currIds, rowId];
    });
  };

  const handleSelectAll = (selected: boolean, rows: IContact[]) => {
    if (!selected) {
      setSelectedRowIds([]);
      return;
    }
    const rowIds = rows.map((row) => row.id);
    setSelectedRowIds(rowIds);
  };

  const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);

  const { state: contactsState, operations: contactsOperations } =
    useContactsMutation();

  const {
    formProps: createFormProps,
    drawerProps: createDrawerProps,
    show: showCreateDrawer,
    saveButtonProps: createSaveButtonProps,
    formLoading: createFormLoading,
    mutationResult: createResult,
    close: closeCreateModal,
  } = useDrawerForm<IBaseContact>({
    action: "create",
    warnWhenUnsavedChanges: false,
  });

  const onCreateContactFinish = (values: IBaseContact) => {
    contactsOperations.createContact(values, { onSuccess: closeCreateModal });
  };

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: showEditDrawer,
    saveButtonProps: editSaveButtonProps,
    formLoading: editFormLoading,
    mutationResult: errorsResult,
    close: closeEditModal,
  } = useDrawerForm<IContact>({
    action: "edit",
    warnWhenUnsavedChanges: false,
  });

  const onEditContactFinish = (values: IContact) => {
    contactsOperations.updateContact(values, { onSuccess: closeEditModal });
  };

  return (
    <List
      headerButtons={
        <>
          {/* TODO */}
          <IconButton
            icon={<FileAddOutlined />}
            className=""
            type="default"
            onClick={() => setImportDrawerVisibility(true)}
            tooltipTitle="Import contacts"
          />
          <FiltersIconButton
            onClick={() => {
              setAreFiltersOpen(true);
            }}
            tooltipText="Filter contacts"
            filtersCount={filters?.length}
          />
          <DeleteManyButton
            resource={resourceName || ""}
            ids={selectedRowIds}
            onSuccess={() => setSelectedRowIds([])}
            deleteButtonProps={{ hideText: true }}
          />
          <Tooltip title="Create contact">
            <CreateButton hideText onClick={() => showCreateDrawer()} />
          </Tooltip>
        </>
      }
      wrapperProps={{
        id: "contacts-list-table",
      }}
    >
      {filters && filters?.length > 0 && (
        <Alert
          style={{ marginBottom: "20px" }}
          message="Filters applied"
          type="warning"
          showIcon={!deviceState.isSmall}
          closeText="Clear filters"
          onClose={filtersOperations.clearFilters}
        />
      )}
      <Table
        {...tableProps}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No contacts"
            />
          ),
        }}
        onRow={(record) => {
          return {
            onClick: () => handleRowSelection(record.id),
          };
        }}
        rowSelection={{
          selectedRowKeys: selectedRowIds,
          onSelect: (record) => handleRowSelection(record.id),
          onSelectAll: (selected, rows) => handleSelectAll(selected, rows),
        }}
        // showHeader={false}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: [8, 10, 15, 20, 50, 100],
          onShowSizeChange(_, pageSize) {
            setPageSize(pageSize);
          },
        }}
        loading={tableProps.loading || isRefetching}
      >
        <Table.Column
          dataIndex="relationship_strength"
          key="relationship_strength"
          title=""
          className="contacts-list-cell"
          responsive={["md"]}
          width={50}
          sorter
          defaultSortOrder={getDefaultSortOrder(
            "relationship_strength",
            sorter
          )}
          render={(value) => <RelationshipIcon value={value} />}
        />
        <Table.Column<IContact>
          dataIndex="name"
          key="first_name"
          title="Name"
          // colSpan={2}
          className="contacts-list-cell"
          sorter
          render={(_, record) => (
            <TextField
              value={`${record.first_name} ${record.last_name}`}
              className="single-row-text"
            />
          )}
          defaultSortOrder={getDefaultSortOrder("first_name", sorter)}
        />
        <Table.Column<IContact>
          // title="Actions"
          dataIndex="actions"
          key="actions"
          width={100}
          className="contacts-list-cell"
          render={(_, record) => (
            <Space
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {record.id && record.is_in_matrix && (
                <ActionPlanButton
                  contactId={record.id}
                  tooltipText="Action Plans"
                  onClick={() => {
                    if (!record.id) return;

                    setSelectedContactsActions(record.id);
                  }}
                />
              )}
              <Tooltip title="Edit contact">
                <EditButton
                  hideText
                  onClick={(e) => {
                    e.stopPropagation();
                    showEditDrawer(record?.id);
                  }}
                />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      {createDrawerProps.visible && (
        <AddContactDrawer
          title="Create contact"
          drawerProps={{
            ...createDrawerProps,
            onClose: (e) => {
              createDrawerProps.onClose?.(e);
              contactsOperations.clearState();
            },
          }}
          formProps={{ ...createFormProps, onFinish: onCreateContactFinish }}
          saveButtonProps={createSaveButtonProps}
          isLoading={createFormLoading || contactsState.createLoading}
          mutationErrors={contactsState.createErrors}
          hideSearch
        />
      )}
      {editDrawerProps.visible && (
        <EditContactDrawer
          title="Edit contact"
          drawerProps={{
            ...editDrawerProps,
            onClose: (e) => {
              editDrawerProps.onClose?.(e);
              contactsOperations.clearState();
            },
          }}
          formProps={{ ...editFormProps, onFinish: onEditContactFinish }}
          saveButtonProps={editSaveButtonProps}
          mutationErrors={contactsState.updateErrors}
          isLoading={editFormLoading || contactsState.updateLoading}
        />
      )}
      {importDrawerVisibility && (
        <ImportContactsDrawer
          visible={importDrawerVisibility}
          onClose={() => setImportDrawerVisibility(false)}
          onImportSuccess={refetchContacts}
        />
      )}
      {selectedContactActions !== null && (
        <ActionPlansDrawer
          drawerProps={{
            visible: selectedContactActions !== null,
            onClose: () => {
              setSelectedContactsActions(null);
            },
          }}
          contactId={selectedContactActions}
        />
      )}
      <FilterContactsDrawer
        drawerProps={{
          visible: areFiltersOpen,
          onClose: () => {
            setAreFiltersOpen(false);
          },
        }}
        formProps={filtersState.formProps}
        clearFilters={filtersOperations.clearFilters}
        onClose={() => setAreFiltersOpen(false)}
      />
    </List>
  );
};
