import axios, { AxiosInstance } from "axios";
import { HttpError } from "@pankod/refine-core";

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  // TODO: set headers here
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.data?.code,
    };

    return Promise.reject(customError);
  }
);

export { axiosInstance as axios };
