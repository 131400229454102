import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonProps,
  Empty,
  Form,
  FormProps,
  Select,
  Space,
  Spin,
} from "@pankod/refine-antd";
import { getSelectOptions } from "utils";
import { IContactSelectOption } from "interfaces";
import { debounce } from "lodash";
import { useSearchContact } from "hooks/useSearchContact";

type SearchContactForm = {
  formProps: FormProps;
  filedPath: string;
  footerButtons?: JSX.Element | JSX.Element[];
  submitButton?: boolean;
  submitButtonProps?: ButtonProps & {
    text?: string;
  };
};

const SearchContactFrom: React.FC<SearchContactForm> = ({
  formProps,
  filedPath,
  footerButtons,
  submitButton,
  submitButtonProps,
}) => {
  const [options, setOptions] = useState<any>([]);

  const setContactsOptions = (contacts: IContactSelectOption[]) => {
    const transformedOptions = getSelectOptions(contacts, "name", "id");
    setOptions(transformedOptions);
  };

  const { state, operations } = useSearchContact({
    onSuccessCallBack: setContactsOptions,
  });

  const setDefaultContacts = () => {
    setContactsOptions(state.allContacts as IContactSelectOption[]);
  };

  const searchContact = (name: string) => {
    if (name.trim() === "") {
      return setDefaultContacts();
    }

    operations.search(name);
  };
  const debounceSearch = debounce(searchContact, 300);

  useEffect(() => {
    setDefaultContacts();
  }, [state.allContacts.length]);

  return (
    <Spin spinning={state.isLoading}>
      <Form
        layout="vertical"
        requiredMark={false}
        {...formProps}
        name="search-contact"
      >
        <Form.Item
          name={filedPath}
          label="Search contact by name"
          rules={[{ required: true, message: "Contact name is required" }]}
        >
          <Select
            showSearch
            options={options}
            allowClear
            filterOption={false}
            onSearch={debounceSearch}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Start typing to see contacts"
              />
            }
          />
        </Form.Item>
        {(submitButton || footerButtons) && (
          <Form.Item>
            <Space style={{ float: "right" }}>
              {footerButtons}
              {submitButton && (
                <Button
                  type="primary"
                  htmlType="submit"
                  {...(submitButtonProps || {})}
                >
                  {submitButtonProps?.text || "Add contact"}
                </Button>
              )}
            </Space>
          </Form.Item>
        )}
      </Form>
    </Spin>
  );
};

export default SearchContactFrom;
