import React from "react";
import {
  Container,
  Grid,
  Image,
  List,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { StepProps } from ".";
import config from "config.json";
import { StepContainer } from "./StepContainer";

export const StepFour: React.FC<StepProps> = (props) => {
  return (
    <StepContainer id={props.id}>
      <Container>
        <Title className="hr-with-text hr-line hr-one-line" order={3}>
          4. Adding contacts to your account
        </Title>
      </Container>
      <Grid>
        <Grid.Col span={12}>
          <Text>
            Contacts from your professional and personal networks are the most
            important factor for making {config.APP_NAME} work for you. You can
            add your contacts one by one or you can import a group of them from
            a CSV or an XLS file.
          </Text>
        </Grid.Col>
      </Grid>
      <Grid style={{ marginTop: 20 }}>
        <Grid.Col span={12}>
          <Title
            order={5}
            id="adding-individual-contacts"
            className="secondary"
            style={{ scrollMarginTop: "100px" }}
          >
            4.1. Adding individual contacts
          </Title>
          <Text style={{ padding: "10px 0" }}>
            To create a new contact, select <strong>Contacts</strong> from the
            main menu, then click or tap the <strong>Create Contact</strong>{" "}
            button. A drawer appears on the right, where you can fill in the
            contact’s information and set their Relationship Matrix™
            characteristics:
          </Text>
          <List type="unordered" spacing={"md"}>
            <List.Item>
              The strength of your relationship from zero to seven, where zero
              stands for “We don’t know each other” and seven means “We are
              friends”.
            </List.Item>
            <List.Item>
              <i>The 4 Is</i> and their potential impact on your success from
              zero (no impact) to five (the most impact) as an{" "}
              <strong>i</strong>
              nfluencer, <strong>i</strong>mplementer,
              <strong>i</strong>ntroducer or a source of <strong>i</strong>
              nformation, insight and ideas.
            </List.Item>
            <List.Item>
              Whether they are an advocate, a blocker or neutral to your cause.
            </List.Item>
          </List>
          <Text style={{ padding: "10px 0" }}>
            Click or tap <strong>Save </strong>to see the new contact appear in
            your list of contacts.
          </Text>
        </Grid.Col>
        <Grid.Col lg={12}>
          <Image src="images/user-guide/add-contact.jpeg" alt="add-contact" />
        </Grid.Col>
      </Grid>
      <Grid style={{ marginTop: 20 }}>
        <Grid.Col span={12}>
          <Title
            order={5}
            id="importing-contacts"
            className="secondary"
            style={{ scrollMarginTop: "100px" }}
          >
            4.2. Importing contacts from a file
          </Title>
          <Text style={{ padding: "10px 0" }}>
            The pilot version of {config.APP_NAME} lets you import files with
            contacts exported from Salesforce or LinkedIn in a CSV or an XLS
            format.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text>
            You can also import lists of contacts, generated by different CRMs
            or created by hand, as long as they contain the following fields:
          </Text>
          <List type="unordered" withPadding spacing={"md"}>
            <List.Item>
              <strong>First Name</strong> (optional)
            </List.Item>
            <List.Item className="secondary">
              <strong>Last Name</strong> (required)
            </List.Item>
            <List.Item>
              <strong>Company (optional): </strong>it will be imported as
              Organisation in {config.APP_NAME}
            </List.Item>
            <List.Item>
              <span className="secondary">
                <strong>Connected On</strong> (required):
              </span>{" "}
              this field, in combination with the rest, helps us keep track of
              contacts that have already been imported, so we can avoid
              duplicates.
            </List.Item>
          </List>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text style={{ padding: "10px 0" }}>Example XLS file:</Text>
          <Table style={{ border: "1px solid #dee2e6" }}>
            <thead>
              <tr>
                <th>First Name</th>
                <th className="secondary">Last Name</th>
                <th>Company</th>
                <th className="secondary">Connected On</th>
              </tr>
            </thead>
            <tbody>
              <tr key="example">
                <td>Jane</td>
                <td>Smith</td>
                <td>ACME</td>
                <td>31 Mar 2023</td>
              </tr>
            </tbody>
          </Table>
          <Text style={{ padding: "10px 0" }}>Example CSV file:</Text>
          <Text>
            <strong>First Name</strong>,
            <strong className="secondary">Last Name</strong>,
            <strong>Company</strong>,
            <strong className="secondary">Connected On</strong>
          </Text>
          <Text>Jane,Smith,ACME,31 Mar 2023</Text>
        </Grid.Col>
      </Grid>
      <Grid style={{ marginTop: 20 }}>
        <Grid.Col span={12}>
          <Text>
            When you have a list of your contacts ready, on the{" "}
            <strong>Contacts</strong> screen click or tap the{" "}
            <strong>Import Contacts</strong> button. Follow the instructions in
            the drawer which appears on the right and click or tap the{" "}
            <strong>Import</strong> button.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text className="secondary bold">Please note:</Text>
          <List spacing={"md"}>
            <List.Item className="secondary">
              If you are using a file which has been generated elsewhere, please
              select <strong>LinkedIn</strong> as your platform in{" "}
              <strong>Step 2</strong>.
              <Image
                src="images/user-guide/import-contacts.jpeg"
                alt="import-contacts"
                style={{ margin: "10px 0", padding: "20px" }}
              />
            </List.Item>
            {/* <List.Item className="secondary">
              At the moment, you can import files with as many as{" "}
              <strong>5,000 contacts</strong>. We are working on expanding that
              to tens of thousands.
            </List.Item>
            <List.Item className="secondary">
              Importing 5,000 contacts may take a couple of minutes.
            </List.Item> */}
          </List>
        </Grid.Col>
      </Grid>
      <Grid style={{ marginTop: 20 }}>
        <Grid.Col span={12}>
          <Text>
            When the import has finished, you will see a report of how many
            contacts were imported, as well as any issues that may have
            occurred. You should also see your newly imported contacts on the{" "}
            <strong>Contacts</strong> screen.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Image
            src="images/user-guide/import-contacts-results.jpeg"
            alt="import-contacts-results"
          />
        </Grid.Col>
      </Grid>
    </StepContainer>
  );
};
