import React, { useState } from "react";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
} from "antd";
import { useLogout, useTranslate } from "@pankod/refine-core";
import { useRegistration } from "./useRegistration";
import { IRegisterForm } from "interfaces";
import { validateConfirmPassword } from "utils";
import { Logo } from "components/logos";
import { FieldErrors, ServerErrors } from "types";
import { AgreementModal } from "components/modals";

const { Text, Title } = Typography;

export const RegisterPage: React.FC = () => {
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] =
    useState<boolean>(false);

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState<boolean>(false);

  const [form] = Form.useForm<IRegisterForm>();

  const { mutateAsync: logout } = useLogout();

  const onRegistrationError = (errors: ServerErrors) => {
    // build errors
    // form.setFields([{ name: ['email'], errors: ["My error"] }]);
    const formFields: FieldErrors[] = Object.entries(errors).map((error) => {
      const [name, filedErrors] = error;
      return { name, errors: filedErrors };
    });
    // set errors
    form.setFields(formFields);
  };

  const translate = useTranslate();

  const { register, isLoading } = useRegistration({
    onErrorCallBack: onRegistrationError,
  });

  const CardTitle = (
    <Title level={3} className="sign-in-form-title">
      Register
    </Title>
  );

  const Link = (props: { name: string; to: string }): JSX.Element => {
    return (
      <a
        style={{ color: "var(--primary-color)", fontWeight: "bold" }}
        href={props.to}
        target="_blank"
        rel="noreferrer"
      >
        {props.name}
      </a>
    );
  };

  return (
    <Layout className="sign-in-background">
      <Row
        justify="center"
        align="middle"
        style={{
          // TODO: change this style
          height: "fit-content",
          minHeight: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="sign-in-form">
            <Logo
              containerStyle="sign-in-logo-container"
              imageStyle="sign-in-logo"
            />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<IRegisterForm>
                layout="vertical"
                name="register-form"
                form={form}
                onFinish={(values) => {
                  if (!termsAndConditionsAccepted) {
                    setTermsAndConditionsOpen(true);
                    return;
                  }

                  register(values);
                }}
                requiredMark={true}
                initialValues={{
                  has_subscribed: false,
                }}
              >
                <Form.Item
                  name="email"
                  label={translate("pages.register.email", "Email")}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    size="large"
                    placeholder={translate("pages.register.email", "Email")}
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  label={translate("pages.register.name", "Name")}
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    size="large"
                    placeholder={translate(
                      "pages.register.username",
                      "Username"
                    )}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={translate("pages.register.password", "Password")}
                  rules={[{ required: true, min: 6 }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input.Password placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  label={translate(
                    "pages.register.password_confirmation",
                    "Confirm Password"
                  )}
                  rules={[
                    {
                      required: true,
                      validator: (filedData, value) => {
                        return validateConfirmPassword(form, filedData, value);
                      },
                    },
                  ]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input.Password placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Form.Item
                  name="has_accepted_terms"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value)
                          return Promise.reject(
                            "To register you must agree with the Data Privacy Statement"
                            // "To register you must agree with the Privacy Policy and the Terms and Conditions"
                          );

                        return Promise.resolve();
                      },
                    },
                  ]}
                  valuePropName="checked"
                  style={{ marginBottom: "12px" }}
                >
                  <Checkbox>
                    I agree with the{" "}
                    <Link
                      to={"/privacy-policy"}
                      name={"Data Privacy Statement"}
                    />{" "}
                    for using the Relationship Matrix App.
                    {/* I agree with the{" "}
                    <Link to={"/privacy-policy"} name={"Privacy Policy"} /> and
                    the <Link to={"/terms"} name={"Terms and conditions"} /> for
                    using the Relationship Matrix App. */}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="has_accepted_pilot_terms"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value)
                          return Promise.reject(
                            "To register you must agree to participate in the pilot programme and give feedback"
                          );

                        return Promise.resolve();
                      },
                    },
                  ]}
                  valuePropName="checked"
                  style={{ marginBottom: "12px" }}
                >
                  <Checkbox>
                    By registering to use The Relationship Matrix I agree to
                    participate in the pilot programme and give feedback on my
                    use and experience to enable the development of the best
                    possible product. I also understand that there will be a
                    charge to use The Relationship Matrix in the future. Fair
                    notice will be given before the app becomes chargeable.
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="has_subscribed"
                  valuePropName="checked"
                  style={{ marginBottom: "12px" }}
                >
                  <Checkbox>
                    Please check if you are interested in receiving Andy’s
                    monthly e-zine 'Connecting is not Enough' plus very
                    occasional marketing or information emails. We will never
                    pass on your details to third parties.
                  </Checkbox>
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                  style={{ marginTop: "12px" }}
                >
                  {translate("pages.register.signup", "Register")}
                </Button>
              </Form>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  {translate(
                    "pages.register.haveAccount",
                    "Already registered?"
                  )}{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "var(--primary-color)",
                    }}
                    onClick={() => {
                      logout();
                    }}
                  >
                    Sign in
                  </span>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
      <AgreementModal
        isOpen={termsAndConditionsOpen}
        title="Terms of Use"
        handleClose={() => setTermsAndConditionsOpen(false)}
        handleOk={() => {
          setTermsAndConditionsAccepted(true);
          setTermsAndConditionsOpen(false);
          form.submit();
        }}
      />
    </Layout>
  );
};
