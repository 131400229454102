import React, { useContext, useState, useEffect } from "react";
import {
  Col,
  Row,
  PopoverProps,
  Popover,
  Button,
  Select,
  Popconfirm,
} from "@pankod/refine-antd";
import { MatrixContext } from "./Table";
import useDeviceSize from "hooks/useDeviceSize";
import { IStakeholderGroup } from "interfaces";
import { getSelectOptions } from "utils";
import { MoveContactProps } from "hooks/useMatrixCommands";
import { OptionItem } from "types";

type ContactContextMenuProps = {
  contactId: number;
  stakeholderGroup: number;
  popoverProps?: PopoverProps;
  onEdit?(contactId: number): void;
  onEditImpact?(contactId: number): void;
  onActionPlanOpen?(contactId: number): void;
};

const ContactContextMenu: React.FC<ContactContextMenuProps> = (props) => {
  const {
    contactId,
    stakeholderGroup,
    popoverProps,
    onEdit,
    onEditImpact,
    onActionPlanOpen,
  } = props;
  const { operations } = useContext(MatrixContext);
  const { state: deviceState } = useDeviceSize();

  const [options, setOptions] = useState<OptionItem[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const closePopover = () => {
    setOpen(false);
  };

  const getOptions = () => {
    const stakeholderGroups: IStakeholderGroup[] =
      operations.getStakeholderGroups();
    const newOptions = getSelectOptions(stakeholderGroups, "name", "id");
    setOptions(newOptions);
  };

  useEffect(() => {
    getOptions();
  }, []);

  const onChange = (group: number) => {
    const newContactActionData: MoveContactProps = {
      contactId,
      currentGroup: stakeholderGroup,
      newGroup: group,
      relationship: undefined,
    }; // TODO fix;

    operations.moveContact(newContactActionData);
  };

  const getPopoverContent = (): React.ReactNode => {
    return (
      <Row
        key={`${contactId}-${stakeholderGroup}-popover`}
        gutter={[24, 12]}
        style={{ maxWidth: "200px" }}
        onClick={() => {
          closePopover();
        }}
      >
        {deviceState.isSmall && (
          <Col span={24}>
            <Select
              style={{ width: "100%" }}
              showSearch
              defaultValue={stakeholderGroup}
              onChange={onChange}
              options={options}
              onFocus={getOptions}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
        )}
        <Col span={24}>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              onEdit?.(contactId);
            }}
          >
            Edit
          </Button>
        </Col>
        <Col span={24}>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              onEditImpact?.(contactId);
            }}
          >
            Edit Impact
          </Button>
        </Col>
        <Col span={24}>
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              onActionPlanOpen?.(contactId);
            }}
          >
            Action Plan
          </Button>
        </Col>
        <Col span={24}>
          <Popconfirm
            title="Are you sure you want to remove the contact from the stakeholder group?"
            okText="Remove"
            okType="danger"
            onConfirm={() => {
              operations.removeContactFromGroup(contactId, stakeholderGroup);
            }}
          >
            <Button style={{ width: "100%" }} danger>
              Remove from group
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    );
  };

  return (
    <Popover
      trigger="click"
      placement="bottom"
      title={null}
      visible={open}
      onVisibleChange={(visible) => setOpen(visible)}
      content={getPopoverContent()}
      {...popoverProps}
    >
      {props.children}
    </Popover>
  );
};

export default ContactContextMenu;
