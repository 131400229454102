import React, { useContext } from "react";
import { MatrixContext } from "./Table";
import { useDrag } from "react-dnd";
import { DragAndDropTypes } from "../../types";
import ContactCard from "components/contactCard";
import { IBaseContactActionsData } from "interfaces";

type Props = {
  id: number;
  stakeholderGroup: number;
  onDelete?(id: number): void;
  onClick?({ contactId, currentGroup }: IBaseContactActionsData): void;
};

function RelationshipCard({ id, stakeholderGroup, onDelete, onClick }: Props) {
  const { operations } = useContext(MatrixContext);
  const { getContactById } = operations;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DragAndDropTypes.CONTACT,
      item: { id, stakeholderGroup }, // TODO
      collect: (monitor) => {
        return {
          isDragging: !!monitor.isDragging(),
        };
      },
    }),
    [id]
  );

  const contact = getContactById(id);

  return (
    <div
      ref={drag}
      onClick={() =>
        onClick?.({ contactId: id, currentGroup: stakeholderGroup })
      }
      className="relationship-card"
    >
      {contact && <ContactCard contact={contact} />}
    </div>
  );
}

export default RelationshipCard;
