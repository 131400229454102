import { CrudFilters, getDefaultFilter } from "@pankod/refine-core";
import { Form, FormProps } from "@pankod/refine-antd";
import { IHookResponse } from "interfaces";

type Props = {
  filters?: CrudFilters;
  setFilters(filters: CrudFilters): void;
};

export type ContactsFilters = {
  q?: string;
  implementer?: [number, number];
  influencer?: [number, number];
  information?: [number, number];
  introducer?: [number, number];
  relationship?: [number, number];
  types?: string[];
};

const defaultValues = {
  implementer: [0, 5] as [number, number],
  influencer: [0, 5] as [number, number],
  information: [0, 5] as [number, number],
  introducer: [0, 5] as [number, number],
  q: "",
  relationship: [0, 7] as [number, number],
  types: [],
};

type RangeType =
  | "relationship"
  | "introducer"
  | "information"
  | "influencer"
  | "implementer";

interface Response extends IHookResponse {
  state: {
    formProps: FormProps;
  };
  operations: {
    clearFilters(): void;
  };
}

export const useContactsFilters = (props: Props): Response => {
  const [formInstance] = Form.useForm<ContactsFilters>();

  const getRangeFromUrl = (range: RangeType): [number, number] | undefined => {
    const stringValue = getDefaultFilter(range, props.filters, "eq");

    if (!stringValue) return undefined;

    const stringRange = stringValue?.split("-");

    if (stringRange.length < 2) return undefined;

    const rangeFrom = parseInt(stringRange[0]);
    const rangeTo = parseInt(stringRange[1]);

    return [rangeFrom, rangeTo];
  };

  const getInitialValues = (): ContactsFilters => {
    if (!props.filters) return {};

    const q: string | undefined = getDefaultFilter("q", props.filters, "eq");
    const implementer = getRangeFromUrl("implementer");
    const influencer = getRangeFromUrl("influencer");
    const information = getRangeFromUrl("information");
    const introducer = getRangeFromUrl("introducer");
    const relationship = getRangeFromUrl("relationship");
    const typesFilter = getDefaultFilter("types", props.filters, "eq");
    const types =
      typeof typesFilter === "string" ? typesFilter?.split(",") : [];

    return {
      q,
      types: types,
      relationship,
      implementer,
      influencer,
      information,
      introducer,
    };
  };

  const shouldApplyRangeFilter = (
    range: [number, number] | undefined,
    rangeType: RangeType
  ): boolean => {
    if (range === undefined) return false;

    const minVal = 0;
    const maxVal = rangeType === "relationship" ? 7 : 5;

    return range[0] !== minVal || range[1] !== maxVal;
  };

  const getRangeFilter = (
    range: [number, number] | undefined,
    rangeType: RangeType
  ): CrudFilters => {
    if (!range || !shouldApplyRangeFilter(range, rangeType)) return [];

    const value = range.join("-");

    return [
      {
        field: rangeType,
        operator: "eq",
        value: value,
      },
    ];
  };

  const buildFilters = (data: ContactsFilters): CrudFilters => {
    const filters: CrudFilters = [];
    const {
      q,
      implementer,
      influencer,
      information,
      introducer,
      relationship,
      types,
    } = data;

    if (q) {
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });
    }

    if (types && types.length > 0) {
      const value = types.join(",");

      filters.push({
        field: "types",
        operator: "eq",
        value: value,
      });
    }

    const implementerFilter = getRangeFilter(implementer, "implementer");
    const influencerFilter = getRangeFilter(influencer, "influencer");
    const informationFilter = getRangeFilter(information, "information");
    const introducerFilter = getRangeFilter(introducer, "introducer");
    const relationshipFilter = getRangeFilter(relationship, "relationship");

    filters.push(
      ...implementerFilter,
      ...influencerFilter,
      ...informationFilter,
      ...introducerFilter,
      ...relationshipFilter
    );

    return filters;
  };

  const onFinish = (values: ContactsFilters) => {
    const filters = buildFilters(values);
    props.setFilters(filters);
  };

  const clearFilters = () => {
    formInstance.setFieldsValue(defaultValues);
    props.setFilters([]);
  };

  return {
    state: {
      formProps: {
        form: formInstance,
        onFinish,
        initialValues: getInitialValues(),
      },
    },
    operations: {
      clearFilters,
    },
  };
};
