import { IAction, IBasicMatrixData } from "interfaces";
import create from 'zustand'
import { v4 as uuidv4 } from 'uuid';


interface IActionPlansStore {
    matrices: IBasicMatrixData[]
    actions: IAction[],
    setStore(matrices: IBasicMatrixData[], actions: IAction[]): void,
    clearStore(): void,
    getActionsByMatrixId(matrixId: string | number): IAction[]
    addAction(action: IAction): void,
    deleteAction(actionId: string | number): void,
    updateAction(actionId: string | number, newActionData: IAction): void,
}

export const useActionPlansStore = create<IActionPlansStore>()((set, get) => ({
    matrices: [],
    actions: [],
    setStore: (matrices: IBasicMatrixData[], actions: IAction[]) => {
        const actionsWithMeta = actions.map((action) => {
            if (!!action?.metadata?.collapseId) return action

            // for old records
            return { ...action, metadata: { collapseId: uuidv4() } }
        });
        set(state => ({ matrices, actions: actionsWithMeta }))
    },
    clearStore: () => {
        set({ matrices: [], actions: [] })
    },
    addAction: (action: IAction) => {
        set(state => {
            return {
                ...state,
                actions: [...state.actions, action]
            }
        })
    },
    updateAction: (collapseId: string, newActionData: IAction) => {
        const currAction = get().actions.findIndex(action => action.metadata.collapseId === collapseId);
        const actions = get().actions;
        actions.splice(currAction, 1, newActionData);
        set({ actions });
    },
    deleteAction: (collapseId: string) => {
        const filteredAction = get().actions.filter(action => action.metadata.collapseId !== collapseId)

        set({ actions: filteredAction })
    },
    getActionsByMatrixId: (matrixId: number | string) => {
        return get().actions.filter(action => action.matrix_id === matrixId);
    }
}));