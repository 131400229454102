export type ListItem = {
  key: string;
  label: string;
};

export type OptionItem = {
  value: string;
  label: string;
};

export type ServerErrors = {
  [key: string]: string[];
};

export type FieldErrors = {
  name: string;
  errors: string[];
};

export enum ImpactCategoriesColors {
  INFLUENCER = "#f4c39b",
  IMPLEMENTER = "#b098c3",
  INTRODUCER = "#f0efa2",
  INFORMATION = "#83cdf0",
  DEFAULT = "black",
}

export const Impact_Types = {
  INFLUENCER: "INFLUENCER",
  IMPLEMENTER: "IMPLEMENTER",
  INTRODUCER: "INTRODUCER",
  INFORMATION: "INFORMATION",
} as const;

export type ImpactTypes = keyof typeof Impact_Types;

export enum ContactTypes {
  ADVOCATE = "advocate",
  NEUTRAL = "neutral",
  BLOCKER = "blocker",
}

export enum DragAndDropTypes {
  CONTACT = "contact",
}

declare const ButtonTypes: [
  "default",
  "primary",
  "ghost",
  "dashed",
  "link",
  "text"
];

export declare type ButtonType = (typeof ButtonTypes)[number];

export declare type LegacyButtonType = ButtonType | "danger";

export enum ContactActions {
  RELATIONSHIP = "relationship",
  GROUP = "group",
  RELATIONSHIP_AND_GROUP = "relationship_and_group",
  EDIT = "edit",
  DELETE = "delete",
}

export enum ImportFromTypes {
  SALESFORCE = "salesforce",
  LINKEDIN = "linkedin",
}
