import React, { useState } from "react";
import { IHookResponse, IImportResult } from "interfaces";
import {
  useApiUrl,
  useCustomMutation,
  useCustom,
  HttpError,
} from "@pankod/refine-core";
import { OptionItem, ServerErrors } from "types";

type ImportContactsProps = {
  onSuccess?(): void;
  onError?(errors: ServerErrors): void;
};

export interface IImportContacts {
  import_from: number;
  import_option?: number;
  file: File;
}

export interface IImportSettings {
  import_from: OptionItem[];
  import_options: OptionItem[];
  file_extensions: string[];
}

export enum ImportStatuses {
  IMPORTED = "IMPORTED",
  SKIPPED = "SKIPPED",
  OVERRIDDEN = "OVERRIDDEN",
}

export interface IUseUserResponse extends IHookResponse {
  state: {
    isLoading: boolean;
    settings: IImportSettings | undefined;
    result: IImportResult | undefined;
  };
  operations: {
    importContacts(payload: IImportContacts): void;
    resetResult(): void;
  };
}

function useImportContacts(props: ImportContactsProps = {}): IUseUserResponse {
  const API_URL = useApiUrl();

  const [importResult, setImportResult] = useState<IImportResult | undefined>();

  const resetResult = () => {
    if (!importResult) return;

    setImportResult(undefined);
  };

  const { data, isFetching: settingsLoading } = useCustom<IImportSettings>({
    url: `${API_URL}/user/import/options`,
    method: "get",
  });
  const settings = data?.data;

  const { mutateAsync, isLoading: importLoading } =
    useCustomMutation<IImportResult>();

  const importContacts = async (payload: IImportContacts) => {
    const formData = new FormData();
    formData.append("imported_from", payload.import_from.toString());
    formData.append("file", payload.file);

    await mutateAsync(
      {
        url: `${API_URL}/user/import`,
        method: "post",
        values: formData,
      },
      {
        onSuccess: ({ data }) => {
          setImportResult(data);
          props.onSuccess?.();
        },
        onError: (err: HttpError) => {
          const { response = {} } = err;
          const { data = {} } = response;
          const { errors = {} } = data;

          console.log("errors", errors);

          props.onError?.(errors);
        },
      }
    );
  };

  return {
    state: {
      isLoading: settingsLoading || importLoading,
      settings,
      result: importResult,
    },
    operations: {
      importContacts,
      resetResult,
    },
  };
}

export default useImportContacts;
