import { IMatrix, IMatrixCommand } from "interfaces";
import {
  AddNewContactCommand,
  IAddNewContactCommand,
} from "./AddNewContactCommand";

export interface IAddNewContactsCommand extends IMatrixCommand {
  addContactsCmds: IAddNewContactCommand[];
}

export interface IAddNewContactsCommandResponse {}

const createAddContactsCommands = (
  contactsIds: number[],
  groupId: number
): IAddNewContactCommand[] => {
  return contactsIds.map((contactId) => {
    return new AddNewContactCommand(contactId, groupId);
  });
};

export class AddNewContactsCommand implements IAddNewContactsCommand {
  addContactsCmds: IAddNewContactCommand[];

  constructor(contactsIds: number[], groupId: number) {
    this.addContactsCmds = createAddContactsCommands(contactsIds, groupId);
  }

  execute(matrix: IMatrix): IAddNewContactsCommandResponse {
    return {};
  }
  undo(matrix: IMatrix): IAddNewContactsCommandResponse {
    return {};
  }
  toJson(): Object[] {
    const commands: Object[] = [];
    this.addContactsCmds.forEach((cmd) => {
      const jsonCmd = cmd.toJson();
      commands.push(...jsonCmd);
    });
    return commands;
  }
}
